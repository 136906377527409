import { notification } from 'antd'
import axiosInstance from 'utilities/configureAxios'
import route from 'utilities/route'

export default async function getTeachers(payload = {}) {
  return axiosInstance
    .get(route('teachers.index'), payload)
    .then(res => res)
    .catch(error => {
      notification.warning({
        message: error.code,
        description: error.message,
      })
      return error.response
    })
}

export async function getRevenueResponsibleTeachers(payload = {}) {
  return axiosInstance
    .get(route('teachers.revenue_responsible'), payload)
    .then(res => res)
    .catch(error => {
      notification.warning({
        message: error.code,
        description: error.message,
      })
      return error.response
    })
}

export async function getTeacherDetails(id, payload) {
  const url = route('teachers.getById', { id })
  return axiosInstance
    .get(url, { params: payload })
    .then(res => res)
    .catch(error => {
      notification.warning({
        message: error.code,
        description: error.message,
      })
      return error.response
    })
}

export async function createTeacher(payload) {
  return axiosInstance
    .post(route('teachers.create'), payload)
    .then(res => res)
    .catch(error => {
      notification.warning({
        message: error.code,
        description: error.message,
      })
      return error.response
    })
}

export async function updateTeacher(id, payload, extraParams = {}) {
  const routeValue = route('teachers.edit', { id })

  return axiosInstance
    .post(routeValue, payload, { params: extraParams })
    .then(res => res)
    .catch(error => {
      notification.warning({
        message: error.code,
        description: error.message,
      })
      return error.response
    })
}

export async function exportTeachers(payload = {}, extraParams = {}) {
  const routeValue = route('teachers.export')
  return axiosInstance
    .post(routeValue, payload, extraParams)
    .then(res => res)
    .catch(error => {
      notification.warning({
        message: error.code,
        description: error.message,
      })
      return error.response
    })
}
