import * as appConfig from './app'
import * as routesConfig from './routes'
import * as pathConfig from './paths'

export default {
  appConfig: appConfig.default,
  routesConfig: routesConfig.default,
  pathConfig: pathConfig.default,
}

export const paymentStatus = {
  created: 'link created',
  failed: 'payment failed',
  confirmed: 'paid',
  refund: 'refund',
  refunded: 'refunded',
}

export const FeeDueStatusColor = {
  due: 'red',
  paid: 'green',
  due_in_two_classes: 'yellow',
  due_in_six_classes: 'blue',
  cancelled: 'grey',
  pending: 'white',
  proposed: 'white',
  completed: 'grey',
  trial_enrollment: 'grey',
  withdrawn: 'grey',
  reverse: 'grey',
}
