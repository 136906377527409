import { notification } from 'antd'
import axiosInstance from 'utilities/configureAxios'
import route from 'utilities/route'

export default async function getPrograms(payload = {}) {
  return axiosInstance
    .get(route('programs.index'), { params: payload.params })
    .then(res => res)
    .catch(error => {
      notification.warning({
        message: error.code,
        description: error.message,
      })
      return error.response
    })
}

export async function createProgram(payload = {}) {
  return axiosInstance
    .post(route('programs.index'), payload.data)
    .then(res => res)
    .catch(error => {
      notification.warning({
        message: error.code,
        description: error.message,
      })
      return error.response
    })
}

export async function editProgram(id, payload = { data: {} }) {
  const routeValue = route('programs.edit', { id })
  return axiosInstance
    .post(routeValue, payload.data)
    .then(res => res)
    .catch(error => {
      notification.warning({
        message: error.code,
        description: error.message,
      })
      return error.response
    })
}

export async function editProgramStatus(id, payload = { data: {} }) {
  const routeValue = route('programs.update_status', { id })
  return axiosInstance
    .patch(routeValue, payload.data)
    .then(res => res)
    .catch(error => {
      notification.warning({
        message: error.code,
        description: error.message,
      })
      return error.response
    })
}

export async function getCurrentProgram(id) {
  const routeValue = route('programs.current_program', { id })
  return axiosInstance
    .get(routeValue)
    .then(res => res)
    .catch(error => {
      notification.warning({
        message: error.code,
        description: error.message,
      })
      return error.response
    })
}

export async function getProgramRegistrations(id) {
  const routeValue = route('programs.current_program_registrations', { id })
  return axiosInstance
    .get(routeValue)
    .then(res => res)
    .catch(error => {
      notification.warning({
        message: error.code,
        description: error.message,
      })
      return error.response
    })
}
