import actions from './actions'

const initialState = {
  loading: false,
  index: [],
  index_student: [],
  pagination: {},
  current: {},
  filters: {
    course_status: ['published'],
    batch_status: ['published', 'approved'],
  },
}

export default function reportReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_FILTERS:
      return {
        ...state,
        filters: {
          ...state.filters,
          ...action.payload,
        },
      }

    case actions.SET_STATE: {
      return { ...state, ...action.payload }
    }

    case actions.SET_REPORT_FILTER: {
      return { ...state, filters: { ...state.filters, ...action.payload } }
    }

    case actions.CLEAR_FILTERS:
      return { ...state, filters: {} }

    default:
      return state
  }
}
