import { all, takeEvery, put, call, select } from 'redux-saga/effects'
import { notification } from 'antd'
import {
  getTeacherPayments,
  addTeacherPayment,
  updateTeacherPayment,
  removeTeacherPayment,
} from 'services/teacherPayments'
import actions from './actions'
import errorActions from '../error/actions'

export function* WATCH_LOAD_TEACHER_EARNING(action) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })

  const response = yield call(getTeacherPayments, action.payload)

  if (response) {
    if (response.data.success) {
      yield put({
        type: actions.SET_STATE,
        payload: {
          index: response.data.data,
          pagination: response.data.meta.pagination,
        },
      })
    } else {
      yield put({
        type: errorActions.SET_STATE,
        payload: response.data,
      })
    }
  }
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: false,
    },
  })
}

export function* WATCH_CREATE_TEACHER_EARNING(action) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: false,
    },
  })

  const response = yield call(addTeacherPayment, action.payload)

  if (response) {
    if (response.data.success) {
      const filters = yield select(state => state.teacherEarning.filters)

      yield put({
        type: errorActions.SET_STATE,
        payload: { errors: {} },
      })

      yield put({
        type: actions.LOAD_TEACHER_EARNING_INDEX,
        payload: {
          params: filters,
        },
      })

      notification.success({
        message: 'Success !',
        description: 'Teacher payment was successfully added.',
      })
    } else if (response.data.code === 422) {
      yield put({
        type: errorActions.SET_STATE,
        payload: response.data,
      })
    }
  }
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: false,
    },
  })
}

export function* WATCH_REMOVE_TEACHER_EARNING(action) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })

  const response = yield call(removeTeacherPayment, action.payload.id, { is_active: false })

  if (response) {
    if (response.data.success) {
      const filters = yield select(state => state.teacherEarning.filters)

      yield put({
        type: errorActions.SET_STATE,
        payload: { errors: {} },
      })

      yield put({
        type: actions.LOAD_TEACHER_EARNING_INDEX,
        payload: {
          params: filters,
        },
      })

      notification.success({
        message: 'Success!',
        description: 'Teacher payment was successfully removed.',
      })
    } else if (response.data.code === 422) {
      yield put({
        type: errorActions.SET_STATE,
        payload: response.data,
      })
    }
  }

  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: false,
    },
  })
}

export function* WATCH_UPDATE_TEACHER_EARNING(action) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })

  const response = yield call(updateTeacherPayment, action.payload.id, action.payload.data)
  if (response) {
    if (response.data.success) {
      const filters = yield select(state => state.teacherEarning.filters)

      yield put({
        type: actions.SET_STATE,
        payload: { current: response.data.data },
      })

      yield put({
        type: actions.LOAD_TEACHER_EARNING_INDEX,
        payload: {
          params: filters,
        },
      })

      notification.success({
        message: 'Success !',
        description: 'Teacher payment updated successfully. ',
      })
    } else if (response.data.code === 422) {
      yield put({
        type: errorActions.SET_STATE,
        payload: response.data,
      })
    }
  }
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: false,
    },
  })
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.LOAD_TEACHER_EARNING_INDEX, WATCH_LOAD_TEACHER_EARNING),
    takeEvery(actions.CREATE_NEW_TEACHER_EARNING, WATCH_CREATE_TEACHER_EARNING),
    takeEvery(actions.UPDATE_TEACHER_EARNING, WATCH_UPDATE_TEACHER_EARNING),
    takeEvery(actions.REMOVE_TEACHER_EARNING, WATCH_REMOVE_TEACHER_EARNING),
  ])
}
