const actions = {
  SET_LOADING: 'counselorPayment/SET_LOADING',
  SET_BATCH_DETAILS: 'counselorPayment/SET_BATCH_DETAILS',
  SET_JOIN_DATE: 'counselorPayment/SET_JOIN_DATE',
  SET_JOIN_CLASS: 'counselorPayment/SET_JOIN_CLASS',
  SET_CHILD_DETAILS: 'counselorPayment/SET_CHILD_DETAILS',
  SET_INSTALLMENT_DETAILS: 'counselorPayment/SET_INSTALLMENT_DETAILS',
  SET_STATE: 'counselorPayment/SET_STATE',
  BOOK_TRIAL_CLASS: 'counselorPayment/BOOK_TRIAL_CLASS',
  ADD_CART: 'counselorPayment/ADD_CART',
  CHECKOUT: 'counselorPayment/CHECKOUT',
  CHECKOUT_SUCCESS: 'counselorPayment/CHECKOUT_SUCCESS',
  CHECKOUT_LOADING: 'counselorPayment/CHECKOUT_LOADING',
  CLEAR_TRIAL_DETAILS: 'counselorPayment/CLEAR_TRIAL_DETAILS',
  SELECTED_INSTALLMENT: 'counselorPayment/SELECTED_INSTALLMENT',
  PARENTS_COUPONS: 'counselorPayment/PARENTS_COUPONS',
  PARENTS_COUPONS_SUCCSESS: 'counselorPayment/PARENTS_COUPONS_SUCCSESS',
  APPLY_COUPON_MODAL: 'counselorPayment/APPLY_COUPON_MODAL',
  TOGGLE_COUPON: 'counselorPayment/TOGGLE_COUPON',
  TOGGLE_COUPON_SUCCESS: 'counselorPayment/TOGGLE_COUPON_SUCCESS',
  REMOVE_COUPON: 'counselorPayment/REMOVE_COUPON',
  APPLY_COUPON: 'counselorPayment/APPLY_COUPON',
  ADDITIONAL_DISCOUNT: 'counselorPayment/ADDITIONAL_DISCOUNT',
  REMOVE_STATE: 'counselorPayment/REMOVE_STATE',
  REMOVE_CHILD_STATE: 'counselorPayment/REMOVE_CHILD_STATE',
  REMOVE_INSTALLMENT_DETAILS: 'counselorPayment/REMOVE_INSTALLMENT_DETAILS',
}

export default actions
