import { all, takeEvery, put, call } from 'redux-saga/effects'
import getParents, {
  createGuardian,
  updateGuardian,
  getGuardianDetails,
  getCartItems,
  deleteCartItem,
  getGuardianFamilyDetails,
  linkGuardian,
} from 'services/guardians'
import { push } from 'react-router-redux'
import { notification } from 'antd'
import { history } from 'index'
import actions from './actions'
import errorActions from '../error/actions'

export function* WATCH_LOAD_GUARDIANS_INDEX(action) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })

  const response = yield call(getParents, action.payload)
  if (response) {
    if (response.data.success) {
      if (response.data.meta) {
        yield put({
          type: actions.SET_STATE,
          payload: {
            index: response.data.data,
            CounselorParent: response.data.data[0],
            pagination: response.data.meta.pagination,
          },
        })
      } else {
        yield put({
          type: actions.SET_STATE,
          payload: {
            TotalParents: response.data.data,
          },
        })
      }
    } else {
      yield put({
        type: errorActions.SET_STATE,
        payload: response.data,
      })
    }
  }
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: false,
    },
  })
}

export function* WATCH_CREATE_GUARDIAN(action) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })

  const response = yield call(createGuardian, action.payload)

  if (response) {
    if (response.data.success) {
      if (history.location.pathname === '/counsellor/parents') {
        yield put(push('/counsellor/parents'))
      } else {
        yield put(push('/parents'))
      }

      yield put({
        type: errorActions.SET_STATE,
        payload: { errors: {} },
      })
      notification.success({
        message: 'Success !',
        description: 'Parent created successfully.',
      })
    } else if (response.data.code === 422) {
      yield put({
        type: errorActions.SET_STATE,
        payload: response.data,
      })
    }
  }
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: false,
    },
  })
}

export function* WATCH_UPDATE_GUARDIAN(action) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })

  const response = yield call(
    updateGuardian,
    action.payload.id,
    action.payload.values,
    action.payload.query,
  )
  if (response) {
    if (response.data.success) {
      yield put({
        type: actions.SET_STATE,
        payload: { current: response.data.data },
      })
      if (history.location.pathname.includes('/counsellor/parents')) {
        yield put(push('/counsellor/parents'))
      } else {
        yield put(push('/parents'))
      }
      notification.success({
        message: 'Success !',
        description: 'Details saved successfully.',
      })
    } else if (response.data.code === 422) {
      yield put({
        type: errorActions.SET_STATE,
        payload: response.data,
      })
    }
  }
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: false,
    },
  })
}

export function* WATCH_LOAD_SELECTED_GUARDIAN(action) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })

  const response = yield call(getGuardianDetails, action.payload.id, action.payload.query)

  if (response) {
    if (response.data.success) {
      yield put({
        type: actions.SET_STATE,
        payload: { current: response.data.data },
      })
    } else if (response.data.code === 422) {
      yield put({
        type: errorActions.SET_STATE,
        payload: response.data,
      })
    }
  }
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: false,
    },
  })
}

export function* WATCH_LOAD_CART_ITEMS(action) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })

  const response = yield call(getCartItems, action.payload.id, action.payload)
  if (response) {
    if (response.data.success) {
      yield put({
        type: actions.SET_STATE,
        payload: {
          cart: response.data.data,
        },
      })
    } else {
      yield put({
        type: errorActions.SET_STATE,
        payload: response.data,
      })
    }
  }
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: false,
    },
  })
}

export function* WATCH_DELETE_CART_ITEMS(action) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })

  const response = yield call(deleteCartItem, action.payload)
  if (response) {
    if (response.status === 204) {
      yield put({
        type: actions.LOAD_CART_ITEMS,
        payload: { id: action.payload.parentId },
      })
    } else {
      yield put({
        type: errorActions.SET_STATE,
        payload: response.data,
      })
    }
  }
}

export function* WATCH_LOAD_FAMILY_INDEX(action) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })

  const response = yield call(getGuardianFamilyDetails, action.payload.id, action.payload.query)

  if (response) {
    if (response.data.success) {
      yield put({
        type: actions.SET_STATE,
        payload: { familyMembers: response.data.data },
      })
    } else if (response.data.code === 422) {
      yield put({
        type: errorActions.SET_STATE,
        payload: response.data,
      })
    }
  }
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: false,
    },
  })
}

export function* WATCH_LINK_GUARDIAN(action) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })

  const { formData, id } = action.payload

  const response = yield call(linkGuardian, formData, id)

  if (response) {
    if (response.data.success) {
      yield put({
        type: errorActions.SET_STATE,
        payload: { errors: {} },
      })
      yield put(push(`/parents/${id}/account-linking`))
      notification.success({
        message: 'Success !',
        description: 'Guardian Linked successfully.',
      })
      // fetch new list of guardians
      yield put({
        type: actions.LOAD_FAMILY_INDEX,
        payload: { id },
      })
    } else if (response.data.code === 422) {
      yield put({
        type: errorActions.SET_STATE,
        payload: response.data,
      })
    }
  }
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: false,
    },
  })
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.LOAD_GUARDIANS_INDEX, WATCH_LOAD_GUARDIANS_INDEX),
    takeEvery(actions.CREATE_GUARDIAN, WATCH_CREATE_GUARDIAN),
    takeEvery(actions.LOAD_SELECTED_GUARDIAN, WATCH_LOAD_SELECTED_GUARDIAN),
    takeEvery(actions.EDIT_GUARDIAN, WATCH_UPDATE_GUARDIAN),
    takeEvery(actions.LOAD_CART_ITEMS, WATCH_LOAD_CART_ITEMS),
    takeEvery(actions.DELETE_CART_ITEM, WATCH_DELETE_CART_ITEMS),
    takeEvery(actions.LOAD_FAMILY_INDEX, WATCH_LOAD_FAMILY_INDEX),
    takeEvery(actions.LINK_GUARDIAN, WATCH_LINK_GUARDIAN),
  ])
}
