import { notification } from 'antd'
import axiosInstance from 'utilities/configureAxios'
import route from 'utilities/route'

export default async function getNotifications(payload = { params: {} }) {
  return axiosInstance
    .get(route('notifications.index'), {
      params: payload.params,
    })
    .then(res => res)
    .catch(error => {
      notification.warning({
        message: error.code,
        description: error.message,
      })
      return error.response
    })
}

export async function createNotification(payload = {}) {
  return axiosInstance
    .post(route('notifications.create'), payload.data)
    .then(res => {
      return res
    })
    .catch(error => {
      notification.warning({
        message: error.code,
        description: error.message,
      })
      return error.response
    })
}

export async function getRecipients(query, recipient) {
  let routeValue = ''

  switch (recipient) {
    case 'teacher':
      routeValue = route('notifications.teacherRecipients')
      break
    case 'parent':
      routeValue = route('notifications.parentRecipients')
      break
    case 'enrollment':
      routeValue = route('notifications.enrollmentRecipients')
      break
    default:
  }

  const params = {
    q: query,
    all: true,
  }

  if (recipient === 'enrollment') {
    params.student_name = query
    delete params.q
  }

  return axiosInstance
    .get(routeValue, {
      params,
    })
    .then(res => res)
    .catch(error => {
      notification.warning({
        message: error.code,
        description: error.message,
      })
      return error.response
    })
}

export async function exportNotifications(payload = {}, extraParams = {}) {
  const routeValue = route('notifications.export')
  return axiosInstance
    .post(routeValue, payload, extraParams)
    .then(res => res)
    .catch(error => {
      notification.warning({
        message: error.code,
        description: error.message,
      })
      return error.response
    })
}
