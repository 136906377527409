import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import user from './user/reducers'
import menu from './menu/reducers'
import settings from './settings/reducers'
import courses from './courses/reducers'
import error from './error/reducers'
import auth from './auth/reducers'
import tags from './tags/reducers'
import batches from './batches/reducers'
import standards from './standards/reducers'
import guardians from './guardians/reducers'
import teachers from './teachers/reducers'
import admins from './admin/reducers'
import centers from './centers/reducers'
import enrollments from './enrollments/reducers'
import orders from './orders/reducers'
import cities from './cities/reducers'
import zones from './zones/reducers'
import configurations from './configurations/reducers'
import locations from './location/reducers'
import payments from './payments/reducers'
import classes from './classes/reducers'
import ledgers from './ledgers/reducers'
import coupons from './coupons/reducers'
import notifications from './notifications/reducers'
import programs from './programs/reducers'
import feedbacks from './feedbacks/reducers'
import roles from './roles/reducers'
import counselor from './counselor/reducer'
import counselorPayment from './counselorPayment/reducer'
import testimonials from './testimonials/reducers'
import courseLearnings from './courseLearnings/reducers'
import report from './report/reducers'
import wallet from './wallet/reducers'
import trial from './trial/reducers'
import boards from './boards/reducers'
import cancellationReasons from './cancellationReasons/reducer'
import batchLabels from './batchLabels/reducer'
import teacherEarning from './teacherEarning/reducers'

export default history =>
  combineReducers({
    router: connectRouter(history),
    user,
    menu,
    settings,
    courses,
    classes,
    error,
    auth,
    tags,
    batches,
    standards,
    guardians,
    teachers,
    admins,
    centers,
    enrollments,
    orders,
    cities,
    zones,
    configurations,
    locations,
    payments,
    ledgers,
    coupons,
    notifications,
    programs,
    feedbacks,
    roles,
    counselor,
    counselorPayment,
    testimonials,
    courseLearnings,
    report,
    wallet,
    trial,
    boards,
    cancellationReasons,
    batchLabels,
    teacherEarning,
  })
