const actions = {
  SET_STATE: 'coupons/SET_STATE',
  LOAD_COUPONS_INDEX: 'coupons/LOAD_COUPONS_INDEX',
  LOAD_CURRENT_COUPON: 'coupons/LOAD_CURRENT_COUPON',
  LOAD_CURRENT_COUPON_ASYNC: 'coupons/LOAD_CURRENT_COUPON_ASYNC',
  CREATE_COUPON: 'coupons/CREATE_COUPON',
  UPDATE_COUPON: 'coupons/UDPATE_COUPON',
  SET_FILTERS: 'coupons/SET_FILTERS',
  CLEAR_FILTERS: 'coupons/CLEAR_FILTERS',
}

export default actions
