import { notification } from 'antd'
import axiosInstance from 'utilities/configureAxios'
import route from 'utilities/route'

export default async function getCities(payload = {}) {
  return axiosInstance
    .get(route('cities.index'), payload)
    .then(res => res)
    .catch(error => {
      notification.warning({
        message: error.code,
        description: error.message,
      })
      return error.response
    })
}

export async function createCity(payload = {}) {
  return axiosInstance
    .post(route('cities.index'), payload)
    .then(res => res)
    .catch(error => {
      notification.warning({
        message: error.code,
        description: error.message,
      })
      return error.response
    })
}

export async function getCurrentCity(cityId, payload = {}) {
  const url = route('cities.current_city') + cityId
  return axiosInstance
    .get(url, payload)
    .then(res => res)
    .catch(error => {
      notification.warning({
        message: error.code,
        description: error.message,
      })
      return error.response
    })
}

export async function updateCurrentCity(cityId, payload = {}) {
  const url = route('cities.current_city') + cityId

  return axiosInstance
    .post(url, payload)
    .then(res => res)
    .catch(error => {
      notification.warning({
        message: error.code,
        description: error.message,
      })
      return error.response
    })
}
