const actions = {
  SET_STATE: 'orders/SET_STATE',
  LOAD_ORDERS_INDEX: 'orders/LOAD_ORDERS_INDEX',
  COLLECT_FEES: 'orders/COLLECT_FEES',
  COLLECT_FEES_EMANDATE: 'orders/COLLECT_FEES_EMANDATE',
  UPDATE_ORDER_AMOUNT: 'orders/UPDATE_ORDER_AMOUNT',
  SET_UPDATE_ORDER_AMOUNT_MODAL: 'orders/SET_UPDATE_ORDER_AMOUNT_MODAL',
  LOAD_CURRENT_ORDER: 'orders/LOAD_CURRENT_ORDER',
  LOAD_CURRENT_ORDER_ASYNC: 'orders/LOAD_CURRENT_ORDER_ASYNC',
  SET_FILTERS: 'orders/SET_FILTERS',
  CLEAR_FILTERS: 'orders/CLEAR_FILTERS',
  ADD_DUE_AMOUNT: 'orders/ADD_DUE_AMOUNT',
  SET_ADD_DUE_AMOUNT_MODAL: 'orders/SET_ADD_DUE_AMOUNT_MODAL',
  UPDATE_ORDER_STATUS: 'orders/UPDATE_ORDER_STATUS',
}

export default actions
