import { all, takeEvery, put, call } from 'redux-saga/effects'
import getBatches, {
  getSingleBatch,
  createBatch,
  updateBatch,
  sendSchedule,
  fetchInstallments,
  getGroupID,
} from 'services/batches'
import { push } from 'react-router-redux'
import { notification } from 'antd'
import actions from './actions'
import errorActions from '../error/actions'

export function* WATCH_LOAD_BATCHES_INDEX(action) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })

  const response = yield call(getBatches, action.payload)

  if (response) {
    if (response.data.success) {
      yield put({
        type: actions.SET_STATE,
        payload: {
          index: response.data.data,
          pagination: response.data.meta.pagination,
        },
      })
    } else {
      yield put({
        type: errorActions.SET_STATE,
        payload: response.data,
      })
    }
  }
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: false,
    },
  })
}

export function* LOAD_INSTALLMENTS() {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })

  const response = yield call(fetchInstallments)
  if (response) {
    if (response.data.success) {
      yield put({
        type: actions.SET_STATE,
        payload: {
          installments: response.data.data,
        },
      })
    } else {
      yield put({
        type: errorActions.SET_STATE,
        payload: response.data,
      })
    }
  }
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: false,
    },
  })
}

export function* WATCH_LOAD_CURRENT_BATCH(action) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })

  const response = yield call(getSingleBatch, action.payload.id, action.payload)

  if (response) {
    if (response.data.success) {
      yield put({
        type: actions.LOAD_CURRENT_BATCH_ASYNC,
        payload: {
          current: response.data.data,
        },
      })
    } else {
      yield put({
        type: errorActions.SET_STATE,
        payload: response.data,
      })
    }
  }
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: false,
    },
  })
}

export function* WATCH_UPDATE_BATCH(action) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })
  const response = yield call(updateBatch, action.payload.id, action.payload)

  if (response) {
    if (response.data.success) {
      yield put(push('/batches'))
      notification.success({
        message: 'Success!',
        description: 'Batch updated successfully',
      })
    } else {
      yield put({
        type: errorActions.SET_STATE,
        payload: response.data,
      })
    }
  }
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: false,
    },
  })
}

export function* WATCH_CREATE_BATCH(action) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })

  const response = yield call(createBatch, action.payload)

  if (response) {
    if (response.data.success) {
      yield put(push('/batches'))
      notification.success({
        message: 'Success!',
        description: 'Batch created successfully',
      })
    } else {
      yield put({
        type: errorActions.SET_STATE,
        payload: response.data,
      })
    }
  }
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: false,
    },
  })
}

export function* WATCH_UPDATE_STATUS(action) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })

  const response = yield call(updateBatch, action.payload.id, action.payload.data)

  if (response) {
    if (response.data.success) {
      yield put({
        type: actions.LOAD_BATCHES_INDEX,
        payload: { params: action.payload.filters },
      })
      notification.success({
        message: 'Success !',
        description: 'Status Changed Success',
      })
    } else {
      yield put({
        type: errorActions.SET_STATE,
        payload: response.data,
      })
      notification.warning({
        message: 'Failure !',
        description: 'Status Cannot Be Changed',
      })
    }
  }
}

export function* WATCH_SEND_SCHEDULE(action) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })

  const response = yield call(sendSchedule, action.payload)
  if (response) {
    if (response.data.success) {
      yield put({
        type: actions.TOGGLE_SCHEDULE_MODAL,
        payload: {
          visible: false,
        },
      })
      notification.success({
        message: 'Success !',
        description: 'Schedule Email Sent Successfully',
      })
    } else {
      yield put({
        type: errorActions.SET_STATE,
        payload: response.data,
      })
    }
  }
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: false,
    },
  })
}

export function* WATCH_GET_CONTENT_GROUP() {
  yield put({
    type: actions.SET_STATE,
    payload: { loading: true },
  })

  const response = yield call(getGroupID)
  if (response) {
    if (response) {
      yield put({
        type: actions.SET_STATE,
        payload: {
          contentGroupOnLMS: response,
        },
      })
    } else if (response.data.code === 422) {
      yield put({
        type: errorActions.SET_STATE,
        payload: response.data,
      })
      notification.warning({
        message: 'Failure !',
        description: 'Status Cannot Be Changed',
      })
    }
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.LOAD_BATCHES_INDEX, WATCH_LOAD_BATCHES_INDEX),
    takeEvery(actions.LOAD_INSTALLMENTS, LOAD_INSTALLMENTS),
    takeEvery(actions.LOAD_CURRENT_BATCH, WATCH_LOAD_CURRENT_BATCH),
    takeEvery(actions.CREATE_BATCH, WATCH_CREATE_BATCH),
    takeEvery(actions.UPDATE_BATCH, WATCH_UPDATE_BATCH),
    takeEvery(actions.UPDATE_STATUS, WATCH_UPDATE_STATUS),
    takeEvery(actions.SEND_SCHEDULE, WATCH_SEND_SCHEDULE),
    yield all([takeEvery(actions.GET_GET_CONTENT_GROUP, WATCH_GET_CONTENT_GROUP)]),
  ])
}
