import { notification } from 'antd'
import axiosInstance from 'utilities/configureAxios'
import route from 'utilities/route'

export default async function fetchCourseLearnings(payload = {}) {
  return axiosInstance
    .get(route('courseLearning.index'), payload)
    .then(res => res)
    .catch(error => {
      notification.warning({
        message: error.code,
        description: error.message,
      })
      return error.response
    })
}

export async function getCourseLearning(payload = {}) {
  const routeValue = `${route('courseLearning.index')}/${payload.id}`
  return axiosInstance
    .get(routeValue, {
      params: payload.params,
    })
    .then(res => res)
    .catch(error => {
      notification.warning({
        message: error.code,
        description: error.message,
      })
      return error.response
    })
}

export async function createCourseLearningsUpdate(id, data = new FormData()) {
  return axiosInstance
    .post(route('courseLearning.courseLearning_update', { id }), data)
    .then(res => res)
    .catch(error => {
      notification.warning({
        message: error.code,
        description: error.message,
      })
      return error.response
    })
}

export async function createCourseLearnings(payload = {}) {
  return axiosInstance
    .post(route('courseLearning.create'), payload)
    .then(res => res)
    .catch(error => {
      notification.warning({
        message: error.code,
        description: error.message,
      })
      return error.response
    })
}

export async function updateCourseLearnings(courseLearningId, payload = {}) {
  const routeValue = route('courseLearning.update') + courseLearningId
  return axiosInstance
    .post(routeValue, payload)
    .then(res => res)
    .catch(error => {
      notification.warning({
        message: error.code,
        description: error.message,
      })
      return error.response
    })
}

export async function deleteCourseLearning(courseLearningId) {
  const routeValue = `${route('courseLearning.delete')}${courseLearningId}`
  return axiosInstance
    .delete(routeValue)
    .then(res => res)
    .catch(error => {
      notification.warning({
        message: error.code,
        description: error.message,
      })
      return error.response
    })
}
