import { notification } from 'antd'
import axiosInstance from 'utilities/configureAxios'
import route from 'utilities/route'

export default async function getCoupons(payload = {}) {
  return axiosInstance
    .get(route('coupons.index'), payload)
    .then(res => res)
    .catch(error => {
      notification.warning({
        message: error.code,
        description: error.message,
      })
      return error.response
    })
}

export async function getCurrentCoupon(id, params = {}) {
  const url = route('coupons.current_coupon', { id })
  return axiosInstance
    .get(url, { params })
    .then(res => res)
    .catch(error => {
      notification.warning({
        message: error.code,
        description: error.message,
      })
      return error.response
    })
}

export async function createCoupon(payload = {}) {
  return axiosInstance
    .post(route('coupons.create'), payload)
    .then(res => res)
    .catch(error => {
      notification.warning({
        message: error.code,
        description: error.message,
      })
      return error.response
    })
}

export async function updateCoupon(id, payload = {}) {
  const url = route('coupons.current_coupon', { id })
  return axiosInstance
    .patch(url, payload)
    .then(res => res)
    .catch(error => {
      notification.warning({
        message: error.code,
        description: error.message,
      })
      return error.response
    })
}

export async function exportCoupons(payload = {}, extraParams = {}) {
  const routeValue = route('coupons.export')
  return axiosInstance
    .post(routeValue, payload, extraParams)
    .then(res => res)
    .catch(error => {
      notification.warning({
        message: error.code,
        description: error.message,
      })
      return error.response
    })
}
