import actions from './actions'

const initialState = {
  relatedTags: [],
}

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case actions.LOAD_RELATED_TAGS_ASYNC:
      return {
        ...state,
        relatedTags: action.payload,
      }

    default:
      return state
  }
}
