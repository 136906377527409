const actions = {
  LOAD_BATCHES_INDEX: 'batches/LOAD_BATCHES_INDEX',
  LOAD_INSTALLMENTS: 'courses/LOAD_INSTALLMENTS',
  SET_FILTERS: 'batches/SET_FILTERS',
  SET_STATE: 'batches/SET_STATE',
  LOAD_CURRENT_BATCH: 'batches/LOAD_CURRENT_BATCH',
  LOAD_CURRENT_BATCH_ASYNC: 'batches/LOAD_CURRENT_BATCH_ASYNC',
  CREATE_BATCH: 'batches/CREATE_BATCH',
  UPDATE_BATCH: 'batches/UPDATE_BATCH',
  CLEAR_FILTERS: 'batches/CLEAR_FILTERS',
  UPDATE_STATUS: 'batches/UPDATE_STATUS',
  CLEAR_CURRENT: 'batches/CLEAR_CURRENT',
  TOGGLE_SCHEDULE_MODAL: 'batches/TOGGLE_SCHEDULE_MODAL',
  SEND_SCHEDULE: 'batches/SEND_SCHEDULE',
  GET_GET_CONTENT_GROUP: 'GET_GET_CONTENT_GROUP',
}

export default actions
