const actions = {
  SET_STATE: 'classes/SET_STATE',
  LOAD_CLASSES_INDEX: 'classes/LOAD_CLASSES_INDEX',
  LOAD_CURRENT_CLASS: 'classes/LOAD_CURRENT_CLASS',
  MARK_CLASS_ATTENDENCE: 'classes/MARK_CLASS_ATTENDENCE',
  CLEAR_CURRENT: 'classes/CLEAR_CURRENT',
  SET_FILTERS: 'classes/SET_FILTERS',
  CLEAR_FILTERS: 'classes/CLEAR_FILTERS',
  CREATE_CLASS_UPDATE: 'classes/CREATE_CLASS_UPDATE',
  MARK_CLASS_HOLIDAY: 'classes/MARK_CLASS_HOLIDAY',
  UPDATE_SPECIFIC_CLASS: 'classes/UPDATE_SPECIFIC_CLASS',
}

export default actions
