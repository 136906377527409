import actions from './actions'

const initialState = {
  loading: false,
  visible: null,
  index: [],
  pagination: null,
  teacher: {},
  current: {},
  filters: {
    page: 1,
    per_page: 15,
  },
}

export default function teacherEarningReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_FILTERS:
      return {
        ...state,
        filters: {
          ...state.filters,
          ...action.payload,
        },
      }

    case actions.SET_STATE:
      return { ...state, ...action.payload }

    case actions.SET_SELECTED_TEACHER:
      return { ...state, teacher: action.payload }

    case actions.CLEAR_FILTERS:
      return {
        ...state,
        filters: {
          page: 1,
          per_page: 15,
        },
      }

    case actions.CLEAR_SELECTED_TEACHER:
      return { ...state, teacher: {} }

    case actions.CLEAR_CURRENT:
      return { ...state, current: {} }

    default:
      return state
  }
}
