import { notification } from 'antd'
import axiosInstance from 'utilities/configureAxios'
import route from 'utilities/route'

export async function getCourses(params = {}) {
  return axiosInstance
    .get(route('counselor.courses'), { params })
    .then(res => res)
    .catch(error => {
      notification.warning({
        message: error.code,
        description: error.message,
      })
      return error.response
    })
}

export async function getStandards(params = {}) {
  const url = route('counselor.grades')
  return axiosInstance
    .get(url, { params })
    .then(res => res)
    .catch(error => {
      notification.warning({
        message: error.code,
        description: error.message,
      })
      return error.response
    })
}

export async function getCounsleorsApi() {
  const url = route('counselor.counselors')
  return axiosInstance
    .get(url)
    .then(res => res)
    .catch(error => {
      notification.warning({
        message: error.code,
        description: error.message,
      })
      return error.response
    })
}

export async function getSingleCourseDetails(id, params = {}) {
  const url = route('counselor.courseDetails') + id
  return axiosInstance
    .get(url, { params })
    .then(res => res)
    .catch(error => {
      notification.warning({
        message: error.code,
        description: error.message,
      })
      return error.response
    })
}

export async function counselorEnrollmentsApi(params = {}) {
  const url = route('counselor.enrollmentDetails')
  return axiosInstance
    .get(url, { params })
    .then(res => res)
    .catch(error => {
      notification.warning({
        message: error.code,
        description: error.message,
      })
      return error.response
    })
}

export async function addChildApi(id, payload) {
  const url = route('counselor.addChild') + id
  return axiosInstance
    .post(url, payload)
    .then(res => res)
    .catch(error => {
      notification.warning({
        message: error.code,
        description: error.message,
      })
      return error.response
    })
}

export async function addLeaddApi(payload) {
  const url = route('counselor.addLead')
  return axiosInstance
    .post(url, payload)
    .then(res => res)
    .catch(error => {
      notification.warning({
        message: error.code,
        description: error.message,
      })
      return error.response
    })
}

export async function trialClassDatesApi(id, params = {}) {
  const url = `${route('counselor.trialClassDates') + id}/trial-classes`
  return axiosInstance
    .get(url, { params })
    .then(res => res)
    .catch(error => {
      notification.warning({
        message: error.code,
        description: error.message,
      })
      return error.response
    })
}

export async function installmentBreakageAPi(id, params = {}) {
  const url = `${route('counselor.installmentBreakage') + id}/installment-breakage`
  return axiosInstance
    .get(url, { params })
    .then(res => res)
    .catch(error => {
      notification.warning({
        message: error.code,
        description: error.message,
      })
      return error.response
    })
}

export async function specifyCouponApi(id, payload) {
  const url = `${route('counselor.specifyCoupon') + id}/attach-parents`
  return axiosInstance
    .patch(url, payload)
    .then(res => res)
    .catch(error => {
      notification.warning({
        message: error.code,
        description: error.message,
      })
      return error.response
    })
}

export async function paymentLinkApi(id, isWallet) {
  const url = `${route('counselor.paymentLink') + id}/payment-link/generate?includes=timings,centre`
  return axiosInstance
    .post(url, { is_wallet: isWallet })
    .then(res => res)
    .catch(error => {
      notification.warning({
        message: error.code,
        description: error.message,
      })
      return error.response
    })
}

export async function shareResourcesApi(id, params = {}) {
  const url = `${route('counselor.shareResource') + id}/share`
  return axiosInstance
    .get(url, { params })
    .then(res => res)
    .catch(error => {
      notification.warning({
        message: error.code,
        description: error.message,
      })
      return error.response
    })
}

export async function classesForEachBatchApi(id, params = {}) {
  const url = `${`${route('counselor.classesForEachBatch')}?batch_id=${id}&all=true`}`
  return axiosInstance
    .get(url, { params })
    .then(res => res)
    .catch(error => {
      notification.warning({
        message: error.code,
        description: error.message,
      })
      return error.response
    })
}

export async function validateEnrollment(payload = {}) {
  return axiosInstance
    .post(route('counselor.validate_enrollment'), payload)
    .then(res => res)
    .catch(error => {
      notification.warning({
        message: error.code,
        description: error.message,
      })

      return error.response
    })
}

export async function generateClassesCount(payload = {}) {
  return axiosInstance
    .post(route('counselor.classes_count'), payload)
    .then(res => res)
    .catch(error => {
      notification.warning({
        message: error.code,
        description: error.message,
      })
      return error.response
    })
}
