import { all, takeEvery, put, call } from 'redux-saga/effects'

import fetchStandardsIndex from 'services/standards'
import actions from './actions'
import errorActions from '../error/actions'

export function* WATCH_LOAD_STANDARDS_INDEX(action) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })

  const response = yield call(fetchStandardsIndex, action.payload)

  if (response) {
    if (response.data.success) {
      yield put({
        type: actions.SET_STATE,
        payload: {
          index: response.data.data,
        },
      })
    } else {
      yield put({
        type: errorActions.SET_STATE,
        payload: response.data,
      })
    }
  }
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: false,
    },
  })
}

export default function* rootSaga() {
  yield all([takeEvery(actions.LOAD_STANDARDS_INDEX, WATCH_LOAD_STANDARDS_INDEX)])
}
