import actions from './actions'

const initialState = {
  loading: false,
  submitLoading: false,
  index: [],
  pagination: null,
  current: {},
  orderUpdateModal: { visible: false, order: {}, loading: false },
  addDueAmountModal: { visible: false, order: {}, loading: false },
  filters: {
    page: 1,
    per_page: 15,
    status: 'pending',
    enrollment_status: 'confirmed',
  },
}

export default function orderReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_FILTERS:
      return {
        ...state,
        filters: {
          ...state.filters,
          ...action.payload,
        },
      }

    case actions.SET_STATE:
      return { ...state, ...action.payload }

    case actions.CLEAR_FILTERS:
      return {
        ...state,
        filters: {
          page: 1,
          per_page: 15,
          status: 'pending',
          enrollment_status: 'confirmed',
        },
      }

    case actions.LOAD_CURRENT_ORDER_ASYNC:
      return { ...state, current: action.payload }

    case actions.SET_UPDATE_ORDER_AMOUNT_MODAL:
      return { ...state, orderUpdateModal: { ...state.orderUpdateModal, ...action.payload } }

    case actions.SET_ADD_DUE_AMOUNT_MODAL:
      return { ...state, addDueAmountModal: { ...state.addDueAmountModal, ...action.payload } }

    default:
      return state
  }
}
