import { notification } from 'antd'
import axiosInstance from 'utilities/configureAxios'
import route from 'utilities/route'

export default async function addTrialSlot(payload = {}) {
  const url = route('trial.index')
  return axiosInstance
    .post(url, payload)
    .then(res => res.data)
    .catch(error => {
      // notification.warning({
      //   message: error.code,
      //   description: error.message,
      // })
      return error.response
    })
}
export async function editTrialSlot(payload = {}) {
  const url = `${route('trial.index')}/${payload.slot_id}`

  return axiosInstance
    .post(url, payload)
    .then(res => res.data)
    .catch(error => {
      // notification.warning({
      //   message: error.code,
      //   description: error.message,
      // })
      return error.response
    })
}
export async function addTrialEnrollment(payload = {}) {
  const url = route('trial.trialEnrollment')
  return axiosInstance
    .post(url, payload)
    .then(res => res.data)
    .catch(error => {
      // console.log('error', error)
      // notification.warning({
      //   message: error.code,
      //   description: error.message,
      // })
      return error.response
    })
}
export async function markAttendanceApi(payload = {}) {
  const url = route('trial.trialAttendenece')
  return axiosInstance
    .put(url, payload)
    .then(res => res.data)
    .catch(error => {
      // console.log('error', error)
      // notification.warning({
      //   message: error.code,
      //   description: error.message,
      // })
      return error.response
    })
}

export async function getAllTrialSlot(payload = {}) {
  const url = route('trial.index')
  return axiosInstance
    .get(url, payload)
    .then(res => res)
    .catch(error => {
      notification.warning({
        message: error.code,
        description: error.message,
      })
      return error.response
    })
}

export async function exportSlotsAPi(payload = {}, extraParams = {}) {
  const routeValue = route('trial.export')
  return axiosInstance
    .post(routeValue, payload, extraParams)
    .then(res => res)
    .catch(error => {
      notification.warning({
        message: error.code,
        description: error.message,
      })
      return error.response
    })
}
export async function archiveSlotsApi(id, payload = {}) {
  const routeValue = `${route('trial.index')}/${id}`
  return axiosInstance
    .delete(routeValue, { data: payload })
    .then(res => res)
    .catch(error => {
      notification.warning({
        message: error.code,
        description: error.message,
      })
      return error.response
    })
}
export async function archiveTrialBooking(id, payload = {}) {
  const routeValue = `${route('trial.trialEnrollment')}/${id}`
  return axiosInstance
    .delete(routeValue, { data: payload })
    .then(res => res)
    .catch(error => {
      notification.warning({
        message: error.code,
        description: error.message,
      })
      return error.response
    })
}

export async function getTeacherAndCourseTrialSlot(payload = {}) {
  const routeValue = `${route('trial.index')}`
  return axiosInstance
    .get(routeValue, payload)
    .then(res => res)
    .catch(error => {
      notification.warning({
        message: error.code,
        description: error.message,
      })
      return error.response
    })
}

export async function exportTrialSlotConverionAPi(payload = {}, extraParams = {}) {
  const routeValue = route('trial.trialSlotConversion')
  return axiosInstance
    .post(routeValue, payload, extraParams)
    .then(res => res)
    .catch(error => {
      notification.warning({
        message: error.code,
        description: error.message,
      })
      return error.response
    })
}
export async function exportTrialSlotConverionDetailAPi(payload = {}, extraParams = {}) {
  const routeValue = route('trial.trialSlotConversionDetail')
  return axiosInstance
    .post(routeValue, payload, extraParams)
    .then(res => res)
    .catch(error => {
      notification.warning({
        message: error.code,
        description: error.message,
      })
      return error.response
    })
}
