import { all, takeEvery, put, call } from 'redux-saga/effects'
import getCoupons, { getCurrentCoupon, createCoupon, updateCoupon } from 'services/coupons'
import { notification } from 'antd'
import { push } from 'react-router-redux'
import actions from './actions'
import errorActions from '../error/actions'

export function* WATCH_LOAD_COUPONS_INDEX(action) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })

  const response = yield call(getCoupons, action.payload)

  if (response) {
    if (response.data.success) {
      yield put({
        type: actions.SET_STATE,
        payload: {
          index: response.data.data,
          pagination: response.data.meta.pagination,
        },
      })
    } else {
      yield put({
        type: errorActions.SET_STATE,
        payload: response.data,
      })
    }
  }
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: false,
    },
  })
}

export function* WATCH_LOAD_CURRENT_COUPON(action) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })

  const response = yield call(getCurrentCoupon, action.payload.id, action.payload.params)

  if (response) {
    if (response.data.success) {
      yield put({
        type: actions.LOAD_CURRENT_COUPON_ASYNC,
        payload: response.data.data,
      })
    } else {
      yield put({
        type: errorActions.SET_STATE,
        payload: response.data,
      })
    }
  }
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: false,
    },
  })
}

export function* CREATE_COUPON(action) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })

  const response = yield call(createCoupon, action.payload)

  if (response) {
    if (response.data.success) {
      yield put(push('/coupons'))
      notification.success({
        message: 'Success!',
        description: 'Coupons created successfully',
      })
    } else {
      yield put({
        type: errorActions.SET_STATE,
        payload: response.data,
      })
    }
  }
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: false,
    },
  })
}

export function* UPDATE_COUPON(action) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      buttonLoading: true,
    },
  })

  const response = yield call(updateCoupon, action.payload.id, action.payload.data)

  if (response) {
    if (response.data.success) {
      yield put({
        type: actions.SET_STATE,
        payload: {
          showmodal: false,
        },
      })
      notification.success({
        message: 'Success',
        description: 'Coupon Updated',
      })
      yield put(push('/coupons'))
    } else {
      yield put({
        type: errorActions.SET_STATE,
        payload: response.data,
      })
      notification.warning({
        message: 'Failure',
        description: 'Coupon Cannot Be Saved',
      })
    }
  }
  yield put({
    type: actions.SET_STATE,
    payload: {
      buttonLoading: false,
    },
  })
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.LOAD_COUPONS_INDEX, WATCH_LOAD_COUPONS_INDEX),
    takeEvery(actions.LOAD_CURRENT_COUPON, WATCH_LOAD_CURRENT_COUPON),
    takeEvery(actions.CREATE_COUPON, CREATE_COUPON),
    takeEvery(actions.UPDATE_COUPON, UPDATE_COUPON),
  ])
}
