import { all, takeEvery, put, call } from 'redux-saga/effects'
import fetchZonesIndex, { createZone, updateZone } from 'services/zones'
import actions from './actions'
import errorActions from '../error/actions'

export function* WATCH_LOAD_ZONES_INDEX(action) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })

  const response = yield call(fetchZonesIndex, action.payload)

  if (response) {
    if (response.data.success) {
      yield put({
        type: actions.SET_STATE,
        payload: {
          index: response.data.data,
          pagination: response.data.meta.pagination,
        },
      })
    } else {
      yield put({
        type: errorActions.SET_STATE,
        payload: response.data,
      })
    }
  }
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: false,
    },
  })
}

export function* WATCH_CREATE_ZONE(action) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })

  const response = yield call(createZone, action.payload, { include: 'city' })

  if (response) {
    if (response.data.success) {
      yield put({
        type: errorActions.SET_STATE,
        payload: { errors: {} },
      })
      yield put({
        type: actions.SET_STATE,
        payload: { addModalVisible: false },
      })
    } else if (response.data.code === 422) {
      yield put({
        type: errorActions.SET_STATE,
        payload: response.data,
      })
    }
  }
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: false,
    },
  })
}

export function* WATCH_UPDATE_ZONE(action) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })

  const response = yield call(updateZone, action.payload.id, action.payload.values, {
    include: 'city',
  })

  if (response) {
    if (response.data.success) {
      yield put({
        type: errorActions.SET_STATE,
        payload: { errors: {} },
      })
      yield put({
        type: actions.SET_STATE,
        payload: { editModalVisible: { visible: false, zone: null } },
      })
    } else if (response.data.code === 422) {
      yield put({
        type: errorActions.SET_STATE,
        payload: response.data,
      })
    }
  }
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: false,
    },
  })
}

export default function* rootSaga() {
  yield all([takeEvery(actions.LOAD_ZONES_INDEX, WATCH_LOAD_ZONES_INDEX)])
  yield all([takeEvery(actions.CREATE_ZONE, WATCH_CREATE_ZONE)])
  yield all([takeEvery(actions.UPDATE_ZONE, WATCH_UPDATE_ZONE)])
}
