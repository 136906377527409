import { all, takeEvery, put, call } from 'redux-saga/effects'
import getPrograms, {
  createProgram,
  getCurrentProgram,
  editProgram,
  getProgramRegistrations,
  editProgramStatus,
} from 'services/programs'
import { push } from 'react-router-redux'
import { notification } from 'antd'
import actions from './actions'
import errorActions from '../error/actions'

export function* WATCH_CREATE_PROGRAM(action) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })

  const response = yield call(createProgram, action.payload)

  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: false,
    },
  })

  if (response) {
    if (response.data.success) {
      yield put(push(`/apps/${action.payload.section}`))

      notification.success({
        message: 'Success!',
        description: 'Program created successfully',
      })
    } else {
      yield put({
        type: errorActions.SET_STATE,
        payload: response.data,
      })
    }
  }
}

export function* WATCH_EDIT_PROGRAM(action) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })

  const response = yield call(editProgram, action.payload.id, action.payload)

  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: false,
    },
  })

  if (response) {
    if (response.data.success) {
      yield put(push(`/apps/${action.payload.section}`))
      notification.success({
        message: 'Success!',
        description: 'Program updated successfully',
      })
    } else {
      yield put({
        type: errorActions.SET_STATE,
        payload: response.data,
      })
    }
  }
}

export function* WATCH_EDIT_PROGRAM_STATUS(action) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })

  const response = yield call(editProgramStatus, action.payload.id, action.payload)

  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: false,
    },
  })

  if (response) {
    if (response.data.success) {
      yield put({
        type: actions.LOAD_PROGRAMS_INDEX,
        payload: {
          params: action.payload.params,
        },
      })
      notification.success({
        message: 'Success!',
        description: 'Program status updated successfully',
      })
    } else {
      yield put({
        type: errorActions.SET_STATE,
        payload: response.data,
      })
    }
  }
}

export function* LOAD_PROGRAMS_INDEX(action) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })

  const response = yield call(getPrograms, action.payload)

  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: false,
    },
  })

  if (response) {
    if (response.data.success) {
      yield put({
        type: actions.SET_STATE,
        payload: {
          index: response.data.data,
          pagination: response.data.meta.pagination,
        },
      })
    } else {
      yield put({
        type: errorActions.SET_STATE,
        payload: response.data,
      })
    }
  }
}

export function* WATCH_LOAD_SELECTED_PROGRAM(action) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })

  const response = yield call(getCurrentProgram, action.payload.id)

  if (response) {
    if (response.data.success) {
      yield put({
        type: actions.SET_STATE,
        payload: { current: response.data.data },
      })
    } else if (response.data.code === 422) {
      yield put({
        type: errorActions.SET_STATE,
        payload: response.data,
      })
    }
  }
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: false,
    },
  })
}

export function* LOAD_PROGRAM_REGISTRATIONS(action) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })

  const response = yield call(getProgramRegistrations, action.payload.id)

  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: false,
    },
  })

  if (response) {
    if (response.data.success) {
      yield put({
        type: actions.SET_STATE,
        payload: {
          current_program_registrations: response.data.data,
        },
      })
    } else {
      yield put({
        type: errorActions.SET_STATE,
        payload: response.data,
      })
    }
  }
}

export default function* rootSaga() {
  yield all([takeEvery(actions.CREATE_PROGRAM, WATCH_CREATE_PROGRAM)])
  yield all([takeEvery(actions.EDIT_PROGRAM, WATCH_EDIT_PROGRAM)])
  yield all([takeEvery(actions.LOAD_PROGRAMS_INDEX, LOAD_PROGRAMS_INDEX)])
  yield all([takeEvery(actions.LOAD_SELECTED_PROGRAM, WATCH_LOAD_SELECTED_PROGRAM)])
  yield all([takeEvery(actions.LOAD_PROGRAM_REGISTRATIONS, LOAD_PROGRAM_REGISTRATIONS)])
  yield all([takeEvery(actions.WATCH_EDIT_PROGRAM_STATUS, WATCH_EDIT_PROGRAM_STATUS)])
}
