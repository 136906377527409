const actions = {
  SET_STATE: 'courseLearning/SET_STATE',
  LOAD_COURSELEARNING_INDEX: 'courseLearning/LOAD_COURSELEARNINGS_INDEX',
  LOAD_CURRENT_COURSELEARNING: 'courseLearning/LOAD_CURRENT_COURSELEARNING',
  CREATE_COURSELEARNING: 'courseLearning/CREATE_COURSELEARNING',
  CLEAR_CURRENT: 'courseLearning/CLEAR_CURRENT',
  SET_FILTERS: 'courseLearning/SET_FILTERS',
  CLEAR_FILTERS: 'courseLearning/CLEAR_FILTERS',
  CREATE_COURSELEARNING_UPDATE: 'courseLearning/CREATE_COURSELEARNING_UPDATE',
  UPDATE_COURSELEARNING: 'courseLearning/UPDATE_COURSELEARNING',
  DELETE_COURSELEARNING: 'courseLearning/DELETE_COURSELEARNING',
}

export default actions
