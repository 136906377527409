import { all, takeEvery, put, call } from 'redux-saga/effects'
import getFeedbacks, { getCurrentFeedback, updateFeedback } from 'services/feedbacks'
import { notification } from 'antd'
import { push } from 'react-router-redux'
import actions from './actions'
import errorActions from '../error/actions'

export function* WATCH_LOAD_FEEDBACKS_INDEX(action) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })

  const response = yield call(getFeedbacks, action.payload)

  if (response) {
    if (response.data.success) {
      yield put({
        type: actions.SET_STATE,
        payload: {
          index: response.data.data,
          pagination: response.data.meta.pagination,
        },
      })
    } else {
      yield put({
        type: errorActions.SET_STATE,
        payload: response.data,
      })
    }
  }
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: false,
    },
  })
}

export function* WATCH_LOAD_CURRENT_FEEDBACK(action) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })

  const response = yield call(getCurrentFeedback, action.payload.id, action.payload)

  if (response) {
    if (response.data.success) {
      yield put({
        type: actions.LOAD_CURRENT_FEEDBACK_ASYNC,
        payload: response.data.data,
      })
    } else {
      yield put({
        type: errorActions.SET_STATE,
        payload: response.data,
      })
    }
  }
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: false,
    },
  })
}

export function* WATCH_UPDATE_FEEDBACK(action) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })

  const response = yield call(updateFeedback, action.payload.id, action.payload.data)

  if (response) {
    if (response.data.success) {
      yield put({
        type: actions.LOAD_FEEDBACKS_INDEX,
        payload: {
          ...action.payload.filters,
        },
      })
      notification.success({
        message: 'Success',
        description: 'Feedback Status Updated',
      })
      yield put(push('/feedback'))
    } else {
      yield put({
        type: errorActions.SET_STATE,
        payload: response.data,
      })
      notification.warning({
        message: 'Failure',
        description: 'Feedback Cannot Be Saved',
      })
    }
  }
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: false,
    },
  })
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.LOAD_FEEDBACKS_INDEX, WATCH_LOAD_FEEDBACKS_INDEX),
    takeEvery(actions.LOAD_CURRENT_FEEDBACK, WATCH_LOAD_CURRENT_FEEDBACK),
    takeEvery(actions.UPDATE_FEEDBACK, WATCH_UPDATE_FEEDBACK),
  ])
}
