import { all, takeEvery, put, call } from 'redux-saga/effects'
import { notification } from 'antd'
import {
  createBatchLabels,
  getBatchLabels,
  updateBatchLabels,
  removeBatchLabels,
} from 'services/batchLabels'
import actions from './actions'
import errorActions from '../error/actions'

export function* WATCH_LOAD_BATCH_LABELS_INDEX(action) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })

  const response = yield call(getBatchLabels, action.payload)

  if (response) {
    if (response.data.success) {
      yield put({
        type: actions.SET_STATE,
        payload: {
          index: response?.data?.data,
          pagination: response?.data?.meta?.pagination,
        },
      })
    } else {
      yield put({
        type: errorActions.SET_STATE,
        payload: response.data,
      })
    }
  }

  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: false,
    },
  })
}

export function* WATCH_CREATE_BATCH_LABELS(action) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })

  const response = yield call(createBatchLabels, action.payload)

  if (response) {
    if (response.data.success) {
      yield put({
        type: actions.SET_STATE,
        payload: {
          current: {},
          modalType: '',
        },
      })

      notification.success({
        message: 'Success!',
        description: 'Batch Label added successfully',
      })

      yield put({
        type: actions.LOAD_BATCH_LABELS_INDEX,
      })
    } else {
      yield put({
        type: errorActions.SET_STATE,
        payload: response.data,
      })
    }
  }

  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: false,
    },
  })
}

export function* WATCH_UPDATE_BATCH_LABELS(action) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })

  const response = yield call(updateBatchLabels, action.payload.id, action.payload.data)

  if (response) {
    if (response.data.success) {
      yield put({
        type: actions.SET_STATE,
        payload: {
          current: {},
          modalType: '',
        },
      })

      notification.success({
        message: 'Success!',
        description: 'Batch Label updated successfully',
      })

      yield put({
        type: actions.LOAD_BATCH_LABELS_INDEX,
      })
    } else {
      yield put({
        type: errorActions.SET_STATE,
        payload: response.data,
      })
    }
  }

  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: false,
    },
  })
}

export function* WATCH_DELETE_BATCH_LABELS(action) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })

  const response = yield call(removeBatchLabels, action.payload.id, {})

  if (response) {
    if (response.data.success) {
      yield put({
        type: actions.SET_STATE,
        payload: {
          modalType: '',
          current: {},
        },
      })

      notification.success({
        message: 'Success!',
        description: 'Batch Label deleted successfully',
      })

      yield put({
        type: actions.LOAD_BATCH_LABELS_INDEX,
      })
    } else {
      yield put({
        type: errorActions.SET_STATE,
        payload: response.data,
      })
    }
  }
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: false,
    },
  })
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.LOAD_BATCH_LABELS_INDEX, WATCH_LOAD_BATCH_LABELS_INDEX),
    takeEvery(actions.CREATE_BATCH_LABELS, WATCH_CREATE_BATCH_LABELS),
    takeEvery(actions.UPDATE_BATCH_LABELS, WATCH_UPDATE_BATCH_LABELS),
    takeEvery(actions.DELETE_BATCH_LABELS, WATCH_DELETE_BATCH_LABELS),
  ])
}
