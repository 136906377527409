const pathConfig = {
  guardians: {
    edit: '/parents/:parentId',
    ForCounsellorEdit: '/counsellor/parents/:parentId',
    cart: '/parents/:parentId/cart',
    account_linking: '/parents/:parentId/account-linking',
  },
  teachers: {
    edit: '/teachers/:teacherId',
    payments: '/teachers/payments',
  },
  admins: {
    edit: '/admins/:adminId',
  },
  batch: {
    edit: '/batches/:batchId',
  },
  course: {
    edit: '/courses/:courseId',
  },
  enrollment: {
    details: '/enrollments/:enrollmentId',
    attendance: '/enrollments/:id/attendance',
    print_receipt: '/enrollments/:id/printReceipt',
    trail_slot: '/create-trail-slot/:courseId/:studentId',
    trial_slots_index: '/trails-slots',
  },
  centers: {
    edit: '/center/:centerId',
  },
  order: {
    edit: '/order_view_details/:orderId',
  },
  classes: {
    collect_fees: '/:section/:classId/collectFees',
    class_update: '/classes/:classId/classUpdates',
    get_one: '/classes/:classId',
    mark_attendance: '/classes/:classId/markAttendance',
    class_ledger: '/classes/:classId/class-ledger',
  },
  ledgers: {
    edit: '/ledger/:id/edit',
    create_settlement: '/ledger/:id/reconcileAccounts',
  },
  coupons: {
    edit: '/coupons/:id/edit',
  },
  program: {
    edit: '/apps/ParentTrainingPrograms/:id/edit',
    teacher_edit: '/apps/TeacherTrainingPrograms/:id/edit',
    interested_users: '/apps/ParentTrainingPrograms/:id/intrestedParents',
    interested_teachers: '/apps/TeacherTrainingPrograms/:id/intrestedTeachers',
  },
  feedback: {
    details: '/feedback/:id',
  },
  testimonial: {
    edit: '/testimonials/:testimonialId',
  },
  courseLearning: {
    edit: '/course-learnings/:courseLearningId',
  },
  reports: {
    collectionReport: '/report/collection',
    enrollmentReport: '/report/enrollment',
    organicReport: '/organic-report',
  },
  trialSlot: {
    conversion: '/trial-conversion',
  },
  feeDueList: {
    index: '/fee-due-list',
  },
}

export default pathConfig
