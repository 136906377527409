import { notification } from 'antd'
import axios from 'utilities/configureAxios'
import route from 'utilities/route'

export const getBatchLabels = async (payload = {}) => {
  return axios
    .get(route('batchLabels.index'), payload)
    .then(response => response)
    .catch(error => {
      notification.warning({
        message: error.code,
        description: error.message,
      })

      return error.response
    })
}

export const createBatchLabels = async (payload = {}) => {
  return axios
    .post(route('batchLabels.add'), payload)
    .then(res => res)
    .catch(err => {
      notification.warning({
        message: err.code,
        description: err.message,
      })

      return err.response
    })
}

export const updateBatchLabels = async (id, payload = {}) => {
  return axios
    .post(route('batchLabels.update', { id }), payload)
    .then(res => res)
    .catch(err => {
      notification.warning({
        message: err.code,
        description: err.message,
      })

      return err.response
    })
}

export async function removeBatchLabels(id, payload = {}) {
  return axios
    .delete(route('batchLabels.remove', { id }), payload)
    .then(res => res)
    .catch(err => {
      notification.warning({
        message: err.code,
        description: err.message,
      })

      return err.response
    })
}
