import { notification } from 'antd'
import axiosInstance from 'utilities/configureAxios'
import route from 'utilities/route'

export default async function getParents(payload = {}) {
  return axiosInstance
    .get(route('guardians.index'), payload)
    .then(res => res)
    .catch(error => {
      notification.warning({
        message: error.code,
        description: error.message,
      })
      return error.response
    })
}

export async function getGuardianDetails(id, payload) {
  const url = route('guardians.getById', { id })
  return axiosInstance
    .get(url, { params: payload })
    .then(res => res)
    .catch(error => {
      notification.warning({
        message: error.code,
        description: error.message,
      })
      return error.response
    })
}

export async function createGuardian(payload) {
  return axiosInstance
    .post(route('guardians.create'), payload)
    .then(res => res)
    .catch(error => {
      notification.warning({
        message: error.code,
        description: error.message,
      })
      return error.response
    })
}

export async function updateGuardian(id, payload, extraParams = {}) {
  const routeValue = route('guardians.edit', { id })
  return axiosInstance
    .post(routeValue, payload, { params: extraParams })
    .then(res => res)
    .catch(error => {
      notification.warning({
        message: error.code,
        description: error.message,
      })
      return error.response
    })
}

export async function getCartItems(id, payload, extraParams = {}) {
  const routeValue = route('guardians.cart_items', { id })
  return axiosInstance
    .get(routeValue, payload, { params: extraParams })
    .then(res => res)
    .catch(error => {
      notification.warning({
        message: error.code,
        description: error.message,
      })
      return error.response
    })
}

export async function deleteCartItem(payload, extraParams = {}) {
  const id = payload.parentId
  const cartId = payload.id
  const routeValue = route('guardians.delete_item', { id, cartId })
  return axiosInstance
    .delete(routeValue, payload, { params: extraParams })
    .then(res => res)
    .catch(error => {
      notification.warning({
        message: error.code,
        description: error.message,
      })
      return error.response
    })
}

export async function exportGuardians(payload = {}, extraParams = {}) {
  const routeValue = route('guardians.export')
  return axiosInstance
    .post(routeValue, payload, extraParams)
    .then(res => res)
    .catch(error => {
      notification.warning({
        message: error.code,
        description: error.message,
      })
      return error.response
    })
}

export async function getGuardianFamilyDetails(id, payload, extraParams = {}) {
  const routeValue = route('guardians.family_members', { id })
  return axiosInstance
    .get(routeValue, payload, { params: extraParams })
    .then(res => res)
    .catch(error => {
      notification.warning({
        message: error.code,
        description: error.message,
      })
      return error.response
    })
}

export async function linkGuardian(payload, id) {
  const routeValue = route('guardians.link_guardian', { id })

  return axiosInstance
    .post(routeValue, payload)
    .then(res => res)
    .catch(error => {
      notification.warning({
        message: error.code,
        description: error.message,
      })
      return error.response
    })
}
