import { all, takeEvery, put, call } from 'redux-saga/effects'
import getLedgers, { getLedger, editLedger, createSettlement, addLedger } from 'services/ledgers'
import { push } from 'react-router-redux'
import { notification } from 'antd'
import actions from './actions'
import errorActions from '../error/actions'

export function* WATCH_LOAD_LEDGERS_INDEX(action) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })

  const response = yield call(getLedgers, action.payload)

  if (response) {
    if (response.data.success) {
      yield put({
        type: actions.SET_STATE,
        payload: {
          index: response.data.data,
          pagination: response.data.meta.pagination,
        },
      })
    } else {
      yield put({
        type: errorActions.SET_STATE,
        payload: response.data,
      })
    }
  }
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: false,
    },
  })
}

export function* WATCH_LOAD_CURRENT_LEDGER(action) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })

  const response = yield call(getLedger, action.payload)
  if (response) {
    if (response.data.success) {
      yield put({
        type: actions.SET_STATE,
        payload: {
          current: response.data.data,
        },
      })
    } else {
      yield put({
        type: errorActions.SET_STATE,
        payload: response.data,
      })
    }
  }
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: false,
    },
  })
}

export function* EDIT_LEDGER(action) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      submitLoading: true,
    },
  })

  const response = yield call(editLedger, action.payload.id, action.payload.data)
  if (response) {
    if (response.data.success) {
      yield put(push('/ledger'))
      notification.success({
        message: 'Success!',
        description: 'Ledger updated successfully',
      })
      yield put({
        type: actions.SET_STATE,
        payload: {
          current: response.data.data,
        },
      })
    } else {
      yield put({
        type: errorActions.SET_STATE,
        payload: response.data,
      })
    }
  }
  yield put({
    type: actions.SET_STATE,
    payload: {
      submitLoading: false,
    },
  })
}

export function* ADD_LEDGER(action) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      submitLoading: true,
    },
  })

  const response = yield call(addLedger, action.payload.data)
  if (response) {
    if (response.data.success) {
      yield put(push('/ledger'))
      notification.success({
        message: 'Success!',
        description: 'Ledger added successfully',
      })
      yield put({
        type: actions.SET_STATE,
        payload: {
          current: response.data.data,
        },
      })
    } else {
      yield put({
        type: errorActions.SET_STATE,
        payload: response.data,
      })
    }
  }
  yield put({
    type: actions.SET_STATE,
    payload: {
      submitLoading: false,
    },
  })
}

export function* CREATE_SETTLEMENT(action) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      submitLoading: true,
    },
  })

  const response = yield call(createSettlement, action.payload.id, action.payload.data)
  if (response) {
    if (response.data.success) {
      yield put(push('/ledger'))
      notification.success({
        message: 'Success!',
        description: 'Settlement created successfully',
      })
      yield put({
        type: actions.SET_STATE,
        payload: {
          current: response.data.data,
        },
      })
    } else {
      yield put({
        type: errorActions.SET_STATE,
        payload: response.data,
      })
    }
  }
  yield put({
    type: actions.SET_STATE,
    payload: {
      submitLoading: false,
    },
  })
}

export default function* rootSaga() {
  yield all([takeEvery(actions.LOAD_LEDGERS_INDEX, WATCH_LOAD_LEDGERS_INDEX)])
  yield all([takeEvery(actions.LOAD_CURRENT_LEDGER, WATCH_LOAD_CURRENT_LEDGER)])
  yield all([takeEvery(actions.EDIT_LEDGER, EDIT_LEDGER)])
  yield all([takeEvery(actions.ADD_LEDGER, ADD_LEDGER)])
  yield all([takeEvery(actions.CREATE_SETTLEMENT, CREATE_SETTLEMENT)])
}
