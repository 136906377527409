import { all, takeEvery, put, call } from 'redux-saga/effects'
import {
  getCourses,
  getStandards,
  getSingleCourseDetails,
  counselorEnrollmentsApi,
  addChildApi,
  addLeaddApi,
  trialClassDatesApi,
  installmentBreakageAPi,
  specifyCouponApi,
  paymentLinkApi,
  shareResourcesApi,
  getCounsleorsApi,
  classesForEachBatchApi,
} from 'services/counselor'
import { notification } from 'antd'
import { get } from 'lodash'
import counselorPayemntActions from 'redux/counselorPayment/actions'
import actions from './actions'
import guardianActions from '../guardians/actions'
import errorActions from '../error/actions'

export function* getCoursesSaga(action) {
  yield put({
    type: actions.SET_COURSE_LOADER,
    payload: true,
  })

  const response = yield call(getCourses, action.payload.params)
  if (response) {
    if (response.data.success) {
      yield put({
        type: actions.GET_COURSES_SUCCESS,
        payload: response.data.data,
      })
      yield put({
        type: actions.SET_COURSE_LOADER,
        payload: false,
      })
    } else {
      yield put({
        type: errorActions.SET_STATE,
        payload: response.data,
      })
      yield put({
        type: actions.SET_COURSE_LOADER,
        payload: false,
      })
    }
  }
  yield put({
    type: actions.SET_COURSE_LOADER,
    payload: false,
  })
}

export function* getStandardsSaga(action) {
  yield put({
    type: actions.SET_LOADING,
    payload: true,
  })

  const response = yield call(getStandards, action.payload.params)
  if (response) {
    if (response.data.success) {
      yield put({
        type: actions.GET_STANDARDS_SUCCESS,
        payload: response.data.data,
      })
    } else {
      yield put({
        type: errorActions.SET_STATE,
        payload: response.data,
      })
    }
  }

  yield put({
    type: actions.SET_LOADING,
    payload: false,
  })
}

export function* getSingleCourseBatch(action) {
  yield put({
    type: actions.SINGLE_COURSE_BATCH_LOADING,
    payload: true,
  })

  const response = yield call(getSingleCourseDetails, action.payload.id, action.payload.params)

  if (response) {
    if (response?.data?.success) {
      yield put({
        type: actions.SINGLE_COURSE_BATCH_SUCCESS,
        payload: response?.data?.data,
      })
    } else {
      yield put({
        type: errorActions.SET_STATE,
        payload: response.data,
      })
    }
  }

  yield put({
    type: actions.SINGLE_COURSE_BATCH_LOADING,
    payload: false,
  })
}

export function* getSingleCourse(action) {
  yield put({
    type: actions.SET_LOADING,
    payload: true,
  })

  const response = yield call(getSingleCourseDetails, action.payload.id, action.payload.params)

  if (response) {
    if (response.data.success) {
      yield put({
        type: actions.COURSE_DETAILS_SUCCESS,
        payload: response.data.data,
      })
    } else {
      yield put({
        type: errorActions.SET_STATE,
        payload: response.data,
      })
    }
  }
  yield put({
    type: actions.SET_LOADING,
    payload: false,
  })
}

export function* counselorEnrollments(action) {
  yield put({
    type: actions.SET_LOADING,
    payload: true,
  })

  const response = yield call(counselorEnrollmentsApi, action.payload.params)
  if (response) {
    if (response.data.success) {
      yield put({
        type: actions.COUNSELOR_ENROLLMENTS_SUCCESS,
        payload: {
          index: response.data.data,
          pagination: get(response, 'data.meta.pagination', null),
        },
      })
    } else {
      yield put({
        type: errorActions.SET_STATE,
        payload: response.data,
      })
    }
  }
  yield put({
    type: actions.SET_LOADING,
    payload: false,
  })
}

export function* childAdd(action) {
  yield put({
    type: actions.SET_LOADING,
    payload: true,
  })
  const response = yield call(addChildApi, action.payload.id, action.payload.data)
  if (response) {
    if (response.data.success) {
      notification.success({
        message: 'Child Added Successfully!',
      })

      yield put({
        type: guardianActions.LOAD_GUARDIANS_INDEX,
        payload: {
          params: {
            q: response.data.data.user.mobile,
            includes: 'children',
          },
        },
      })
    } else {
      yield put({
        type: errorActions.SET_STATE,
        payload: response.data,
      })
      notification.warning({
        message: 'Something Went Wrong.',
      })
    }
  }
  yield put({
    type: actions.SET_LOADING,
    payload: false,
  })
}

export function* addLeadSaga(action) {
  yield put({
    type: actions.SET_LOADING,
    payload: true,
  })
  const response = yield call(addLeaddApi, action.payload)
  if (response) {
    if (response.data.success) {
      notification.success({
        message: 'Lead Added Successfully!',
      })
      yield put({
        type: guardianActions.LOAD_GUARDIANS_INDEX,
        payload: {
          params: {
            q: response.data.data.user.mobile,
            includes: 'children',
          },
        },
      })
    } else {
      yield put({
        type: errorActions.SET_STATE,
        payload: response.data,
      })
      notification.warning({
        message: response.data.errors.mobile[0],
      })
    }
  }
  yield put({
    type: actions.SET_LOADING,
    payload: false,
  })
}

export function* trialClasSaga(action) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      modalLoader: true,
    },
  })

  const response = yield call(trialClassDatesApi, action.payload.id, action.payload.params)
  if (response) {
    if (response.data.success) {
      yield put({
        type: actions.TRIAL_CLASS_DATES_SUCCESS,
        payload: {
          trialClassDates: response.data.data,
          modalVisible: 'trial',
        },
      })
    } else {
      yield put({
        type: errorActions.SET_STATE,
        payload: response.data,
      })
    }
  }
  yield put({
    type: actions.SET_STATE,
    payload: {
      modalLoader: false,
    },
  })
}

export function* installmentBreakageSaga(action) {
  yield put({
    type: actions.SET_COURSE_LOADER,
    payload: true,
  })

  const response = yield call(installmentBreakageAPi, action.payload.id, action.payload.params)
  if (response) {
    if (response.data.success) {
      const installments = response.data.data

      yield put({
        type: actions.INSTALLMENT_BREAKAGE_SUCCESS,
        payload: {
          installments,
          modalVisible: '',
        },
      })

      yield put({
        type: counselorPayemntActions.SELECTED_INSTALLMENT,
        payload: installments?.data?.[0],
      })

      yield put({
        type: counselorPayemntActions.SET_INSTALLMENT_DETAILS,
        payload: installments?.data?.[0]?.installment__type_id,
      })
    } else {
      yield put({
        type: errorActions.SET_STATE,
        payload: response.data,
      })
      yield put({
        type: actions.INSTALLMENT_BREAKAGE_SUCCESS,
        payload: {
          installments: [],
          modalVisible: '',
        },
      })
    }
  }
  yield put({
    type: actions.SET_COURSE_LOADER,
    payload: false,
  })
}

export function* specifyCouponSaga(action) {
  yield put({
    type: actions.SET_LOADING,
    payload: true,
  })

  const response = yield call(specifyCouponApi, action.payload.id, action.payload.data)
  if (response) {
    if (response.data.success) {
      yield put({
        type: actions.SPECIFY_COUPON_MODAL,
        payload: {
          specifyCouponModal: false,
        },
      })
      notification.success({
        message: 'Coupon specified successfully.',
      })
    } else {
      yield put({
        type: errorActions.SET_STATE,
        payload: response.data,
      })
    }
  }
  yield put({
    type: actions.SET_LOADING,
    payload: false,
  })
}

export function* paymentLinkSaga(action) {
  yield put({
    type: actions.SET_COURSE_LOADER,
    payload: true,
  })
  const response = yield call(paymentLinkApi, action.payload.orderId, action.payload.is_wallet)
  if (response) {
    if (response.data.success) {
      yield put({
        type: actions.PAYMENT_LINK_SUCCESS,
        payload: {
          paymentLinkDetails: response.data.data,
        },
      })
      if (response.data.data.payment_link !== null) {
        notification.success({
          message: 'Successfully link generated.',
        })
      } else {
        notification.success({
          message: 'You successfully enrolled on a batch.',
        })
      }
    } else {
      yield put({
        type: errorActions.SET_STATE,
        payload: response.data,
      })
    }
  }
  yield put({
    type: actions.SET_COURSE_LOADER,
    payload: false,
  })
}

export function* shareResourceSaga(action) {
  yield put({
    type: actions.SET_COURSE_LOADER,
    payload: true,
  })
  const response = yield call(shareResourcesApi, action.payload.id, action.payload.params)
  if (response) {
    if (response.data.success) {
      notification.success({
        message: 'Shared with parent via sms.',
      })
    } else {
      yield put({
        type: errorActions.SET_STATE,
        payload: response.data,
      })
    }
  }
  yield put({
    type: actions.SET_COURSE_LOADER,
    payload: false,
  })
}

export function* getCounselorSaga() {
  const response = yield call(getCounsleorsApi)
  if (response) {
    if (response.data.success) {
      yield put({
        type: actions.GET_COUNSELOR_SUCCESS,
        payload: response.data.data,
      })
    } else {
      yield put({
        type: errorActions.SET_STATE,
        payload: response.data,
      })
    }
  }
  yield put({
    type: actions.SET_COURSE_LOADER,
    payload: false,
  })
}
export function* getClassesForEachBatch(action) {
  yield put({ type: actions.SET_MODAL_LOADER, payload: true })

  const response = yield call(classesForEachBatchApi, action.payload.batch_id, action.payload.data)

  if (response) {
    if (response.data.success) {
      yield put({ type: actions.CLASSES_FOR_EACH_BATCH_SUCCESS, payload: response?.data?.data })
    }
  } else {
    yield put({ type: actions.CLASSES_FOR_EACH_BATCH_SUCCESS, payload: [] })
    yield put({
      type: errorActions.SET_STATE,
      payload: response?.data,
    })
  }

  yield put({ type: actions.SET_MODAL_LOADER, payload: false })
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.SINGLE_COURSE_BATCH, getSingleCourseBatch),
    takeEvery(actions.GET_COURSES, getCoursesSaga),
    takeEvery(actions.GET_STANDARDS, getStandardsSaga),
    takeEvery(actions.COURSE_DETAILS, getSingleCourse),
    takeEvery(actions.COUNSELOR_ENROLLMENTS, counselorEnrollments),
    takeEvery(actions.ADD_CHILD, childAdd),
    takeEvery(actions.ADD_LEAD, addLeadSaga),
    takeEvery(actions.TRIAL_CLASS_DATES, trialClasSaga),
    takeEvery(actions.INSTALLMENT_BREAKAGE, installmentBreakageSaga),
    takeEvery(actions.UPDATE_SPECIFY_COUPON, specifyCouponSaga),
    takeEvery(actions.PAYMENT_LINK, paymentLinkSaga),
    takeEvery(actions.SHARE_RESOURCES, shareResourceSaga),
    takeEvery(actions.GET_COUNSELOR, getCounselorSaga),
    takeEvery(actions.CLASSES_FOR_EACH_BATCH, getClassesForEachBatch),
  ])
}
