const actions = {
  LOAD_ROLES_INDEX: 'roles/LOAD_ROLES_INDEX',
  LOAD_CURRENT_ROLE: 'roles/LOAD_CURRENT_ROLE',
  SET_FILTERS: 'roles/SET_FILTERS',
  SET_STATE: 'roles/SET_STATE',
  CREATE_ROLES: 'roles/CREATE_ROLES',
  CLEAR_FILTERS: 'roles/CLEAR_FILTERS',
  SHOW_MODAL: 'roles/SHOW_MODAL',
  SYNC_ROLE: 'roles/SYNC_ROLE',
}
export default actions
