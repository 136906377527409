import { all, takeEvery, put, call } from 'redux-saga/effects'
// import { push } from 'react-router-redux'
import { get } from 'lodash'
import { getRoles, createRole, getCurrentRole, syncRole } from 'services/admins'
import actions from './actions'
import errorActions from '../error/actions'

export function* WATCH_LOAD_ROLES_INDEX(action) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })

  const response = yield call(getRoles, action.payload)

  if (response) {
    if (response.data.success) {
      yield put({
        type: actions.SET_STATE,
        payload: {
          index: response.data.data,
        },
      })
    } else {
      yield put({
        type: errorActions.SET_STATE,
        payload: response.data,
      })
    }
  }
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: false,
    },
  })
}

export function* WATCH_LOAD_CURRENT_ROLE(action) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })

  const response = yield call(getCurrentRole, action.payload.name)
  if (response) {
    if (response.data.success) {
      yield put({
        type: actions.SHOW_MODAL,
        payload: {
          visible: true,
          edit: true,
          role: get(response.data.data, '0', {}),
        },
      })
    } else {
      yield put({
        type: errorActions.SET_STATE,
        payload: response.data,
      })
    }
  }
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: false,
    },
  })
}

export function* WATCH_CREATE_ROLE(action) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })

  const response = yield call(createRole, action.payload)
  if (response) {
    if (response.data.success) {
      yield put({
        type: actions.LOAD_ROLES_INDEX,
      })
      yield put({
        type: actions.SHOW_MODAL,
        payload: {
          visible: false,
        },
      })
    } else {
      yield put({
        type: errorActions.SET_STATE,
        payload: response.data,
      })
    }
    yield put({
      type: actions.SET_STATE,
      payload: {
        loading: false,
      },
    })
  }
}

export function* WATCH_SYNC_ROLE(action) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })

  const response = yield call(syncRole, action.payload.name, action.payload)
  if (response) {
    if (response.data.success) {
      yield put({
        type: actions.LOAD_ROLES_INDEX,
      })
      yield put({
        type: actions.SHOW_MODAL,
        payload: {
          visible: false,
        },
      })
    } else {
      yield put({
        type: errorActions.SET_STATE,
        payload: response.data,
      })
    }
    yield put({
      type: actions.SET_STATE,
      payload: {
        loading: false,
      },
    })
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.LOAD_ROLES_INDEX, WATCH_LOAD_ROLES_INDEX),
    takeEvery(actions.CREATE_ROLES, WATCH_CREATE_ROLE),
    takeEvery(actions.LOAD_CURRENT_ROLE, WATCH_LOAD_CURRENT_ROLE),
    takeEvery(actions.SYNC_ROLE, WATCH_SYNC_ROLE),
  ])
}
