import React from 'react'
import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'
import loginActions from 'redux/auth/actions'
import { Menu, Dropdown, Avatar } from 'antd'
import styles from './style.module.scss'

@connect(({ user }) => ({ user }))
class ProfileMenu extends React.Component {
  state = {
    count: 7,
  }

  logout = e => {
    e.preventDefault()
    const { dispatch } = this.props
    dispatch({
      type: loginActions.LOGGOUT,
    })
  }

  addCount = () => {
    let { count } = this.state
    count += 1
    this.setState({
      count,
    })
  }

  render() {
    const { user } = this.props
    const menu = (
      <Menu selectable={false}>
        <Menu.Item>
          <strong>
            <FormattedMessage id="topBar.profileMenu.hello" />, {user.name || 'Vishal'}
          </strong>
        </Menu.Item>
        <Menu.Divider />
        <Menu.Item>
          <div>
            <strong style={{ paddingRight: '10px' }}>
              <FormattedMessage id="topBar.profileMenu.email" />:{' '}
            </strong>
            {user.email || ''}
            <br />
            <strong style={{ paddingRight: '6px' }}>
              <FormattedMessage id="topBar.profileMenu.phone" />:{' '}
            </strong>
            {user.mobile || '— '}
          </div>
        </Menu.Item>
        {/* <Menu.Divider /> */}
        {/* <Menu.Item>
          <a href="#" onClick={e => e.preventDefault()}>
            <i className={`${styles.menuIcon} fe fe-user`} />
            <FormattedMessage id="topBar.profileMenu.editProfile" />
          </a>
        </Menu.Item> */}
        <Menu.Divider />
        <Menu.Item>
          <a href="/" onClick={this.logout}>
            <i className={`${styles.menuIcon} fe fe-log-out`} />
            <FormattedMessage id="topBar.profileMenu.logout" />
          </a>
        </Menu.Item>
      </Menu>
    )
    return (
      <Dropdown overlay={menu} trigger={['click']} onVisibleChange={this.addCount}>
        <div className={styles.dropdown}>
          {/* <Badge count={count}>
            
          </Badge> */}
          <Avatar
            className={styles.avatar}
            src={user.profile_picture_thumbnail}
            shape="square"
            size="large"
            icon="user"
          />
        </div>
      </Dropdown>
    )
  }
}

export default ProfileMenu
