import { all, takeEvery, put, call } from 'redux-saga/effects'
import fetchRelatedTags from 'services/relatedTags'
import actions from './actions'
import errorActions from '../error/actions'

export function* WATCH_LOAD_TAGS(action) {
  const response = yield call(fetchRelatedTags, action.payload)
  if (response) {
    if (response.data.success) {
      const values = response.data.data.map(item => item.label)
      yield put({
        type: actions.LOAD_RELATED_TAGS_ASYNC,
        payload: values,
      })
    } else {
      yield put({
        type: errorActions.SET_STATE,
        payload: response.data,
      })
    }
  }
}

export default function* rootSaga() {
  yield all([takeEvery(actions.LOAD_RELATED_TAGS, WATCH_LOAD_TAGS)])
}
