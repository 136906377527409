import { all, takeEvery, put, call } from 'redux-saga/effects'
import getEnrollments, {
  getEnrollmentByOrder,
  getCurrentEnrollment,
  createEnrollment,
  cancelEnrollment,
  updateEnrollment,
  updateCommission,
  getEnrollmentAttendance,
  updateEnrollmentAttendance,
  transferEnrollment,
  editClassFeesEnrollment,
  printReceiptEnrollment,
  getCancellationReasons,
  getCertificateEnrollment,
  updateTrialEnrollmentType,
  enrollmentCount,
  markClassLeave,
} from 'services/enrollments'
import { notification } from 'antd'
import counselorActions from 'redux/counselor/actions'
import { push } from 'react-router-redux'
import actions from './actions'
import errorActions from '../error/actions'

export function* WATCH_LOAD_ENROLLMENTS_INDEX(action) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })

  const response = yield call(getEnrollments, action.payload)

  if (response) {
    if (response.data.success) {
      yield put({
        type: actions.SET_STATE,
        payload: {
          index: response.data.data,
          pagination: response.data.meta.pagination,
        },
      })
    } else {
      yield put({
        type: errorActions.SET_STATE,
        payload: response.data,
      })
    }
  }
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: false,
    },
  })
}

export function* WATCH_LOAD_ENROLLMENTS_COUNT(action) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loadingCount: true,
    },
  })

  const response = yield call(enrollmentCount, action.payload)

  if (response) {
    if (response.data.success) {
      yield put({
        type: actions.SET_STATE,
        payload: {
          count: response.data.data,
          // pagination: response.data.meta.pagination,
        },
      })
    } else {
      yield put({
        type: errorActions.SET_STATE,
        payload: response.data,
      })
    }
  }
  yield put({
    type: actions.SET_STATE,
    payload: {
      loadingCount: true,
    },
  })
}

export function* WATCH_LOAD_ENROLLMENT_BY_ORDER(action) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })

  const response = yield call(getEnrollmentByOrder, action.payload.id, action.payload)

  if (response) {
    if (response.data.success) {
      yield put({
        type: actions.SET_STATE,
        payload: {
          index: response.data.data,
        },
      })
    } else {
      yield put({
        type: errorActions.SET_STATE,
        payload: response.data,
      })
    }
  }
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: false,
    },
  })
}

export function* WATCH_LOAD_CURRENT_ENROLLMENT(action) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })

  const response = yield call(getCurrentEnrollment, action.payload.id, action.payload)

  if (response) {
    if (response.data.success) {
      yield put({
        type: actions.LOAD_CURRENT_ENROLLMENT_ASYNC,
        payload: response.data.data,
      })
    } else {
      yield put({
        type: errorActions.SET_STATE,
        payload: response.data,
      })
    }
  }
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: false,
    },
  })
}

export function* WATCH_CREATE_ENROLLMENT(action) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })

  const response = yield call(createEnrollment, action.payload)

  if (response) {
    if (response.data.success) {
      notification.success({
        message: 'Success',
        description: 'Enrollment Created',
      })
      yield put(push('/enrollments'))
    } else {
      yield put({
        type: errorActions.SET_STATE,
        payload: response.data,
      })
    }
  }
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: false,
    },
  })
}

export function* WATCH_CANCEL_ENROLLMENT(action) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })
  const response = yield call(
    cancelEnrollment,
    action.payload.id,
    action.payload.is_refundable,
    action.payload.data,
  )

  if (response) {
    if (response.data.success) {
      yield put({
        type: actions.SET_STATE,
        payload: {
          showmodal: false,
        },
      })
      notification.success({
        message: 'Success',
        description: 'Enrollment Cancelled',
      })
      yield put(push('/enrollments'))
    } else {
      yield put({
        type: errorActions.SET_STATE,
        payload: response.data,
      })
      notification.warning({
        message: 'Failure',
        description: 'Enrollment Cannot Be Cancelled',
      })
    }
  }
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: false,
    },
  })
}

export function* WATCH_UPDATE_COMMISSION(action) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })

  const response = yield call(updateCommission, action.payload.id, action.payload.data)

  if (response) {
    if (response.data.success) {
      yield put({
        type: actions.SET_STATE,
        payload: {
          showmodal: false,
        },
      })
      notification.success({
        message: 'Success!',
        description: 'Commissions Saved',
      })
      yield put(push('/enrollments'))
    } else {
      yield put({
        type: errorActions.SET_STATE,
        payload: response.data,
      })
      notification.warning({
        message: 'Failure',
        description: 'Enrollment Cannot Be Saved',
      })
    }
  }
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: false,
    },
  })
}

export function* WATCH_UPDATE_TRIAL_ENROLLEMENT_TYPE(action) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })

  const response = yield call(updateTrialEnrollmentType, action.payload.id, action.payload)

  if (response) {
    if (response.data.success) {
      notification.success({
        message: 'Success',
        description: 'Enrollment status updated',
      })
      yield put(push('/enrollments'))
    } else {
      yield put({
        type: errorActions.SET_STATE,
        payload: response.data,
      })
      notification.warning({
        message: 'Failure',
        description: 'Enrollment Cannot Be Saved',
      })
    }
  }

  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: false,
    },
  })
}

export function* WATCH_UPDATE_ENROLLMENT(action) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })

  const response = yield call(updateEnrollment, action.payload.id, action.payload.data)

  if (response) {
    if (response.data.success) {
      yield put({
        type: actions.SET_STATE,
        payload: {
          showmodal: false,
        },
      })
      notification.success({
        message: 'Success',
        description: 'Enrollment Saved',
      })
      if (action.payload.route) {
        yield put(push(action.payload.route))
      } else {
        yield put(push('/enrollments'))
      }
    } else {
      yield put({
        type: errorActions.SET_STATE,
        payload: response.data,
      })
      notification.warning({
        message: 'Failure',
        description: 'Enrollment Cannot Be Saved',
      })
    }
  }
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: false,
    },
  })
}

export function* WATCH_LOAD_ENROLLMENT_ATTENDANCE(action) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })

  const response = yield call(getEnrollmentAttendance, action.payload)

  if (response) {
    if (response.data.success) {
      yield put({
        type: actions.SET_STATE,
        payload: {
          attendance: response.data.data,
          pagination: response.data.meta.pagination,
        },
      })
    } else {
      yield put({
        type: errorActions.SET_STATE,
        payload: response.data,
      })
    }
  }
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: false,
    },
  })
}

export function* WATCH_LOAD_UPDATE_ATTENDANCE_STATUS(action) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })

  const response = yield call(updateEnrollmentAttendance, action.payload)

  if (response) {
    if (response.data.success) {
      notification.success({
        message: 'Success!',
        description: 'Attendance status updated',
      })
    } else {
      yield put({
        type: errorActions.SET_STATE,
        payload: response.data,
      })
    }
  }
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: false,
    },
  })
}

export function* WATCH_TRANSFER_ENROLLMENT(action) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      transferEnrollmentLoading: true,
    },
  })

  const response = yield call(transferEnrollment, action.payload.id, action.payload.data)

  yield put({
    type: actions.SET_STATE,
    payload: {
      showTransferModal: false,
    },
  })
  if (response) {
    if (response.data.success) {
      notification.success({
        message: 'Success!',
        description: 'Enrollment successfully transferred',
      })
    } else {
      yield put({
        type: errorActions.SET_STATE,
        payload: response.data,
      })
    }
  }
  yield put({
    type: actions.SET_STATE,
    payload: {
      transferEnrollmentLoading: false,
    },
  })
}

export function* handleUpdateClassFee(
  actionType,
  apiCall,
  successMessage,
  { closeOnSuccess = false, callOnSuccess = false, successApiPayload = {}, successApiType },
) {
  yield put({
    type: actionType,
    payload: {
      loading: true,
    },
  })

  const response = yield call(apiCall)

  if (response) {
    if (response.data.success) {
      notification.success({
        message: 'Success!',
        description: successMessage,
      })

      if (callOnSuccess)
        yield put({
          type: successApiType,
          payload: successApiPayload,
        })

      yield put({
        type: actionType,
        payload: {
          visible: !closeOnSuccess,
        },
      })
    }
  } else {
    yield put({
      type: errorActions.SET_STATE,
      payload: response?.data,
    })
  }

  yield put({
    type: actionType,
    payload: {
      loading: false,
    },
  })
}

export function* WATCH_ADJUST_CLASS_FEES(action) {
  yield handleUpdateClassFee(
    actions.SET_CLASS_FEE_MODAL,
    () => editClassFeesEnrollment(action.payload.id, action.payload.data),
    'Fees Updated Successfully kjk!',
    { closeOnSuccess: true },
  )
}

export function* WATCH_EDIT_CLASS_FEE(action) {
  yield handleUpdateClassFee(
    actions.SET_INSTALLMENT_FEE_MODAL,
    () => editClassFeesEnrollment(action.payload.id, action.payload.data),
    'Fees Updated Successfully!',
    {
      callOnSuccess: true,
      closeOnSuccess: false,
      successApiType: counselorActions.CLASSES_FOR_EACH_BATCH,
      successApiPayload: action.payload.extra,
    },
  )
}

export function* PRINT_RECEIPT(action) {
  const response = yield call(printReceiptEnrollment, action.payload.id, action.payload.data)
  if (response) {
    if (response.data.success) {
      const url = process.env.REACT_APP_API_HOST + response.data.data.file
      window.open(url)

      notification.success({
        message: 'Success!',
        description: 'Receipt downloaded Successfully!',
      })
      yield put({
        type: actions.SET_CLASS_FEE_MODAL,
        payload: {
          visible: false,
        },
      })
    } else {
      yield put({
        type: errorActions.SET_STATE,
        payload: response.data,
      })
    }
  }
}

export function* GET_CERTIFICATE(action) {
  const response = yield call(getCertificateEnrollment, action.payload.id, action.payload.data)
  if (response) {
    if (response.data.success) {
      const url = process.env.REACT_APP_API_HOST + response.data.data.file
      window.open(url)

      notification.success({
        message: 'Success!',
        description: 'Certificate downloaded Successfully!',
      })
      // yield put({
      //   type: actions.SET_CLASS_FEE_MODAL,
      //   payload: {
      //     visible: false,
      //   },
      // })
    } else {
      yield put({
        type: errorActions.SET_STATE,
        payload: response.data,
      })
    }
  }
}

export function* CANCELLATION_REASONS(action) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })

  const response = yield call(getCancellationReasons, action.payload)

  if (response) {
    if (response.data.success) {
      yield put({
        type: actions.CANCELLATION_REASONS_SUCCESS,
        payload: response.data.data,
      })
    }
  }
  // yield put({
  //   type: actions.SET_STATE,
  //   payload: {
  //     loading: false,
  //   },
  // })
}

export function* WATCH_CLASS_LEAVE(action) {
  yield put({
    type: actions.SET_INSTALLMENT_FEE_MODAL,
    payload: {
      loading: true,
    },
  })

  const response = yield call(markClassLeave, action.payload.id, action.payload.data)
  if (response) {
    if (response.data.success) {
      notification.success({
        message: 'Success!',
        description: 'Class updated successfully!',
      })

      if (action.payload.extra) {
        yield put({
          type: counselorActions.CLASSES_FOR_EACH_BATCH,
          payload: action.payload.extra,
        })
      }
    } else {
      yield put({
        type: errorActions.SET_STATE,
        payload: response.data,
      })
    }
  }

  yield put({
    type: actions.SET_INSTALLMENT_FEE_MODAL,
    payload: {
      loading: false,
    },
  })
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.LOAD_ENROLLMENTS_INDEX, WATCH_LOAD_ENROLLMENTS_INDEX),
    takeEvery(actions.LOAD_ENROLLMENT_COUNT, WATCH_LOAD_ENROLLMENTS_COUNT),
    takeEvery(actions.LOAD_CURRENT_ENROLLMENT, WATCH_LOAD_CURRENT_ENROLLMENT),
    takeEvery(actions.LOAD_ENROLLMENT_BY_ORDER, WATCH_LOAD_ENROLLMENT_BY_ORDER),
    takeEvery(actions.CREATE_ENROLLMENT, WATCH_CREATE_ENROLLMENT),
    takeEvery(actions.CANCEL_ENROLLMENT, WATCH_CANCEL_ENROLLMENT),
    takeEvery(actions.UPDATE_ENROLLMENT, WATCH_UPDATE_ENROLLMENT),
    takeEvery(actions.UPDATE_COMMISSION, WATCH_UPDATE_COMMISSION),
    takeEvery(actions.LOAD_ENROLLMENT_ATTENDANCE, WATCH_LOAD_ENROLLMENT_ATTENDANCE),
    takeEvery(actions.LOAD_UPDATE_ATTENDANCE_STATUS, WATCH_LOAD_UPDATE_ATTENDANCE_STATUS),
    takeEvery(actions.TRANSFER_ENROLLMENT, WATCH_TRANSFER_ENROLLMENT),
    takeEvery(actions.ADJUST_CLASS_FEES, WATCH_ADJUST_CLASS_FEES),
    takeEvery(actions.PRINT_RECEIPT, PRINT_RECEIPT),
    takeEvery(actions.GET_CERTIFICATE, GET_CERTIFICATE),
    takeEvery(actions.CANCELLATION_REASONS, CANCELLATION_REASONS),
    takeEvery(actions.UPDATE_TRIAL_ENROLLMENT_TYPE, WATCH_UPDATE_TRIAL_ENROLLEMENT_TYPE),
    takeEvery(actions.EDIT_CLASS_FEE, WATCH_EDIT_CLASS_FEE),
    takeEvery(actions.MARK_CLASS_LEAVE, WATCH_CLASS_LEAVE),
  ])
}
