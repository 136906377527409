import React from 'react'
import { connect } from 'react-redux'
import classNames from 'classnames'
import style from './style.module.scss'

const mapStateToProps = ({ settings }) => ({ settings })

@connect(mapStateToProps)
class Footer extends React.Component {
  render() {
    const {
      settings: { isContentNoMaxWidth },
    } = this.props
    return (
      <div
        className={classNames(style.footer, {
          [style.footerFullWidth]: isContentNoMaxWidth,
        })}
      >
        <div className={style.inner}>
          <div className="row">
            <div className="col-md-8 footer-mobile" xs={6} sm={6} md={8} lg={8} xl={8}>
              {/* <p>
                <strong>Air UI Admin Template - Best Solution for Your Next Big App!</strong>
              </p>
              <p>
                Air UI is a set of modern professional Html / React / Vue and Angular based
                templates. This is a powerful and super flexible tool, which suits best for any kind
                of web application: Web Sites, Web Applications, Hybrid Apps, CRM, CMS, Admin
                Panels, etc.
              </p> */}
              <p className="copyright-mobile" style={{ marginBottom: '0' }}>
                &copy; {new Date().getFullYear()} Cedarwood
              </p>
            </div>
            <div className="col-md-4 footer-mobile" xs={6} sm={6} md={4} lg={4} xl={4}>
              <div className="d-flex justify-content-end">
                <img src="resources/images/logo-white.svg" alt="Cedarwood" width="120" />
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Footer
