import actions from './actions'

const initialState = {
  loading: false,
  index: [],
  pagination: null,
  filters: {
    page: 1,
    per_page: 15,
  },
  modal: { visible: false, role: {}, edit: false },
}

export default function parentReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_FILTERS:
      return {
        ...state,
        filters: {
          ...state.filters,
          ...action.payload,
        },
      }

    case actions.SET_STATE:
      return { ...state, ...action.payload }

    case actions.CLEAR_FILTERS:
      return { ...state, filters: {} }
    case actions.SHOW_MODAL:
      return { ...state, modal: { ...state.modal, ...action.payload } }
    default:
      return state
  }
}
