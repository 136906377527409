import { all, takeEvery, put, call } from 'redux-saga/effects'
import getNotifications, { createNotification, getRecipients } from 'services/notifications'
import { push } from 'react-router-redux'
import { notification } from 'antd'
import actions from './actions'
import errorActions from '../error/actions'

export function* WATCH_CREATE_NOTIFICATION(action) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })

  const response = yield call(createNotification, action.payload)
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: false,
    },
  })

  if (response) {
    if (response.data.success) {
      yield put(push('/notifications'))
      notification.success({
        message: 'Success!',
        description: 'Notification created successfully',
      })
    } else {
      yield put({
        type: errorActions.SET_STATE,
        payload: response.data,
      })
    }
  }
}

export function* LOAD_NOTIFICATIONS_INDEX(action) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })

  const response = yield call(getNotifications, action.payload)

  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: false,
    },
  })

  if (response) {
    if (response.data.success) {
      yield put({
        type: actions.SET_STATE,
        payload: {
          index: response.data.data,
          pagination: response.data.meta.pagination,
        },
      })
    } else {
      yield put({
        type: errorActions.SET_STATE,
        payload: response.data,
      })
    }
  }
}

export function* LOAD_RECIPIENTS(action) {
  const { recipient, query } = action.payload
  const response = yield call(getRecipients, query, recipient)

  if (response) {
    if (response.data.success) {
      yield put({
        type: actions.SET_STATE,
        payload: {
          recipients: response.data.data,
        },
      })
    } else {
      yield put({
        type: errorActions.SET_STATE,
        payload: response.data,
      })
    }
  }
}

export default function* rootSaga() {
  yield all([takeEvery(actions.CREATE_NOTIFICATION, WATCH_CREATE_NOTIFICATION)])
  yield all([takeEvery(actions.LOAD_NOTIFICATIONS_INDEX, LOAD_NOTIFICATIONS_INDEX)])
  yield all([takeEvery(actions.LOAD_RECIPIENTS, LOAD_RECIPIENTS)])
}
