import { notification } from 'antd'
import axiosInstance from 'utilities/configureAxios'
import route from 'utilities/route'

export default async function fetchZonesIndex(payload = {}) {
  return axiosInstance
    .get(route('zones.index'), payload)
    .then(res => res)
    .catch(error => {
      notification.warning({
        message: error.code,
        description: error.message,
      })
      return error.response
    })
}

export async function updateZone(id, payload, extraParams = {}) {
  const routeValue = route('zones.edit', { id })
  return axiosInstance
    .put(routeValue, payload, { params: extraParams })
    .then(res => res)
    .catch(error => {
      notification.warning({
        message: error.code,
        description: error.message,
      })
      return error.response
    })
}

export async function createZone(payload, extraParams = {}) {
  return axiosInstance
    .post(route('zones.create'), payload, { params: extraParams })
    .then(res => res)
    .catch(error => {
      notification.warning({
        message: error.code,
        description: error.message,
      })
      return error.response
    })
}
