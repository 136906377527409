import axios from 'axios'
import { getLoginStatus } from 'selectors/auths'
import { notification } from 'antd'
import actions from 'redux/auth/actions'
import { push } from 'react-router-redux'
import { get } from 'lodash'
import appConfig from '../config'

const { appConfig: Config } = appConfig

const axiosConfiguration = () =>
  axios.create({
    baseURL: Config.api.host,
    timeout: 30000,
  })

const axiosInstance = axiosConfiguration()

axiosInstance.interceptors.request.use(async config => {
  const { store } = await import('../index')
  const state = store.getState()
  config.headers = {
    Accept: 'application/json',
    Authorization: `Bearer ${state.auth.token}`,
  }

  return config
})

axiosInstance.interceptors.response.use(
  response => {
    return response
  },
  err => {
    import('../index').then(persistStore => {
      const { store } = persistStore
      const state = store.getState()
      if (err.response) {
        if (get(err, 'response.status', false)) {
          if (err.response.status === 401) {
            if (getLoginStatus(state)) {
              store.dispatch({ type: actions.UNAUTHORIZED })
            }
            push('/')
          }
        }
      }
    })
    if (get(err, 'response.status', '') === 422) {
      return err.response
    }
    const message = 'ERROR!!!     '
    return notification.error({
      message,
      description: get(err, 'response.data.message', 'Network Error'),
    })
  },
)

export default axiosInstance
