import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import { getLoginStatus } from 'selectors/auths'

const ProtectedRoute = ({ component: Component, isprotected, loggedIn, ...rest }) => {
  if (isprotected) {
    if (loggedIn) {
      return <Route {...rest} render={Component} />
    }
    return <Redirect to={{ pathname: '/' }} />
  }

  return <Route {...rest} render={Component} />
}

const mapStateToProps = state => {
  return {
    loggedIn: getLoginStatus(state),
  }
}

export default connect(mapStateToProps)(ProtectedRoute)
