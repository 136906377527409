const actions = {
  SET_STATE: 'configuarations/SET_STATE',
  SET_FILTERS: 'configuarations/SET_FILTERS',
  LOAD_CONFIGURATIONS_INDEX: 'configuarations/LOAD_CONFIGURATIONS_INDEX',
  UPDATE_CONFIGURATION: 'configuarations/UPDATE_CONFIGURATION',
  GENERATE_TEACHERS_OTP: 'configuarations/GENERATE_TEACHERS_OTP',
  SET_EDIT_MODAL_VISIBLE: 'configuarations/SET_EDIT_MODAL_VISIBLE',
}

export default actions
