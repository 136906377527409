const actions = {
  SET_STATE: 'cancellationReasons/SET_STATE',
  SET_FILTERS: 'cancellationReasons/SET_FILTERS',
  HIDE_MODAL: 'cancellationReasons/HIDE_MODAL',
  CLEAR_FILTERS: 'cancellationReasons/CLEAR_FILTERS',
  LOAD_CANCELLATION_REASONS_INDEX: 'cancellationReasons/LOAD_CANCELLATION_REASONS_INDEX',
  LOAD_CURRENT_CANCELLATION_REASONS: 'cancellationReasons/LOAD_CURRENT_CANCELLATION_REASONS',
  CREATE_CANCELLATION_REASONS: 'cancellationReasons/CREATE_CANCELLATION_REASONS',
  UPDATE_CANCELLATION_REASONS: 'cancellationReasons/UPDATE_CANCELLATION_REASONS',
  DELETE_CANCELLATION_REASONS: 'cancellationReasons/DELETE_CANCELLATION_REASONS',
}

export default actions
