import { all, takeEvery, put, call } from 'redux-saga/effects'
import { notification } from 'antd'
import getBoards, { addNewBoard, removeBoard, updateBoard } from '../../services/boards'
import actions from './actions'
import errorActions from '../error/actions'

export function* WATCH_LOAD_BOARD_INDEX(action) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })

  const response = yield call(getBoards, action.payload)

  if (response) {
    if (response.data.success) {
      yield put({
        type: actions.SET_STATE,
        payload: {
          index: response.data.data,
          pagination: response.data.meta.pagination,
        },
      })
    } else {
      yield put({
        type: errorActions.SET_STATE,
        payload: response.data,
      })
    }
  }

  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: false,
    },
  })
}

export function* WATCH_CREATE_BOARD(action) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })

  const response = yield call(addNewBoard, action.payload)

  if (response) {
    if (response.data.success) {
      yield put({
        type: actions.SET_STATE,
        payload: {
          current: {},
          modalType: '',
        },
      })
      notification.success({
        message: 'Success !',
        description: 'Board added successfully',
      })
      yield put({
        type: actions.LOAD_BOARD_INDEX,
      })
    } else {
      yield put({
        type: errorActions.SET_STATE,
        payload: response.data,
      })
    }

    yield put({
      type: actions.SET_STATE,
      payload: {
        loading: false,
      },
    })
  }
}

export function* WATCH_UPDATE_BOARD(action) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })

  const response = yield call(updateBoard, action.payload.id, action.payload.data)

  if (response) {
    if (response.data.success) {
      yield put({
        type: actions.SET_STATE,
        payload: {
          current: {},
          modalType: '',
        },
      })
      notification.success({
        message: 'Success !',
        description: 'Board updated successfully',
      })
      yield put({
        type: actions.LOAD_BOARD_INDEX,
      })
    } else {
      yield put({
        type: errorActions.SET_STATE,
        payload: response.data,
      })
    }

    yield put({
      type: actions.SET_STATE,
      payload: {
        loading: false,
      },
    })
  }
}

export function* WATCH_DELETE_BOARD(action) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })

  const response = yield call(removeBoard, action.payload.id)

  if (response) {
    if (response.data.success) {
      yield put({
        type: actions.SET_STATE,
        payload: {
          current: {},
          modalType: '',
        },
      })
      notification.success({
        message: 'Success!',
        description: 'Board deleted successfully',
      })
      yield put({
        type: actions.LOAD_BOARD_INDEX,
      })
    } else {
      yield put({
        type: errorActions.SET_STATE,
        payload: response.data,
      })
    }

    yield put({
      type: actions.SET_STATE,
      payload: {
        loading: false,
      },
    })
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.LOAD_BOARD_INDEX, WATCH_LOAD_BOARD_INDEX),
    takeEvery(actions.CREATE_BOARD, WATCH_CREATE_BOARD),
    takeEvery(actions.UPDATE_BOARD, WATCH_UPDATE_BOARD),
    takeEvery(actions.DELETE_BOARD, WATCH_DELETE_BOARD),
  ])
}
