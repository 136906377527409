const actions = {
  SET_STATE: 'programs/SET_STATE',
  CREATE_PROGRAM: 'programs/CREATE_PROGRAM',
  EDIT_PROGRAM: 'programs/EDIT_PROGRAM',
  WATCH_EDIT_PROGRAM_STATUS: 'programs/WATCH_EDIT_PROGRAM_STATUS',
  LOAD_PROGRAMS_INDEX: 'programs/LOAD_PROGRAMS_INDEX',
  LOAD_SELECTED_PROGRAM: 'programs/LOAD_SELECTED_PROGRAM',
  SET_FILTERS: 'programs/SET_FILTERS',
  CLEAR_FILTERS: 'programs/CLEAR_FILTERS',
  LOAD_PROGRAM_REGISTRATIONS: 'program/LOAD_PROGRAM_REGISTRATIONS',
}

export default actions
