import { notification } from 'antd'
import axiosInstance from 'utilities/configureAxios'
import route from 'utilities/route'

export default async function getLedgers(payload = {}) {
  return axiosInstance
    .get(route('ledgers.index'), payload)
    .then(res => res)
    .catch(error => {
      notification.warning({
        message: error.code,
        description: error.message,
      })
      return error.response
    })
}

export async function getLedger(payload = {}) {
  const routeValue = `${route('ledgers.index')}/${payload.id}`
  return axiosInstance
    .get(routeValue, {
      params: payload.params,
    })
    .then(res => res)
    .catch(error => {
      notification.warning({
        message: error.code,
        description: error.message,
      })
      return error.response
    })
}

export async function getSettlementAmount(payload = {}) {
  const routeValue = `${route('ledgers.index')}/${payload.id}/amount`
  return axiosInstance
    .get(routeValue, {
      params: payload.params,
    })
    .then(res => res)
    .catch(error => {
      notification.warning({
        message: error.code,
        description: error.message,
      })
      return error.response
    })
}

export async function editLedger(id, data = {}) {
  const routeValue = `${route('ledgers.index')}/${id}`
  return axiosInstance
    .patch(routeValue, data)
    .then(res => res)
    .catch(error => {
      notification.warning({
        message: error.code,
        description: error.message,
      })
      return error.response
    })
}

export async function addLedger(data = {}) {
  const routeValue = `${route('ledgers.index')}`
  return axiosInstance
    .post(routeValue, data)
    .then(res => res)
    .catch(error => {
      notification.warning({
        message: error.code,
        description: error.message,
      })
      return error.response
    })
}

export async function createSettlement(id, data = {}) {
  const routeValue = route('ledgers.create_settlement', { id })
  return axiosInstance
    .post(routeValue, data)
    .then(res => res)
    .catch(error => {
      notification.warning({
        message: error.code,
        description: error.message,
      })
      return error.response
    })
}

export async function exportLedgers(payload = {}, extraParams = {}) {
  const routeValue = route('ledgers.export')
  return axiosInstance
    .post(routeValue, payload, extraParams)
    .then(res => res)
    .catch(error => {
      notification.warning({
        message: error.code,
        description: error.message,
      })
      return error.response
    })
}
