import actions from './actions'

const initialState = {
  index: [],
  loading: false,
  refundModal: false,
  guardian_id: '',
  filters: {
    course: '',
    guardian_id: '',
    credit_debit_status: '',
    status: '',
  },
}

export default function walletReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }
    case actions.TOGGLE_WALLET:
      return {
        ...state,
        refundModal: action?.payload?.modalState,
        guardian_id: action?.payload?.id ?? null,
      }
    case actions.SET_FILTERS: {
      return {
        ...state,
        filters: {
          ...state.filters,
          ...action.payload,
        },
      }
    }
    case actions.RESET_FILTERS: {
      return {
        ...state,
        filters: {
          course: '',
          guardian_id: '',
          credit_debit_status: '',
          status: '',
        },
      }
    }

    case actions.LOGOUT:
      return {}
    default:
      return state
  }
}
