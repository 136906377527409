import { notification } from 'antd'
import axiosInstance from 'utilities/configureAxios'
import route from 'utilities/route'

export default async function getTestimonials(payload = {}) {
  return axiosInstance
    .get(route('testimonials.index'), payload)
    .then(res => res)
    .catch(error => {
      notification.warning({
        message: error.code,
        description: error.message,
      })
      return error.response
    })
}

export async function getTestimonial(payload = {}) {
  const routeValue = `${route('testimonials.index')}/${payload.id}`
  return axiosInstance
    .get(routeValue, {
      params: payload.params,
    })
    .then(res => res)
    .catch(error => {
      notification.warning({
        message: error.code,
        description: error.message,
      })
      return error.response
    })
}

export async function createTestimonialUpdate(id, data = new FormData()) {
  return axiosInstance
    .post(route('testimonials.testimonial_update', { id }), data)
    .then(res => res)
    .catch(error => {
      notification.warning({
        message: error.code,
        description: error.message,
      })
      return error.response
    })
}

export async function createTestimonial(payload = {}) {
  return axiosInstance
    .post(route('testimonials.create'), payload)
    .then(res => res)
    .catch(error => {
      notification.warning({
        message: error.code,
        description: error.message,
      })
      return error.response
    })
}

export async function updateTestimonial(testimonialId, payload = {}) {
  return axiosInstance
    .post(route('testimonials.update').concat(testimonialId), payload)
    .then(res => res)
    .catch(error => {
      notification.warning({
        message: error.code,
        description: error.message,
      })
      return error.response
    })
}

export async function deleteTestimonial(testimonialId) {
  const routeValue = `${route('testimonials.delete')}${testimonialId}`
  return axiosInstance
    .delete(routeValue)
    .then(res => res)
    .catch(error => {
      notification.warning({
        message: error.code,
        description: error.message,
      })
      return error.response
    })
}
