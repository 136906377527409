import { all, takeEvery, put, call, select } from 'redux-saga/effects'
import getClasses, { createClassUpdate, getClass, markClassAttendence } from 'services/classes'
import { push } from 'react-router-redux'
import { notification } from 'antd'
import { getClassesFilters } from 'selectors/classes'
import actions from './actions'
import errorActions from '../error/actions'
import { markClassHoliday } from '../../services/classes'

export function* WATCH_LOAD_CLASSES_INDEX(action) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })

  const response = yield call(getClasses, action.payload)

  if (response) {
    if (response.data.success) {
      yield put({
        type: actions.SET_STATE,
        payload: {
          index: response.data.data,
          pagination: response.data.meta.pagination,
        },
      })
    } else {
      yield put({
        type: errorActions.SET_STATE,
        payload: response.data,
      })
    }
  }
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: false,
    },
  })
}

export function* WATCH_LOAD_CURRENT_CLASS(action) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })

  const response = yield call(getClass, action.payload)
  if (response) {
    if (response.data.success) {
      yield put({
        type: actions.SET_STATE,
        payload: {
          current: response.data.data,
        },
      })
    } else {
      yield put({
        type: errorActions.SET_STATE,
        payload: response.data,
      })
    }
  }
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: false,
    },
  })
}

export function* CREATE_CLASS_UPDATE(action) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })

  const response = yield call(createClassUpdate, action.payload.id, action.payload.data)
  if (response) {
    if (response.data.success) {
      if (action?.payload?.typeRevert) {
        notification.success({
          message: 'Success!',
          description: 'Class Reverted',
        })
        const filters = yield select(getClassesFilters)
        yield put({
          type: actions.LOAD_CLASSES_INDEX,
          payload: {
            params: {
              ...filters,
              includes: 'course,teacher',
            },
          },
        })
      } else {
        yield put(push('/classes'))
        notification.success({
          message: 'Success!',
          description: 'Class updates added',
        })
        const filters = yield select(getClassesFilters)
        yield put({
          type: actions.LOAD_CLASSES_INDEX,
          payload: {
            params: {
              ...filters,
              includes: 'course,teacher',
            },
          },
        })
      }
    } else {
      yield put({
        type: errorActions.SET_STATE,
        payload: response.data,
      })
    }
  }
  if (action?.payload?.typeRevert) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        loading: true,
      },
    })
  }

  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: false,
    },
  })
}

export function* MARK_CLASS_HOLIDAY(action) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })

  const response = yield call(markClassHoliday, action.payload.id, action.payload.data)

  if (response) {
    if (response.data.success) {
      yield put({
        type: actions.UPDATE_SPECIFIC_CLASS,
        payload: response.data.data,
      })

      notification.success({
        message: 'Success!',
        description: 'Class Status Updated.',
      })
    } else {
      yield put({
        type: errorActions.SET_STATE,
        payload: response.data,
      })
    }
  }

  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: false,
    },
  })
}

export function* MARK_CLASS_ATTENDENCE(action) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })

  const response = yield call(markClassAttendence, action.payload.id, action.payload.data)

  if (response) {
    if (response.data.success) {
      notification.success({
        message: 'Success!',
        description: 'Class attendence marked',
      })
      yield put(push(`/classes/${action.payload.id}/classUpdates`))
    } else {
      yield put({
        type: errorActions.SET_STATE,
        payload: response.data,
      })
    }
  }
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: false,
    },
  })
}

export default function* rootSaga() {
  yield all([takeEvery(actions.LOAD_CLASSES_INDEX, WATCH_LOAD_CLASSES_INDEX)])
  yield all([takeEvery(actions.CREATE_CLASS_UPDATE, CREATE_CLASS_UPDATE)])
  yield all([takeEvery(actions.MARK_CLASS_ATTENDENCE, MARK_CLASS_ATTENDENCE)])
  yield all([takeEvery(actions.LOAD_CURRENT_CLASS, WATCH_LOAD_CURRENT_CLASS)])
  yield all([takeEvery(actions.MARK_CLASS_HOLIDAY, MARK_CLASS_HOLIDAY)])
}
