import { notification } from 'antd'
import axiosInstance from 'utilities/configureAxios'
import route from 'utilities/route'

export default async function createWalletPaymentLink(payload = {}) {
  const url = route('wallet.add', { uuid: payload.parent_uuid })
  const apiPayload = {
    ...payload,
  }
  return axiosInstance
    .post(url, apiPayload)
    .then(res => res)
    .catch(error => {
      notification.warning({
        message: error.code,
        description: error.message,
      })
      return error.response
    })
}

export async function getWalletsPayments(payload = {}) {
  return axiosInstance
    .get(route('wallet.index'), payload)
    .then(res => res)
    .catch(error => {
      notification.warning({
        message: error.code,
        description: error.message,
      })
      return error.response
    })
}

export async function getWalletsPaymentsExport(payload = {}) {
  return axiosInstance
    .post(route('wallet.export'), payload)
    .then(res => res)
    .catch(error => {
      notification.warning({
        message: error.code,
        description: error.message,
      })
      return error.response
    })
}
export async function makeParentRefund(payload = {}) {
  return axiosInstance
    .post(route('refund.do_refund'), payload)
    .then(res => res)
    .catch(error => {
      notification.warning({
        message: error.code,
        description: error.message,
      })
      return error.response
    })
}

export async function getRazorpayCred(payload = {}) {
  const routeValue = route('razorpayCred.index')
  return axiosInstance
    .get(routeValue, {
      params: payload.params,
    })
    .then(res => res.data)
    .catch(error => {
      notification.warning({
        message: error.code,
        description: error.message,
      })
      return error.response
    })
}
