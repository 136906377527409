import { notification } from 'antd'
import axiosInstance from 'utilities/configureAxios'
import route from 'utilities/route'

export default async function getOrders(payload = {}) {
  return axiosInstance
    .get(route('orders.index'), payload)
    .then(res => res)
    .catch(error => {
      notification.warning({
        message: error.code,
        description: error.message,
      })
      return error.response
    })
}

export async function updateStatus(id, payload = {}) {
  return axiosInstance
    .patch(route('orders.update_status', { id }), payload)
    .then(res => res)
    .catch(error => {
      notification.warning({
        message: error.code,
        description: error.message,
      })
      return error.response
    })
}

export async function collectFees(id, data, byWallet) {
  const payload = {
    ...data,
    by_wallet: byWallet,
  }
  return axiosInstance
    .post(route('orders.collect_fees', { id }), payload)
    .then(res => res)
    .catch(error => {
      notification.warning({
        message: error.code,
        description: error.message,
      })
      return error.response
    })
}

export async function collectFeesEmandate(id, data) {
  return axiosInstance
    .post(route('orders.collect_fees_emandate', { id }), data)
    .then(res => res)
    .catch(error => {
      notification.warning({
        message: error.code,
        description: error.message,
      })
      return error.response
    })
}

export async function getOrderDetails(orderId, payload = {}) {
  const url = route('orders.current_order') + orderId
  return axiosInstance
    .get(url, payload)
    .then(res => res)
    .catch(error => {
      notification.warning({
        message: error.code,
        description: error.message,
      })
      return error.response
    })
}

export async function exportOrders(payload = {}, extraParams = {}) {
  const routeValue = route('orders.export')
  return axiosInstance
    .post(routeValue, payload, extraParams)
    .then(res => res)
    .catch(error => {
      notification.warning({
        message: error.code,
        description: error.message,
      })
      return error.response
    })
}

export async function updateOrderAmount(id, data) {
  return axiosInstance
    .post(route('orders.update_amount', { id }), data)
    .then(res => res)
    .catch(error => {
      notification.warning({
        message: error.code,
        description: error.message,
      })
      return error.response
    })
}
export async function AddDue(id, data) {
  console.log('VIKRAM', 1, id)
  return axiosInstance
    .post(route('orders.add_due', { id }), data)
    .then(res => res)
    .catch(error => {
      notification.warning({
        message: error.code,
        description: error.message,
      })
      return error.response
    })
}
