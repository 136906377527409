import { notification } from 'antd'
import axiosInstance from 'utilities/configureAxios'
import route from 'utilities/route'

export default async function fetchCoursesIndex(payload = {}) {
  return axiosInstance
    .get(route('courses.index'), payload)
    .then(res => res)
    .catch(error => {
      notification.warning({
        message: error.code,
        description: error.message,
      })
      return error.response
    })
}

export async function createCourse(payload = {}) {
  return axiosInstance
    .post(route('courses.create'), payload)
    .then(res => res)
    .catch(error => {
      notification.warning({
        message: error.code,
        description: error.message,
      })
      return error.response
    })
}

export async function getCourseTypes(payload = {}) {
  return axiosInstance
    .get(route('courses.course_types'), payload)
    .then(res => res)
    .catch(error => {
      notification.warning({
        message: error.code,
        description: error.message,
      })
      return error.response
    })
}

export async function getCurrentCourse(id, payload = {}) {
  const routeValue = route('courses.current_course') + id
  return axiosInstance
    .get(routeValue, payload)
    .then(res => res)
    .catch(error => {
      notification.warning({
        message: error.code,
        description: error.message,
      })
      return error.response
    })
}

export async function updateCourse(id, payload = {}) {
  const routeValue = route('courses.update_course') + id
  return axiosInstance
    .post(routeValue, payload)
    .then(res => res)
    .catch(error => {
      notification.warning({
        message: error.code,
        description: error.message,
      })
      return error.response
    })
}
export async function archiveCourseApi(id, payload = {}) {
  const routeValue = route('courses.archive') + id
  return axiosInstance
    .delete(routeValue, payload)
    .then(res => res)
    .catch(error => {
      notification.warning({
        message: error.code,
        description: error.message,
      })
      return error.response
    })
}
export async function unarchiveCourseApi(id, payload = {}) {
  const routeValue = route('courses.unarchive') + id
  return axiosInstance
    .get(routeValue, payload)
    .then(res => res)
    .catch(error => {
      notification.warning({
        message: error.code,
        description: error.message,
      })
      return error.response
    })
}

export async function exportCourses(payload = {}, extraParams = {}) {
  const routeValue = route('courses.export')
  return axiosInstance
    .post(routeValue, payload, extraParams)
    .then(res => res)
    .catch(error => {
      notification.warning({
        message: error.code,
        description: error.message,
      })
      return error.response
    })
}
