import { all, takeEvery, put, call } from 'redux-saga/effects'
import fetchCourseLearnings, {
  createCourseLearningsUpdate,
  createCourseLearnings,
  getCourseLearning,
  updateCourseLearnings,
  deleteCourseLearning,
} from 'services/courseLearnings'
import { push } from 'react-router-redux'
import { notification } from 'antd'
import actions from './actions'
import errorActions from '../error/actions'

export function* WATCH_LOAD_COURSELEARNINGS_INDEX(action) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })

  const response = yield call(fetchCourseLearnings, action.payload)

  if (response) {
    if (response.data.success) {
      yield put({
        type: actions.SET_STATE,
        payload: {
          index: response.data.data,
          pagination: response.data.meta.pagination,
        },
      })
    } else {
      yield put({
        type: errorActions.SET_STATE,
        payload: response.data,
      })
    }
  }
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: false,
    },
  })
}

export function* WATCH_LOAD_CURRENT_COURSELEARNING(action) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })

  const response = yield call(getCourseLearning, action.payload)
  if (response) {
    if (response.data.success) {
      yield put({
        type: actions.SET_STATE,
        payload: {
          current: response.data.data,
        },
      })
    } else {
      yield put({
        type: errorActions.SET_STATE,
        payload: response.data,
      })
    }
  }
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: false,
    },
  })
}

export function* CREATE_COURSELEARNING_UPDATE(action) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })

  const response = yield call(createCourseLearningsUpdate, action.payload.id, action.payload.data)

  if (response) {
    if (response.data.success) {
      yield put(push('/course-learnings'))
      notification.success({
        message: 'Success!',
        description: 'CourseLearning updates added',
      })
    } else {
      yield put({
        type: errorActions.SET_STATE,
        payload: response.data,
      })
    }
  }
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: false,
    },
  })
}

export function* WATCH_CREATE_COURSELEARNING(action) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })

  const response = yield call(createCourseLearnings, action.payload)

  if (response) {
    if (response.data.success) {
      yield put(push('/course-learnings'))
      yield put({
        type: errorActions.SET_STATE,
        payload: { errors: {} },
      })
    } else if (response.data.code === 422) {
      yield put({
        type: errorActions.SET_STATE,
        payload: response.data,
      })
    }
  }
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: false,
    },
  })
}

export function* WATCH_UPDATE_COURSELEARNING(action) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })

  const response = yield call(updateCourseLearnings, action.payload.id, action.payload.data)

  if (response) {
    if (response.data.success) {
      yield put(push('/course-learnings'))
      notification.success({
        message: 'Success!',
        description: 'CourseLearning updated successfully',
      })
    } else {
      yield put({
        type: errorActions.SET_STATE,
        payload: response.data,
      })
    }
  }
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: false,
    },
  })
}

export function* DELETE_COURSELEARNING(action) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })

  const response = yield call(deleteCourseLearning, action.payload.id)
  if (response) {
    if (response.status === 204) {
      yield put({
        type: actions.LOAD_COURSELEARNING_INDEX,
        payload: {
          params: {
            includes: 'course',
          },
        },
      })
    } else {
      yield put({
        type: errorActions.SET_STATE,
        payload: response.data,
      })
    }
  }
}

export default function* rootSaga() {
  yield all([takeEvery(actions.LOAD_COURSELEARNING_INDEX, WATCH_LOAD_COURSELEARNINGS_INDEX)])
  yield all([takeEvery(actions.CREATE_COURSELEARNING_UPDATE, CREATE_COURSELEARNING_UPDATE)])
  yield all([takeEvery(actions.LOAD_CURRENT_COURSELEARNING, WATCH_LOAD_CURRENT_COURSELEARNING)])
  yield all([takeEvery(actions.CREATE_COURSELEARNING, WATCH_CREATE_COURSELEARNING)])
  yield all([takeEvery(actions.UPDATE_COURSELEARNING, WATCH_UPDATE_COURSELEARNING)])
  yield all([takeEvery(actions.DELETE_COURSELEARNING, DELETE_COURSELEARNING)])
}
