import { notification } from 'antd'
import axiosInstance from 'utilities/configureAxios'
import route from 'utilities/route'

export default async function getLocations(payload = {}) {
  return axiosInstance
    .get(route('location.index'), payload)
    .then(res => res)
    .catch(error => {
      notification.warning({
        message: error.code,
        description: error.message,
      })
      return error.response
    })
}

export async function createLocation(payload = {}) {
  return axiosInstance
    .post(route('location.index'), payload)
    .then(res => res)
    .catch(error => {
      notification.warning({
        message: error.code,
        description: error.message,
      })
      return error.response
    })
}

export async function getCurrentLocation(cityId, payload = {}) {
  const url = route('location.current_location') + cityId
  return axiosInstance
    .get(url, payload)
    .then(res => res)
    .catch(error => {
      notification.warning({
        message: error.code,
        description: error.message,
      })
      return error.response
    })
}

export async function updateCurrentLocation(cityId, payload = {}) {
  const url = route('location.current_location') + cityId

  return axiosInstance
    .post(url, payload)
    .then(res => res)
    .catch(error => {
      notification.warning({
        message: error.code,
        description: error.message,
      })
      return error.response
    })
}
