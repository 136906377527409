const actions = {
  SET_STATE: 'courses/SET_STATE',
  LOAD_COURSES_INDEX: 'courses/LOAD_COURSES_INDEX',
  SET_FILTERS: 'courses/SET_FILTERS',
  CREATE_COURSE: 'courses/CREATE_COURSE',
  LOAD_COURSE_TYPES: 'courses/LOAD_COURSE_TYPE',
  LOAD_COURSE_TYPES_ASYNC: 'courses/LOAD_COURSE_TYPE_ASYNC',
  LOAD_SELECTED_COURSE: 'courses/LOAD_SELECTED_COURSE',
  LOAD_SELECTED_COURSE_ASYNC: 'courses/LOAD_SELECTED_COURSE_ASYNC',
  UPDATE_COURSE: 'courses/UPDATE_COURSE',
  UPDATE_COURSE_STATUS: 'courses/UPDATE_COURSE_STATUS',
  CLEAR_FILTERS: 'courses/CLEAR_FILTERS',
  LOAD_STANDARDS_INDEX: 'standards/LOAD_STANDARDS_INDEX',
}

export default actions
