const actions = {
  SET_STATE: 'notifications/SET_STATE',
  LOAD_NOTIFICATIONS_INDEX: 'notifications/LOAD_NOTIFICATIONS_INDEX',
  LOAD_RECIPIENTS: 'notifications/LOAD_RECIPIENTS',
  CLEAR_RECIPIENTS: 'notifications/CLEAR_RECIPIENTS',
  CREATE_NOTIFICATION: 'notifications/CREATE_NOTIFICATION',
  SET_FILTERS: 'notifications/SET_FILTERS',
  CLEAR_FILTERS: 'notifications/CLEAR_FILTERS',
}

export default actions
