const actions = {
  LOAD_PAYMENTS_INDEX: 'payments/LOAD_PAYMENTS_INDEX',
  SET_FILTERS: 'payments/SET_FILTERS',
  SET_STATE: 'payments/SET_STATE',
  CREATE_PAYMENTS: 'payments/CREATE_PAYMENTS',
  CREATE_REFUNDS: 'payments/CREATE_REFUNDS',
  EDIT_REFUNDS: 'payments/EDIT_REFUNDS',
  LOAD_REFUNDS: 'payments/LOAD_REFUNDS',
  CLEAR_FILTERS: 'payments/CLEAR_FILTERS',
}

export default actions
