import { notification } from 'antd'
import axiosInstance from 'utilities/configureAxios'
import route from 'utilities/route'

export default async function getAdmins(payload = {}) {
  return axiosInstance
    .get(route('admin.index'), payload)
    .then(res => res)
    .catch(error => {
      notification.warning({
        message: error.code,
        description: error.message,
      })
      return error.response
    })
}

export async function exportAdmins(payload = {}) {
  const routeValue = route('admin.export')
  return axiosInstance
    .post(routeValue, payload)
    .then(res => res)
    .catch(error => {
      notification.warning({
        message: error.code,
        description: error.message,
      })
      return error.response
    })
}

export async function getAdminDetails(id, query = {}) {
  return axiosInstance
    .get(route('admin.getById', { id }), { params: query })
    .then(res => res)
    .catch(error => {
      notification.warning({
        message: error.code,
        description: error.message,
      })
      return error.response
    })
}

export async function addAdmin(payload, query = {}) {
  return axiosInstance
    .post(route('admin.create'), payload, { params: query })
    .then(res => res)
    .catch(error => {
      notification.warning({
        message: error.code,
        description: error.message,
      })
      return error.response
    })
}

export async function editAdmin(id, payload, query = {}) {
  return axiosInstance
    .post(route('admin.edit', { id }), payload, { params: query })
    .then(res => res)
    .catch(error => {
      notification.warning({
        message: error.code,
        description: error.message,
      })
      return error.response
    })
}
export async function updateAdminStatus(id, payload, query = {}) {
  return axiosInstance
    .post(route('admin.update_status', { id }), payload, { params: query })
    .then(res => res)
    .catch(error => {
      notification.warning({
        message: error.code,
        description: error.message,
      })
      return error.response
    })
}

export async function getRoles(query = {}) {
  return axiosInstance
    .get(route('admin.roles'), { params: query })
    .then(res => res)
    .catch(error => {
      notification.warning({
        message: error.code,
        description: error.message,
      })
      return error.response
    })
}

export async function getPermissions(query = {}) {
  return axiosInstance
    .get(route('admin.permissions'), { params: query })
    .then(res => res)
    .catch(error => {
      notification.warning({
        message: error.code,
        description: error.message,
      })
      return error.response
    })
}

export async function getCurrentRole(name, query = {}) {
  return axiosInstance
    .get(route('admin.create_role', { name }), { params: query })
    .then(res => res)
    .catch(error => {
      notification.warning({
        message: error.code,
        description: error.message,
      })
      return error.response
    })
}

export async function createRole(payload, query = {}) {
  return axiosInstance
    .post(route('admin.roles'), payload, { params: query })
    .then(res => res)
    .catch(error => {
      notification.warning({
        message: error.code,
        description: error.message,
      })
      return error.response
    })
}

export async function syncRole(name, payload, query = {}) {
  return axiosInstance
    .put(route('admin.create_role', { name }), payload, { params: query })
    .then(res => res)
    .catch(error => {
      notification.warning({
        message: error.code,
        description: error.message,
      })
      return error.response
    })
}
export async function runPaymentCron() {
  return axiosInstance
    .get(route('admin.payment_cron'))
    .then(res => res)
    .catch(error => {
      notification.warning({
        message: error.code,
        description: error.message,
      })
      return error.response
    })
}

export async function runFeeDueCron() {
  return axiosInstance
    .get(route('admin.fee_due_cron'))
    .then(res => res)
    .catch(error => {
      notification.warning({
        message: error.code,
        description: error.message,
      })
      return error.response
    })
}

export async function runWalletCron() {
  return axiosInstance
    .get(route('admin.wallet_payment_cron'))
    .then(res => res)
    .catch(error => {
      notification.warning({
        message: error.code,
        description: error.message,
      })
      return error.response
    })
}
