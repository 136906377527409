import { notification } from 'antd'
import axiosInstance from 'utilities/configureAxios'
import route from 'utilities/route'

// enrollment -> by course

export async function fetchEnrollmentsCoursewise(payload = {}, isGet = false) {
  if (isGet) {
    return axiosInstance
      .get(route('reports.fetchEnrollmentsCoursewise'), payload)
      .then(res => res)
      .catch(error => {
        notification.warning({ message: error.code, description: error.message })
        return error.response
      })
  }
  return axiosInstance
    .post(route('reports.fetchEnrollmentsCoursewise'), {}, payload)
    .then(res => res)
    .catch(error => {
      notification.warning({ message: error.code, description: error.message })
      return error.response
    })
}
export async function fetchEnrollmentsCoursBatchewise(payload = {}, isGet = false) {
  if (isGet) {
    return axiosInstance
      .get(route('reports.fetchEnrollmentsCoursBatchewise'), payload)
      .then(res => res)
      .catch(error => {
        notification.warning({ message: error.code, description: error.message })
        return error.response
      })
  }
  return axiosInstance
    .post(route('reports.fetchEnrollmentsCoursBatchewise'), {}, payload)
    .then(res => res)
    .catch(error => {
      notification.warning({ message: error.code, description: error.message })
      return error.response
    })
}
export async function fetchEnrollmentsCourseTeacherBatchwise(payload = {}, isGet = false) {
  if (isGet) {
    return axiosInstance
      .get(route('reports.fetchEnrollmentsCourseTeacherBatchwise'), payload)
      .then(res => res)
      .catch(error => {
        notification.warning({ message: error.code, description: error.message })
        return error.response
      })
  }
  return axiosInstance
    .post(route('reports.fetchEnrollmentsCourseTeacherBatchwise'), {}, payload)
    .then(res => res)
    .catch(error => {
      notification.warning({ message: error.code, description: error.message })
      return error.response
    })
}
export async function fetchEnrollmentsCourseTeacherBatchStudentwise(payload = {}, isGet = false) {
  if (isGet) {
    return axiosInstance
      .get(route('reports.fetchEnrollmentsCourseTeacherBatchStudentwise'), payload)
      .then(res => res)
      .catch(error => {
        notification.warning({ message: error.code, description: error.message })
        return error.response
      })
  }
  return axiosInstance
    .post(route('reports.fetchEnrollmentsCourseTeacherBatchStudentwise'), {}, payload)
    .then(res => res)
    .catch(error => {
      notification.warning({ message: error.code, description: error.message })
      return error.response
    })
}

// enrollment -> by Teacher

export async function fetchEnrollmentsTeacherwise(payload = {}, isGet = false) {
  if (isGet) {
    return axiosInstance
      .get(route('reports.fetchEnrollmentsTeacherwise'), payload)
      .then(res => res)
      .catch(error => {
        notification.warning({ message: error.code, description: error.message })
        return error.response
      })
  }
  return axiosInstance
    .post(route('reports.fetchEnrollmentsTeacherwise'), {}, payload)
    .then(res => res)
    .catch(error => {
      notification.warning({ message: error.code, description: error.message })
      return error.response
    })
}
export async function fetchEnrollmentsTeacherCoursewise(payload = {}, isGet = false) {
  if (isGet) {
    return axiosInstance
      .get(route('reports.fetchEnrollmentsTeacherCoursewise'), payload)
      .then(res => res)
      .catch(error => {
        notification.warning({ message: error.code, description: error.message })
        return error.response
      })
  }
  return axiosInstance
    .post(route('reports.fetchEnrollmentsTeacherCoursewise'), {}, payload)
    .then(res => res)
    .catch(error => {
      notification.warning({ message: error.code, description: error.message })
      return error.response
    })
}
export async function fetchEnrollmentsTeacherCourseBatchwise(payload = {}, isGet = false) {
  if (isGet) {
    return axiosInstance
      .get(route('reports.fetchEnrollmentsTeacherCourseBatchwise'), payload)
      .then(res => res)
      .catch(error => {
        notification.warning({ message: error.code, description: error.message })
        return error.response
      })
  }
  return axiosInstance
    .post(route('reports.fetchEnrollmentsTeacherCourseBatchwise'), {}, payload)
    .then(res => res)
    .catch(error => {
      notification.warning({ message: error.code, description: error.message })
      return error.response
    })
}
export async function fetchEnrollmentsTeacherCourseBatchStudentwise(payload = {}, isGet = false) {
  if (isGet) {
    return axiosInstance
      .get(route('reports.fetchEnrollmentsTeacherCourseBatchStudentwise'), payload)
      .then(res => res)
      .catch(error => {
        notification.warning({ message: error.code, description: error.message })
        return error.response
      })
  }
  return axiosInstance
    .post(route('reports.fetchEnrollmentsTeacherCourseBatchStudentwise'), {}, payload)
    .then(res => res)
    .catch(error => {
      notification.warning({ message: error.code, description: error.message })
      return error.response
    })
}

// enrollment -> by Student

export async function fetchEnrollmentsStudentwise(payload = {}, isGet = false) {
  if (isGet) {
    return axiosInstance
      .get(route('reports.fetchEnrollmentsStudentwise'), payload)
      .then(res => res)
      .catch(error => {
        notification.warning({ message: error.code, description: error.message })
        return error.response
      })
  }
  return axiosInstance
    .post(route('reports.fetchEnrollmentsStudentwise'), {}, payload)
    .then(res => res)
    .catch(error => {
      notification.warning({ message: error.code, description: error.message })
      return error.response
    })
}
export async function fetchEnrollmentsStudentCoursewise(payload = {}, isGet = false) {
  if (isGet) {
    return axiosInstance
      .get(route('reports.fetchEnrollmentsStudentCoursewise'), payload)
      .then(res => res)
      .catch(error => {
        notification.warning({ message: error.code, description: error.message })
        return error.response
      })
  }

  return axiosInstance
    .post(route('reports.fetchEnrollmentsStudentCoursewise'), {}, payload)
    .then(res => res)
    .catch(error => {
      notification.warning({ message: error.code, description: error.message })
      return error.response
    })
}
export async function fetchEnrollmentsStudentCourseBatchwise(payload = {}, isGet = false) {
  if (isGet) {
    return axiosInstance
      .get(route('reports.fetchEnrollmentsStudentCourseBatchwise'), payload)
      .then(res => res)
      .catch(error => {
        notification.warning({ message: error.code, description: error.message })
        return error.response
      })
  }

  return axiosInstance
    .post(route('reports.fetchEnrollmentsStudentCourseBatchwise'), {}, payload)
    .then(res => res)
    .catch(error => {
      notification.warning({ message: error.code, description: error.message })
      return error.response
    })
}

//  collection -> by Course

export async function fetchCollectionsCoursewise(payload = {}, isGet = false) {
  if (isGet) {
    return axiosInstance
      .get(route('reports.fetchCollectionsCoursewise'), payload)
      .then(res => res)
      .catch(error => {
        notification.warning({ message: error.code, description: error.message })
        return error.response
      })
  }
  return axiosInstance
    .post(route('reports.fetchCollectionsCoursewise'), {}, payload)
    .then(res => res)
    .catch(error => {
      notification.warning({ message: error.code, description: error.message })
      return error.response
    })
}
export async function fetchCollectionsCourseTeacherwise(payload = {}, isGet = false) {
  if (isGet) {
    return axiosInstance
      .get(route('reports.fetchCollectionsCourseTeacherwise'), payload)
      .then(res => res)
      .catch(error => {
        notification.warning({ message: error.code, description: error.message })
        return error.response
      })
  }
  return axiosInstance
    .post(route('reports.fetchCollectionsCourseTeacherwise'), {}, payload)
    .then(res => res)
    .catch(error => {
      notification.warning({ message: error.code, description: error.message })
      return error.response
    })
}
export async function fetchCollectionsCourseTeacherBatchwise(payload = {}, isGet = false) {
  if (isGet) {
    return axiosInstance
      .get(route('reports.fetchCollectionsCourseTeacherBatchwise'), payload)
      .then(res => res)
      .catch(error => {
        notification.warning({ message: error.code, description: error.message })
        return error.response
      })
  }
  return axiosInstance
    .post(route('reports.fetchCollectionsCourseTeacherBatchwise'), {}, payload)
    .then(res => res)
    .catch(error => {
      notification.warning({ message: error.code, description: error.message })
      return error.response
    })
}
export async function fetchCollectionsCourseTeacherBatchStudentwise(payload = {}, isGet = false) {
  if (isGet) {
    return axiosInstance
      .get(route('reports.fetchCollectionsCourseTeacherBatchStudentwise'), payload)
      .then(res => res)
      .catch(error => {
        notification.warning({ message: error.code, description: error.message })
        return error.response
      })
  }
  return axiosInstance
    .post(route('reports.fetchCollectionsCourseTeacherBatchStudentwise'), {}, payload)
    .then(res => res)
    .catch(error => {
      notification.warning({ message: error.code, description: error.message })
      return error.response
    })
}

// collection -> by Teacher

export async function fetchCollectionsTeacherwise(payload = {}, isGet = false) {
  if (isGet) {
    return axiosInstance
      .get(route('reports.fetchCollectionsTeacherwise'), payload)
      .then(res => res)
      .catch(error => {
        notification.warning({ message: error.code, description: error.message })
        return error.response
      })
  }
  return axiosInstance
    .post(route('reports.fetchCollectionsTeacherwise'), {}, payload)
    .then(res => res)
    .catch(error => {
      notification.warning({ message: error.code, description: error.message })
      return error.response
    })
}
export async function fetchcollectionsTeacherCoursewise(payload = {}, isGet = false) {
  if (isGet) {
    return axiosInstance
      .get(route('reports.fetchcollectionsTeacherCoursewise'), payload)
      .then(res => res)
      .catch(error => {
        notification.warning({ message: error.code, description: error.message })
        return error.response
      })
  }
  return axiosInstance
    .post(route('reports.fetchcollectionsTeacherCoursewise'), {}, payload)
    .then(res => res)
    .catch(error => {
      notification.warning({ message: error.code, description: error.message })
      return error.response
    })
}
export async function fetchCollectionsTeacherCourseBatchwise(payload = {}, isGet = false) {
  if (isGet) {
    return axiosInstance
      .get(route('reports.fetchCollectionsTeacherCourseBatchwise'), payload)
      .then(res => res)
      .catch(error => {
        notification.warning({ message: error.code, description: error.message })
        return error.response
      })
  }
  return axiosInstance
    .post(route('reports.fetchCollectionsTeacherCourseBatchwise'), {}, payload)
    .then(res => res)
    .catch(error => {
      notification.warning({ message: error.code, description: error.message })
      return error.response
    })
}
export async function fetchCollectionsTeacherCourseBatchStudentwise(payload = {}, isGet = false) {
  if (isGet) {
    return axiosInstance
      .get(route('reports.fetchCollectionsTeacherCourseBatchStudentwise'), payload)
      .then(res => res)
      .catch(error => {
        notification.warning({ message: error.code, description: error.message })
        return error.response
      })
  }
  return axiosInstance
    .post(route('reports.fetchCollectionsTeacherCourseBatchStudentwise'), {}, payload)
    .then(res => res)
    .catch(error => {
      notification.warning({ message: error.code, description: error.message })
      return error.response
    })
}

// collection -> by Student fetchCollectionsStudentwise
export async function fetchCollectionsStudentwise(payload = {}, isGet = false) {
  if (isGet) {
    return axiosInstance
      .get(route('reports.fetchCollectionsStudentwise'), payload)
      .then(res => res)
      .catch(error => {
        notification.warning({ message: error.code, description: error.message })
        return error.response
      })
  }
  return axiosInstance
    .post(route('reports.fetchCollectionsStudentwise'), {}, payload)
    .then(res => res)
    .catch(error => {
      notification.warning({ message: error.code, description: error.message })
      return error.response
    })
}
export async function fetchCollectionsStudentCoursewise(payload = {}, isGet = false) {
  if (isGet) {
    return axiosInstance
      .get(route('reports.fetchCollectionsStudentCoursewise'), payload)
      .then(res => res)
      .catch(error => {
        notification.warning({ message: error.code, description: error.message })
        return error.response
      })
  }

  return axiosInstance
    .post(route('reports.fetchCollectionsStudentCoursewise'), {}, payload)
    .then(res => res)
    .catch(error => {
      notification.warning({ message: error.code, description: error.message })
      return error.response
    })
}
export async function fetchCollectionsStudentCourseBatchwise(payload = {}, isGet = false) {
  if (isGet) {
    return axiosInstance
      .get(route('reports.fetchCollectionsStudentCourseBatchwise'), payload)
      .then(res => res)
      .catch(error => {
        notification.warning({ message: error.code, description: error.message })
        return error.response
      })
  }

  return axiosInstance
    .post(route('reports.fetchCollectionsStudentCourseBatchwise'), {}, payload)
    .then(res => res)
    .catch(error => {
      notification.warning({ message: error.code, description: error.message })
      return error.response
    })
}

// teacher

export async function fetchTotalByCourse(payload = {}, isGet = false) {
  if (isGet) {
    return axiosInstance
      .get(route('reports.fetchTotalByCourse'), payload)
      .then(res => res)
      .catch(error => {
        notification.warning({ message: error.code, description: error.message })
        return error.response
      })
  }
  return axiosInstance
    .post(route('reports.fetchTotalByCourse'), {}, payload)
    .then(res => res)
    .catch(error => {
      notification.warning({ message: error.code, description: error.message })
      return error.response
    })
}
export async function fetchTotalByCourseDetails(payload = {}, isGet = false) {
  if (isGet) {
    return axiosInstance
      .get(route('reports.fetchTotalByCourseDetails'), payload)
      .then(res => res)
      .catch(error => {
        notification.warning({ message: error.code, description: error.message })
        return error.response
      })
  }
  return axiosInstance
    .post(route('reports.fetchTotalByCourseDetails'), {}, payload)
    .then(res => res)
    .catch(error => {
      notification.warning({ message: error.code, description: error.message })
      return error.response
    })
}

export async function exportOrganicReportApi(payload = {}, extraParams = {}) {
  return axiosInstance
    .post(route('reports.organicReport'), payload, extraParams)
    .then(res => res)
    .catch(error => {
      notification.warning({ message: error.code, description: error.message })
      return error.response
    })
}

export async function exportDetailedOrganicReportApi(payload = {}, extraParams = {}) {
  return axiosInstance
    .post(route('reports.detailedOrganicReport'), payload, extraParams)
    .then(res => res)
    .catch(error => {
      notification.warning({ message: error.code, description: error.message })
      return error.response
    })
}

export async function fetchDashboardMetrics(payload = {}) {
  return axiosInstance
    .get(route('reports.dashboardMetrics'), payload)
    .then(res => res)
    .catch(error => {
      notification.warning({ message: error.code, description: error.message })
      return error.response
    })
}

// teacher reports export

// export async function fetchCollectionsTeacherwise(payload = {}, isGet = false) {
//   if (isGet) {
//     return axiosInstance
//       .get(route('reports.fetchCollectionsTeacherwise'), payload)
//       .then(res => res)
//       .catch(error => {
//         notification.warning({ message: error.code, description: error.message })
//         return error.response
//       })
//   }

//   return axiosInstance
//     .post(route('reports.fetchCollectionsTeacherwise'), payload)
//     .then(res => res)
//     .catch(error => {
//       notification.warning({ message: error.code, description: error.message })
//       return error.response
//     })
// }

// student report export

// export async function fetchCollectionsStudentwise(payload = {}, isGet = false) {
//   if (isGet) {
//     return axiosInstance
//       .get(route('reports.fetchCollectionsStudentwise'), payload)
//       .then(res => res)
//       .catch(error => {
//         notification.warning({ message: error.code, description: error.message })
//         return error.response
//       })
//   }

//   return axiosInstance
//     .post(route('reports.fetchCollectionsStudentwise'), payload)
//     .then(res => res)
//     .catch(error => {
//       notification.warning({ message: error.code, description: error.message })
//       return error.response
//     })
// }

// ledger reconciliation

//

// /api/v2/admin/prev-year-enrolled-students-which-is-not-enrolled-this-year?start_date=2022-09-04&end_date=2022-09-05

export async function prevYearEnrolledStudentWhichIsNotEnrolledThisYear(payload = {}) {
  return axiosInstance
    .get(route('reports.prevYearEnrolledStudentWhichIsNotEnrolledThisYear'), payload)
    .then(res => res)
    .catch(error => {
      notification.warning({
        message: error.code,
        description: error.message,
      })
      return error.response
    })
}
