import actions from './actions'

const initialState = {
  loading: false,
  index: [],
  pagination: null,
  current: {},
  modalType: '',
  filters: {
    page: 1,
    per_page: 15,
  },
}

export default function batchLabels(state = initialState, action) {
  switch (action.type) {
    case actions.SET_FILTERS:
      return {
        ...state,
        filters: {
          ...state.filters,
          ...action.payload,
        },
      }

    case actions.SET_STATE:
      return { ...state, ...action.payload }

    case actions.LOAD_CURRENT_BATCH_LABELS:
      return { ...state, current: action.payload }

    case actions.CLEAR_FILTERS:
      return { ...state, filters: {} }

    case actions.HIDE_MODAL:
      return { ...state, modalType: '' }

    default:
      return state
  }
}
