import { all, takeEvery, put, call } from 'redux-saga/effects'
import fetchCoursesIndex, {
  createCourse,
  getCourseTypes,
  getCurrentCourse,
  updateCourse,
} from 'services/courses'
import { push } from 'react-router-redux'
import { get } from 'lodash'
import { notification } from 'antd'
import actions from './actions'
import errorActions from '../error/actions'

export function* LOAD_COURSES_INDEX(action) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })

  const response = yield call(fetchCoursesIndex, action.payload)

  if (response) {
    if (response.data.success) {
      yield put({
        type: actions.SET_STATE,
        payload: {
          index: response.data.data,
          pagination: get(response, 'data.meta.pagination', null),
        },
      })
    } else {
      yield put({
        type: errorActions.SET_STATE,
        payload: response.data,
      })
    }
  }
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: false,
    },
  })
}

export function* WATCH_CREATE_COURSE(action) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })

  const response = yield call(createCourse, action.payload)

  if (response) {
    if (response.data.success) {
      yield put(push('/courses'))
      yield put({
        type: errorActions.SET_STATE,
        payload: { errors: {} },
      })
    } else if (response.data.code === 422) {
      yield put({
        type: errorActions.SET_STATE,
        payload: response.data,
      })
    }
  }
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: false,
    },
  })
}

export function* WATCH_LOAD_COURSE_TYPES(action) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })

  const response = yield call(getCourseTypes, action.payload)

  if (response) {
    if (response.data.success) {
      yield put({
        type: actions.LOAD_COURSE_TYPES_ASYNC,
        payload: response.data.data,
      })
    } else if (response.data.code === 422) {
      yield put({
        type: errorActions.SET_STATE,
        payload: response.data,
      })
    }
  }
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: false,
    },
  })
}

export function* WATCH_LOAD_SELECTED_COURSE(action) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })

  const response = yield call(getCurrentCourse, action.payload.id, action.payload)

  if (response) {
    if (response.data.success) {
      yield put({
        type: actions.LOAD_SELECTED_COURSE_ASYNC,
        payload: response.data.data,
      })
    } else if (response.data.code === 422) {
      yield put({
        type: errorActions.SET_STATE,
        payload: response.data,
      })
    }
  }
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: false,
    },
  })
}

export function* WATCH_UPDATE_COURSE(action) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })

  const response = yield call(updateCourse, action.payload.id, action.payload.data)
  if (response) {
    if (response.data.success) {
      yield put(push('/courses'))
      yield put({
        type: errorActions.SET_STATE,
        payload: { errors: {} },
      })
    } else if (response.data.code === 422) {
      yield put({
        type: errorActions.SET_STATE,
        payload: response.data,
      })
    }
  }
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: false,
    },
  })
}

export function* WATCH_UPDATE_COURSE_STATUS(action) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })

  const response = yield call(updateCourse, action.payload.id, action.payload.data)
  if (response) {
    if (response.data.success) {
      yield put({
        type: actions.LOAD_COURSES_INDEX,
        payload: { params: action.payload.filters },
      })
      notification.success({
        message: 'Success !',
        description: 'Status Changed Success',
      })
    } else if (response.data.code === 422) {
      yield put({
        type: errorActions.SET_STATE,
        payload: response.data,
      })
      notification.warning({
        message: 'Failure !',
        description: response.data.errors.status[0] ?? 'Status Cannot Be Changed',
      })
    }
  }
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: false,
    },
  })
}

export default function* rootSaga() {
  yield all([takeEvery(actions.LOAD_COURSES_INDEX, LOAD_COURSES_INDEX)])
  yield all([takeEvery(actions.CREATE_COURSE, WATCH_CREATE_COURSE)])
  yield all([takeEvery(actions.LOAD_COURSE_TYPES, WATCH_LOAD_COURSE_TYPES)])
  yield all([takeEvery(actions.LOAD_SELECTED_COURSE, WATCH_LOAD_SELECTED_COURSE)])
  yield all([takeEvery(actions.UPDATE_COURSE, WATCH_UPDATE_COURSE)])
  yield all([takeEvery(actions.UPDATE_COURSE_STATUS, WATCH_UPDATE_COURSE_STATUS)])
}
