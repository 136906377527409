import { notification } from 'antd'
import axiosInstance from 'utilities/configureAxios'
import route from 'utilities/route'

export default async function Login(payload = {}) {
  return axiosInstance
    .post(route('auth.login'), payload)
    .then(res => res)
    .catch(error => {
      notification.warning({
        message: error.code,
        description: error.message,
      })
      return error.response
    })
}

export async function Logout(payload = {}) {
  return axiosInstance
    .post(route('auth.logout'), payload)
    .then(res => res)
    .catch(error => {
      notification.warning({
        message: error.code,
        description: error.message,
      })
      return error.response
    })
}

export async function resetLink(payload = {}) {
  return axiosInstance
    .post(route('auth.reset_link'), payload)
    .then(res => res)
    .catch(error => {
      notification.warning({
        message: error.code,
        description: error.message,
      })
      return error.response
    })
}

export async function resetPassword(payload = {}) {
  return axiosInstance
    .post(route('auth.reset_password'), payload)
    .then(res => res)
    .catch(error => {
      notification.warning({
        message: error.code,
        description: error.message,
      })
      return error.response
    })
}
