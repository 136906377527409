import { notification } from 'antd'
import axiosInstance from 'utilities/configureAxios'
import route from 'utilities/route'
import { get } from 'lodash'

export default async function getFeedbacks(payload = {}) {
  return axiosInstance
    .get(route('feedbacks.index'), payload)
    .then(res => res)
    .catch(error => {
      notification.warning({
        message: error.code,
        description: error.message,
      })
      return error.response
    })
}

export async function getCurrentFeedback(id, payload = {}) {
  const url = route('feedbacks.current_feedback', { id })
  return axiosInstance
    .get(url, { params: get(payload, 'params', {}) })
    .then(res => res)
    .catch(error => {
      notification.warning({
        message: error.code,
        description: error.message,
      })
      return error.response
    })
}

export async function createFeedback(payload = {}) {
  return axiosInstance
    .post(route('feedbacks.index'), payload)
    .then(res => res)
    .catch(error => {
      notification.warning({
        message: error.code,
        description: error.message,
      })
      return error.response
    })
}

export async function updateFeedback(id, payload = {}) {
  const url = route('feedbacks.current_feedback', { id })
  return axiosInstance
    .patch(url, payload)
    .then(res => res)
    .catch(error => {
      notification.warning({
        message: error.code,
        description: error.message,
      })
      return error.response
    })
}

export async function exportFeedbacks(payload = {}, extraParams = {}) {
  const routeValue = route('feedbacks.export')
  return axiosInstance
    .post(routeValue, payload, extraParams)
    .then(res => res)
    .catch(error => {
      notification.warning({
        message: error.code,
        description: error.message,
      })
      return error.response
    })
}
