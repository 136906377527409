import { all, takeEvery, put, call } from 'redux-saga/effects'
import fetchConfiguarationsIndex, {
  generateTeachersOTP,
  updateConfiguration,
} from 'services/configurations'
import { notification } from 'antd'
import actions from './actions'
import errorActions from '../error/actions'

export function* WATCH_LOAD_COFIGURATION_INDEX(action) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })

  const response = yield call(fetchConfiguarationsIndex, action.payload)
  if (response) {
    if (response.data.success) {
      yield put({
        type: actions.SET_STATE,
        payload: {
          index: response.data.data,
        },
      })
    } else {
      yield put({
        type: errorActions.SET_STATE,
        payload: response.data,
      })
    }
  }
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: false,
    },
  })
}

export function* WATCH_UPDATE_CONFIGUARATION(action) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })
  const response = yield call(updateConfiguration, action.payload)
  if (response) {
    if (response.data.success) {
      yield put({
        type: errorActions.SET_STATE,
        payload: { errors: {} },
      })
      yield put({
        type: actions.SET_STATE,
        payload: { editModalVisible: { visible: false, configuration: null } },
      })
    } else if (response.data.code === 422) {
      yield put({
        type: errorActions.SET_STATE,
        payload: response.data,
      })
    }
  }
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: false,
    },
  })
}

export function* WATCH_TEACHERS_APP(action) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })

  const response = yield call(generateTeachersOTP, action.payload)
  if (response) {
    if (response.data.success) {
      yield put({ type: actions.LOAD_CONFIGURATIONS_INDEX })
      notification.success({
        message: 'OTP Generated Successfully!',
      })
    } else {
      yield put({
        type: errorActions.SET_STATE,
        payload: response.data,
      })
    }
  }

  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: false,
    },
  })
}

export default function* rootSaga() {
  yield all([takeEvery(actions.LOAD_CONFIGURATIONS_INDEX, WATCH_LOAD_COFIGURATION_INDEX)])
  yield all([takeEvery(actions.UPDATE_CONFIGURATION, WATCH_UPDATE_CONFIGUARATION)])
  yield all([takeEvery(actions.GENERATE_TEACHERS_OTP, WATCH_TEACHERS_APP)])
}
