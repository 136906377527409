const actions = {
  SET_STATE: 'enrollments/SET_STATE',
  LOAD_ENROLLMENTS_INDEX: 'enrollments/LOAD_ENROLLMENTS_INDEX',
  LOAD_CURRENT_ENROLLMENT: 'enrollments/LOAD_CURRENT_ENROLLMENT',
  LOAD_ENROLLMENT_COUNT: 'enrollments/LOAD_ENROLLMENT_COUNT',
  LOAD_CURRENT_ENROLLMENT_ASYNC: 'enrollments/LOAD_CURRENT_ENROLLMENT_ASYNC',
  SET_FILTERS: 'enrollments/SET_FILTERS',
  CLEAR_FILTERS: 'enrollments/CLEAR_FILTERS',
  LOAD_ENROLLMENT_BY_ORDER: 'enrollments/LOAD_ENROLLMENT_BY_ORDER',
  CREATE_ENROLLMENT: 'enrollments/CREATE_ENROLLMENT',
  CANCEL_ENROLLMENT: 'enrollments/CANCEL_ENROLLMENT',
  UPDATE_TRIAL_ENROLLMENT_TYPE: 'enrollments/UPDATE_TRIAL_ENROLLMENT_TYPE',
  UPDATE_ENROLLMENT: 'enrollments/UPDATE_ENROLLMENT',
  UPDATE_COMMISSION: 'enrollments/UPDATE_COMMISSION',
  LOAD_ENROLLMENT_ATTENDANCE: 'enrollments/LOAD_ENROLLMENT_ATTENDANCE',
  LOAD_UPDATE_ATTENDANCE_STATUS: 'enrollments/LOAD_UPDATE_ATTENDANCE_STATUS',
  SHOW_MODAL: 'enrollments/SHOW_MODAL',
  SHOW_TRANSFER_MODAL: 'enrollments/SHOW_TRANSFER_MODAL',
  TRANSFER_ENROLLMENT: 'enrollments/TRANSFER_ENROLLMENTS',
  SET_CLASS_FEE_MODAL: 'enrollments/SET_CLASS_FEE_MODAL',
  SET_INSTALLMENT_FEE_MODAL: 'enrollments/SET_INSTALLMENT_FEE_MODAL',
  SET_ENROLLMENT_MODAL: 'enrollments/SET_ENROLLMENT_MODAL',
  ADJUST_CLASS_FEES: 'enrollments/ADJUST_CLASS_FEES',
  PRINT_RECEIPT: 'enrollments/PRINT_RECEIPT',
  GET_CERTIFICATE: 'enrollments/GET_CERTIFICATE',
  CANCELLATION_REASONS: 'enrollments/CANCELLATION_REASONS',
  CANCELLATION_REASONS_SUCCESS: 'counselor/CANCELLATION_REASONS_SUCCESS',
  EDIT_CLASS_FEE: 'enrollments/EDIT_CLASS_FEE',
  MARK_CLASS_LEAVE: 'enrollments/MARK_CLASS_LEAVE',
}

export default actions
