import { notification } from 'antd'
import axiosInstance from 'utilities/configureAxios'
import route from 'utilities/route'

export async function bookTrialClassApi(payload) {
  console.log('hi')
  return axiosInstance
    .post(route('counselorPayment.bookTrial'), payload)
    .then(res => res)
    .catch(error => {
      notification.warning({
        message: error.code,
        description: error.message,
      })
      return error.response
    })
}

export async function getCouponForParent(id) {
  const url = `${route('counselorPayment.parentCoupons') + id}/coupons`
  return axiosInstance
    .get(url)
    .then(res => res)
    .catch(error => {
      notification.warning({
        message: error.code,
        description: error.message,
      })
      return error.response
    })
}

export async function toggleCouponApi(orderId, couponId) {
  const url = `${route('counselorPayment.toggleCoupon') + orderId}/toggleCoupons/${couponId}`
  return axiosInstance
    .post(url)
    .then(res => res)
    .catch(error => {
      notification.warning({
        message: error.code,
        description: error.message,
      })
      return error.response
    })
}

export async function applyCouponApi(orderId, couponId, payload, isWallet) {
  const url = `${route('counselorPayment.applyCoupon') + orderId}/coupons/${couponId}`
  return axiosInstance
    .post(url, { ...payload, is_wallet: isWallet })
    .then(res => res)
    .catch(error => {
      notification.warning({
        message: error.code,
        description: error.message,
      })
      return error.response
    })
}

export async function additionalDiscountApi(orderId, payload, isWallet) {
  const url = `${route('counselorPayment.additionalDiscountOnly') +
    orderId}/additional-discount/apply`
  return axiosInstance
    .post(url, { ...payload, is_wallet: isWallet })
    .then(res => res)
    .catch(error => {
      notification.warning({
        message: error.code,
        description: error.message,
      })
      return error.response
    })
}
