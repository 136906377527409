import { notification } from 'antd'
import axiosInstance from 'utilities/configureAxios'
import route from 'utilities/route'

export default async function getClasses(payload = {}) {
  return axiosInstance
    .get(route('classes.index'), payload)
    .then(res => res)
    .catch(error => {
      notification.warning({
        message: error.code,
        description: error.message,
      })
      return error.response
    })
}

export async function getClass(payload = {}) {
  const routeValue = `${route('classes.index')}/${payload.id}`
  return axiosInstance
    .get(routeValue, {
      params: payload.params,
    })
    .then(res => res)
    .catch(error => {
      notification.warning({
        message: error.code,
        description: error.message,
      })
      return error.response
    })
}

export async function createClassUpdate(id, data = new FormData()) {
  return axiosInstance
    .post(route('classes.class_update', { id }), data)
    .then(res => res)
    .catch(error => {
      notification.warning({
        message: error.code,
        description: error.message,
      })
      return error.response
    })
}

// class legder

export async function createClassLedger(id, payload = {}) {
  return axiosInstance
    .post(route('classes.class_ledger', { id }), payload)
    .then(res => {
      if (res?.status === 200) {
        notification.success({
          message: 'Ledger created successfully',
        })
      }
      // return res
    })
    .catch(error => {
      notification.warning({
        message: error.code,
        description: error.message,
      })
      // return error.response
    })
}

export async function markClassAttendence(id, data = {}) {
  return axiosInstance
    .post(route('classes.mark_class_attendence', { id }), data)
    .then(res => res)
    .catch(error => {
      notification.warning({
        message: error.code,
        description: error.message,
      })
      return error.response
    })
}

export async function markClassHoliday(id, data = {}) {
  return axiosInstance
    .post(route('classes.mark_holiday', { id }), data)
    .then(res => res)
    .catch(error => {
      notification.warning({
        message: error.code,
        description: error.message,
      })

      return error.response
    })
}

export async function exportClasses(payload = {}, extraParams = {}) {
  const routeValue = route('classes.export')
  return axiosInstance
    .post(routeValue, payload, extraParams)
    .then(res => res)
    .catch(error => {
      notification.warning({
        message: error.code,
        description: error.message,
      })
      return error.response
    })
}
