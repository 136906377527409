const actions = {
  SET_STATE: 'centers/SET_STATE',
  LOAD_CENTERS_INDEX: 'centers/LOAD_CENTERS_INDEX',
  SET_FILTERS: 'centers/SET_FILTERS',
  CLEAR_FILTERS: 'centers/CLEAR_FILTERS',
  UPDATE_STATUS: 'centers/UPDATE_STATUS',
  UPDATE_CENTER: 'centers/UPDATE_CENTER',
  LOAD_SELECTED_CENTER: 'centers/LOAD_SELECTED_CENTER',
  CREATE_CENTER: 'centers/CREATE_CENTER',
}

export default actions
