const actions = {
  LOAD_TEACHERS_INDEX: 'teachers/LOAD_TEACHERS_INDEX',
  SET_FILTERS: 'teachers/SET_FILTERS',
  SET_STATE: 'teachers/SET_STATE',
  LOAD_CURRENT_TEACHER: 'teachers/LOAD_CURRENT_TEACHER',
  LOAD_CURRENT_TEACHER_ASYNC: 'teachers/LOAD_CURRENT_TEACHER_ASYNC',
  CREATE_TEACHER: 'teachers/CREATE_TEACHER',
  UPDATE_TEACHER: 'teachers/UPDATE_TEACHER',
  CLEAR_FILTERS: 'teachers/CLEAR_FILTERS',
  UPDATE_STATUS: 'teachers/UPDATE_STATUS',
  CLEAR_CURRENT: 'teachers/CLEAR_CURRENT',
  CALENDAR_EVENTS: 'teachers/CALENDAR_EVENTS',
}

export default actions
