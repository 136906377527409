const actions = {
  SET_STATE: 'location/SET_STATE',
  LOAD_LOCATIONS_INDEX: 'location/LOAD_LOCATION_INDEX',
  SET_FILTERS: 'location/SET_FILTERS',
  CREATE_LOCATION: 'cities/CREATE_LOCATION',
  UPDATE_LOCATION: 'cities/UPDATE_LOCATION',
  CLEAR_FILTERS: 'cities/CLEAR_FILTERS',
}

export default actions
