import { all, takeEvery, put, call } from 'redux-saga/effects'
import getLocations, { createLocation, updateCurrentLocation } from 'services/locations'
// import { push } from 'react-router-redux'
import actions from './actions'
import errorActions from '../error/actions'

export function* WATCH_LOAD_LOCATIONS_INDEX(action) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })

  const response = yield call(getLocations, action.payload)

  if (response) {
    if (response.data.success) {
      yield put({
        type: actions.SET_STATE,
        payload: {
          index: response.data.data,
          pagination: response.data.meta.pagination,
        },
      })
    } else {
      yield put({
        type: errorActions.SET_STATE,
        payload: response.data,
      })
    }
  }
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: false,
    },
  })
}

export function* WATCH_CREATE_LOCATION(action) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })

  const response = yield call(createLocation, action.payload)

  if (response) {
    if (response.data.success) {
      yield put({
        type: actions.SET_STATE,
        payload: {
          showmodal: false,
        },
      })
      yield put({
        type: actions.LOAD_LOCATIONS_INDEX,
        payload: {
          params: {
            includes: 'city,zones',
          },
        },
      })
    } else {
      yield put({
        type: errorActions.SET_STATE,
        payload: response.data,
      })
    }
  }
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: false,
    },
  })
}

export function* WATCH_UPDATE_LOCATION(action) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })

  const response = yield call(updateCurrentLocation, action.payload.id, action.payload.data)

  if (response) {
    if (response.data.success) {
      yield put({
        type: actions.SET_STATE,
        payload: {
          showmodal: false,
          current: {},
        },
      })
      yield put({
        type: actions.LOAD_LOCATIONS_INDEX,
        payload: {
          params: {
            includes: 'city,zones',
          },
        },
      })
    } else {
      yield put({
        type: errorActions.SET_STATE,
        payload: response.data,
      })
    }
  }
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: false,
    },
  })
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.LOAD_LOCATIONS_INDEX, WATCH_LOAD_LOCATIONS_INDEX),
    takeEvery(actions.CREATE_LOCATION, WATCH_CREATE_LOCATION),
    takeEvery(actions.UPDATE_LOCATION, WATCH_UPDATE_LOCATION),
  ])
}
