import { all, takeEvery, put, call } from 'redux-saga/effects'
import {
  bookTrialClassApi,
  getCouponForParent,
  toggleCouponApi,
  applyCouponApi,
  additionalDiscountApi,
} from 'services/counselorPayment'
import { notification } from 'antd'
import { history } from 'index'
import actions from './actions'
import counselorActions from '../counselor/actions'
import errorActions from '../error/actions'

export function* bookTrialClassSaga(action) {
  yield put({
    type: actions.SET_LOADING,
    payload: {
      loading: true,
    },
  })

  const response = yield call(bookTrialClassApi, action.payload)
  if (response) {
    if (response.data.success) {
      yield put({
        type: counselorActions.MODAL_CLOSE,
        payload: {
          modalVisible: '',
        },
      })
      yield put({
        type: actions.CLEAR_TRIAL_DETAILS,
      })
      notification.success({
        message: 'Trial Class Booked Successfully!',
      })
      setTimeout(() => {
        history.push('/counsellor/dashboard/home')
      }, 1000)
    } else {
      notification.error({
        message: 'Error!',
        description: Object.values(
          `Joining Date : ${response.data.errors?.joining_date?.join(', ')}` ?? response.statusText,
        ),
      })
      yield put({
        type: errorActions.SET_STATE,
        payload: response.data,
      })
    }
  }
  yield put({
    type: actions.SET_LOADING,
    payload: {
      loading: false,
    },
  })
}

export function* checkoutSaga(action) {
  yield put({
    type: actions.CHECKOUT_LOADING,
    payload: {
      checkoutLoading: true,
    },
  })

  console.log('action', action)

  const response = yield call(bookTrialClassApi, action.payload)
  if (response) {
    if (response.data.success) {
      yield put({
        type: actions.CHECKOUT_SUCCESS,
        payload: response.data.data,
      })
      history.push('/counsellor/cart')
    } else {
      yield put({
        type: errorActions.SET_STATE,
        payload: response.data,
      })
    }
  }
  yield put({
    type: actions.CHECKOUT_LOADING,
    payload: {
      checkoutLoading: false,
    },
  })
}

export function* addCartSaga(action) {
  yield put({
    type: actions.SET_LOADING,
    payload: {
      loading: true,
    },
  })

  const response = yield call(bookTrialClassApi, action.payload)
  if (response) {
    if (response.data.success) {
      history.push('/counsellor/dashboard/home')
      notification.success({
        message: `Item has been added to parent${'`'}s cart!`,
      })
    } else {
      yield put({
        type: errorActions.SET_STATE,
        payload: response.data,
      })
    }
  }
  yield put({
    type: actions.SET_LOADING,
    payload: {
      loading: false,
    },
  })
}

export function* parentsCouponSaga(action) {
  yield put({
    type: actions.SET_LOADING,
    payload: {
      loading: true,
    },
  })

  const response = yield call(getCouponForParent, action.payload)
  if (response) {
    if (response.data.success) {
      yield put({
        type: actions.PARENTS_COUPONS_SUCCSESS,
        payload: {
          parentsCoupons: response.data.data,
          applyCouponModal: true,
        },
      })
    } else {
      yield put({
        type: errorActions.SET_STATE,
        payload: response.data,
      })
    }
  }
  yield put({
    type: actions.SET_LOADING,
    payload: {
      loading: false,
    },
  })
}

export function* toggleCouponSaga(action) {
  yield put({
    type: actions.CHECKOUT_LOADING,
    payload: {
      checkoutLoading: true,
    },
  })

  const response = yield call(toggleCouponApi, action.payload.orderId, action.payload.couponId)
  if (response) {
    if (response.data.success) {
      yield put({
        type: actions.TOGGLE_COUPON_SUCCESS,
        payload: {
          toggleCouponDetails: response.data.data,
          applyCouponModal: false,
        },
      })
      notification.success({
        message: 'Coupon Applied Successfully.',
      })
    } else {
      yield put({
        type: errorActions.SET_STATE,
        payload: response.data,
      })
    }
  }
  yield put({
    type: actions.CHECKOUT_LOADING,
    payload: {
      checkoutLoading: false,
    },
  })
}

export function* applyCouponSaga(action) {
  yield put({
    type: actions.CHECKOUT_LOADING,
    payload: {
      checkoutLoading: true,
    },
  })

  const response = yield call(
    applyCouponApi,
    action.payload.orderId,
    action.payload.couponId,
    action.payload.data,
    // action.payload.is_wallet,
  )
  if (response) {
    if (response.data.success) {
      yield put({
        type: counselorActions.PAYMENT_LINK,
        payload: {
          orderId: response.data.data.id,
          is_wallet: action.payload.is_wallet,
        },
      })
    } else {
      yield put({
        type: errorActions.SET_STATE,
        payload: response.data,
      })
    }
  }
  yield put({
    type: actions.CHECKOUT_LOADING,
    payload: {
      checkoutLoading: false,
    },
  })
}

export function* additionalDiscountSaga(action) {
  yield put({
    type: actions.CHECKOUT_LOADING,
    payload: {
      checkoutLoading: true,
    },
  })

  const response = yield call(
    additionalDiscountApi,
    action.payload.orderId,
    action.payload.data,
    action.payload.is_wallet,
  )
  if (response) {
    if (response.data.success) {
      yield put({
        type: counselorActions.PAYMENT_LINK,
        payload: {
          orderId: response.data.data.id,
          is_wallet: action.payload.is_wallet,
        },
      })
    } else {
      yield put({
        type: errorActions.SET_STATE,
        payload: response.data,
      })
    }
  }
  yield put({
    type: actions.CHECKOUT_LOADING,
    payload: {
      checkoutLoading: false,
    },
  })
}

export default function* rootSaga() {
  yield all([takeEvery(actions.BOOK_TRIAL_CLASS, bookTrialClassSaga)])
  yield all([takeEvery(actions.ADD_CART, addCartSaga)])
  yield all([takeEvery(actions.CHECKOUT, checkoutSaga)])
  yield all([takeEvery(actions.PARENTS_COUPONS, parentsCouponSaga)])
  yield all([takeEvery(actions.TOGGLE_COUPON, toggleCouponSaga)])
  yield all([takeEvery(actions.APPLY_COUPON, applyCouponSaga)])
  yield all([takeEvery(actions.ADDITIONAL_DISCOUNT, additionalDiscountSaga)])
}
