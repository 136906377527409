const actions = {
  SET_STATE: 'zones/SET_STATE',
  LOAD_ZONES_INDEX: 'zones/LOAD_ZONES_INDEX',
  SET_FILTERS: 'zones/SET_FILTERS',
  CREATE_ZONE: 'zones/CREATE_ZONE',
  UPDATE_ZONE: 'zones/UPDATE_ZONE',
  SET_ADD_MODAL_VISIBLE: 'zones/SET_ADD_MODAL_VISIBLE',
  SET_EDIT_MODAL_VISIBLE: 'zones/SET_EDIT_MODAL_VISIBLE',
}

export default actions
