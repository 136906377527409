import { notification } from 'antd'
import axiosInstance from 'utilities/configureAxios'
import route from 'utilities/route'

export default async function fetchConfiguarationsIndex(payload = {}) {
  return axiosInstance
    .get(route('configurations.index'), payload)
    .then(res => res)
    .catch(error => {
      notification.warning({
        message: error.code,
        description: error.message,
      })
      return error.response
    })
}

export async function updateConfiguration(payload) {
  return axiosInstance
    .put(route('configurations.edit'), payload)
    .then(res => res)
    .catch(error => {
      notification.warning({
        message: error.code,
        description: error.message,
      })
      return error.response
    })
}

export async function generateTeachersOTP(payload) {
  return axiosInstance
    .post(route('configurations.generate_secret_otp'), payload)
    .then(res => res)
    .catch(error => {
      notification.warning({
        message: error.code,
        description: error.message,
      })
      return error.response
    })
}
