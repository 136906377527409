import React from 'react'
import { Route } from 'react-router-dom'
import { ConnectedRouter } from 'connected-react-router'
import { CSSTransition, SwitchTransition } from 'react-transition-group'
import Switch from 'react-router-transition-switch'
import Loadable from 'react-loadable'
import { connect } from 'react-redux'

import Layout from 'layouts'
import Loader from 'components/layout/Loader'
import NotFoundPage from 'pages/system/404'
import pathConfig from 'config/paths'

import ProtectedRoute from './protectedroute'

const loadable = loader =>
  Loadable({
    loader,
    delay: false,
    loading: () => <Loader />,
  })

const routes = [
  {
    path: '/',
    Component: loadable(() => import('pages/system/login')),
    exact: true,
    isprotected: false,
  },

  {
    path: '/dashboard/home',
    Component: loadable(() => import('pages/dashboard/home')),
    exact: true,
    isprotected: true,
  },
  {
    path: '/counsellor/dashboard/home',
    Component: loadable(() => import('pages/counsellorDashboard')),
    exact: true,
    isprotected: true,
  },
  {
    path: '/counsellor/add-lead',
    Component: loadable(() => import('pages/counsellorDashboard/addLead')),
    exact: true,
    isprotected: true,
  },
  {
    path: '/counsellor/select-course',
    Component: loadable(() => import('pages/counsellorDashboard/SelectCourse')),
    exact: true,
    isprotected: true,
  },
  {
    path: '/counsellor/select-course/course-details/:id/:stdId',
    Component: loadable(() => import('pages/counsellorDashboard/CourseDetails')),
    exact: true,
    isprotected: true,
  },
  {
    path: '/counsellor/payment-plans/:id',
    Component: loadable(() => import('pages/counsellorDashboard/PaymentPlans')),
    exact: true,
    isprotected: true,
  },
  {
    path: '/counsellor/cart',
    Component: loadable(() => import('pages/counsellorDashboard/Cart')),
    exact: true,
    isprotected: true,
  },

  // System Pages

  {
    path: '/forgotpassword',
    Component: loadable(() => import('pages/system/forgot-password')),
    exact: true,
    isprotected: false,
  },
  {
    path: '/forgot-password',
    Component: loadable(() => import('pages/system/forgot-password/reset')),
    exact: true,
    isprotected: false,
  },
  {
    path: '/courses',
    Component: loadable(() => import('pages/courses')),
    exact: true,
    isprotected: true,
  },
  {
    path: '/courses/add',
    Component: loadable(() => import('pages/courses/create')),
    exact: true,
    isprotected: true,
  },
  {
    path: pathConfig.course.edit,
    Component: loadable(() => import('pages/courses/edit')),
    exact: true,
    isprotected: true,
  },
  {
    path: '/batches',
    Component: loadable(() => import('pages/batches')),
    exact: true,
    isprotected: true,
  },
  {
    path: '/batches/add',
    Component: loadable(() => import('pages/batches/create')),
    exact: true,
    isprotected: true,
  },
  {
    path: '/apps/ParentTrainingPrograms',
    Component: loadable(() => import('pages/apps/ParentTrainingPrograms')),
    exact: true,
    isprotected: true,
  },
  {
    path: '/apps/ParentTrainingPrograms/:id/edit',
    Component: loadable(() => import('pages/apps/ParentTrainingPrograms/edit')),
    exact: true,
    isprotected: true,
  },
  {
    path: '/apps/ParentTrainingPrograms/add',
    Component: loadable(() => import('pages/apps/ParentTrainingPrograms/create')),
    exact: true,
    isprotected: true,
  },
  {
    path: '/apps/ParentTrainingPrograms/:id/intrestedParents',
    Component: loadable(() => import('pages/apps/ParentTrainingPrograms/intrestedParents')),
    exact: true,
    isprotected: true,
  },
  {
    path: '/apps/TeacherTrainingPrograms',
    Component: loadable(() => import('pages/apps/TeacherTrainingPrograms')),
    exact: true,
    isprotected: true,
  },
  {
    path: '/apps/TeacherTrainingPrograms/:id/edit',
    Component: loadable(() => import('pages/apps/TeacherTrainingPrograms/edit')),
    exact: true,
    isprotected: true,
  },
  {
    path: '/apps/TeacherTrainingPrograms/add',
    Component: loadable(() => import('pages/apps/TeacherTrainingPrograms/create')),
    exact: true,
    isprotected: true,
  },
  {
    path: '/apps/TeacherTrainingPrograms/:id/intrestedTeachers',
    Component: loadable(() => import('pages/apps/TeacherTrainingPrograms/intrestedTeachers')),
    exact: true,
    isprotected: true,
  },
  {
    path: pathConfig.batch.edit,
    Component: loadable(() => import('pages/batches/edit')),
    exact: true,
    isprotected: true,
  },
  {
    path: '/classes',
    Component: loadable(() => import('pages/classes')),
    exact: true,
    isprotected: true,
  },
  {
    path: '/classes/edit',
    Component: loadable(() => import('pages/classes/edit')),
    exact: true,
    isprotected: true,
  },
  {
    path: '/admins',
    Component: loadable(() => import('pages/admin')),
    exact: true,
    isprotected: true,
  },
  {
    path: '/admins/add',
    Component: loadable(() => import('pages/admin/create')),
    exact: true,
    isprotected: true,
  },
  {
    path: pathConfig.admins.edit,
    Component: loadable(() => import('pages/admin/edit')),
    exact: true,
    isprotected: true,
  },
  {
    path: '/center',
    Component: loadable(() => import('pages/center')),
    exact: true,
    isprotected: true,
  },
  {
    path: '/center/add',
    Component: loadable(() => import('pages/center/create')),
    exact: true,
    isprotected: true,
  },
  {
    path: pathConfig.centers.edit,
    Component: loadable(() => import('pages/center/edit')),
    exact: true,
    isprotected: true,
  },
  {
    path: '/parents',
    Component: loadable(() => import('pages/parents')),
    exact: true,
    isprotected: true,
  },
  {
    path: '/counsellor/parents',
    Component: loadable(() => import('pages/parents')),
    exact: true,
    isprotected: true,
  },
  {
    path: '/counsellor/parents/add',
    Component: loadable(() => import('pages/parents/create')),
    exact: true,
    isprotected: true,
  },
  {
    path: pathConfig.guardians.ForCounsellorEdit,
    Component: loadable(() => import('pages/parents/edit')),
    exact: true,
    isprotected: true,
  },
  {
    path: '/parents/add',
    Component: loadable(() => import('pages/parents/create')),
    exact: true,
    isprotected: true,
  },
  {
    path: pathConfig.guardians.cart,
    Component: loadable(() => import('pages/cart')),
    exact: true,
    isprotected: true,
  },
  {
    path: pathConfig.guardians.edit,
    Component: loadable(() => import('pages/parents/edit')),
    exact: true,
    isprotected: true,
  },
  {
    path: pathConfig.guardians.account_linking,
    Component: loadable(() => import('pages/account_linking')),
    exact: true,
    isprotected: true,
  },
  {
    path: '/teachers',
    Component: loadable(() => import('pages/teachers')),
    exact: true,
    isprotected: true,
  },
  {
    path: '/teachers/add',
    Component: loadable(() => import('pages/teachers/create')),
    exact: true,
    isprotected: true,
  },
  {
    path: pathConfig.teachers.payments,
    Component: loadable(() => import('pages/teachers/payments')),
    exact: true,
    isprotected: true,
  },
  {
    path: pathConfig.teachers.edit,
    Component: loadable(() => import('pages/teachers/edit')),
    exact: true,
    isprotected: true,
  },
  {
    path: '/student',
    Component: loadable(() => import('pages/students')),
    exact: true,
    isprotected: true,
  },
  {
    path: '/payments',
    Component: loadable(() => import('pages/payments')),
    exact: true,
    isprotected: true,
  },
  {
    path: '/payments/add',
    Component: loadable(() => import('pages/payments/create')),
    exact: true,
    isprotected: true,
  },
  {
    path: '/payments/edit',
    Component: loadable(() => import('pages/payments/edit')),
    exact: true,
    isprotected: true,
  },
  {
    path: '/referrals',
    Component: loadable(() => import('pages/referrals')),
    exact: true,
    isprotected: true,
  },
  {
    path: '/referrals/add',
    Component: loadable(() => import('pages/referrals/create')),
    exact: true,
    isprotected: true,
  },
  {
    path: '/referrals/edit',
    Component: loadable(() => import('pages/referrals/edit')),
    exact: true,
    isprotected: true,
  },
  {
    path: '/payments',
    Component: loadable(() => import('pages/payments')),
    exact: true,
    isprotected: true,
  },
  {
    path: '/payments/add',
    Component: loadable(() => import('pages/payments/create')),
    exact: true,
    isprotected: true,
  },
  {
    path: '/payments/edit',
    Component: loadable(() => import('pages/payments/edit')),
    exact: true,
    isprotected: true,
  },
  {
    path: '/enrollments/:id/printReceipt',
    Component: loadable(() => import('pages/printReceipt')),
    exact: true,
    isprotected: true,
  },
  {
    path: '/enrollments/add',
    Component: loadable(() => import('pages/enrollments/create')),
    exact: true,
    isprotected: true,
  },
  {
    path: '/enrollments/:id/attendance',
    Component: loadable(() => import('pages/enrollments/attendance')),
    exact: true,
    isprotected: true,
  },
  {
    path: pathConfig.enrollment.details,
    Component: loadable(() => import('pages/enrollments/edit')),
    exact: true,
    isprotected: true,
  },
  {
    path: '/system/lockscreen',
    Component: loadable(() => import('pages/system/lockscreen')),
    exact: true,
    isprotected: true,
  },
  {
    path: '/system/404',
    Component: loadable(() => import('pages/system/404')),
    exact: true,
    isprotected: true,
  },
  {
    path: '/system/500',
    Component: loadable(() => import('pages/system/500')),
    exact: true,
    isprotected: true,
  },
  {
    path: '/zones',
    Component: loadable(() => import('pages/zones')),
    exact: true,
    isprotected: true,
  },
  {
    path: '/location',
    Component: loadable(() => import('pages/location')),
    exact: true,
    isprotected: true,
  },
  {
    path: '/cities',
    Component: loadable(() => import('pages/cities')),
    exact: true,
    isprotected: true,
  },
  {
    path: '/ledger',
    Component: loadable(() => import('pages/ledger')),
    exact: true,
    isprotected: true,
  },
  {
    path: '/ledger/add',
    Component: loadable(() => import('pages/ledger/add')),
    exact: true,
    isprotected: true,
  },
  {
    path: '/feedback',
    Component: loadable(() => import('pages/feedback')),
    exact: true,
    isprotected: true,
  },
  {
    path: '/feedback/add',
    Component: loadable(() => import('pages/feedback/create')),
    exact: true,
    isprotected: true,
  },
  {
    path: '/feedback/:id',
    Component: loadable(() => import('pages/feedback/edit')),
    exact: true,
    isprotected: true,
  },
  {
    path: '/appsettings',
    Component: loadable(() => import('pages/appsettings')),
    exact: true,
    isprotected: true,
  },
  {
    path: '/boards',
    Component: loadable(() => import('pages/boards')),
    exact: true,
    isprotected: true,
  },
  {
    path: '/batchLabels',
    Component: loadable(() => import('./pages/batchLabels')),
    exact: true,
    isprotected: true,
  },
  {
    path: '/cancellationReasons',
    Component: loadable(() => import('pages/cancellationReasons')),
    exact: true,
    isprotected: true,
  },
  {
    path: '/enrollments',
    Component: loadable(() => import('pages/enrollments')),
    exact: true,
    isprotected: true,
  },
  {
    path: '/counsellor/enrollments',
    Component: loadable(() => import('pages/enrollments')),
    exact: true,
    isprotected: true,
  },
  {
    path: '/orders',
    Component: loadable(() => import('pages/order')),
    exact: true,
    isprotected: true,
  },
  {
    path: pathConfig.order.edit,
    Component: loadable(() => import('pages/order_view_details')),
    exact: true,
    isprotected: true,
  },
  {
    path: '/classes/:id/markAttendance',
    Component: loadable(() => import('pages/classes/markAttendance')),
    exact: true,
    isprotected: true,
  },
  {
    path: '/classes/:id/classUpdates',
    Component: loadable(() => import('pages/classes/classUpdate')),
    exact: true,
    isprotected: true,
  },
  {
    path: '/:section/:id/collectFees',
    Component: loadable(() => import('pages/classes/collectFees')),
    exact: true,
    isprotected: true,
  },
  {
    path: '/printReceipt',
    Component: loadable(() => import('pages/printReceipt')),
    exact: true,
    isprotected: true,
  },
  {
    path: '/ledger/:id/reconcileAccounts',
    Component: loadable(() => import('pages/ledger/reconcileAccounts')),
    exact: true,
    isprotected: true,
  },
  {
    path: '/coupons',
    Component: loadable(() => import('pages/coupons')),
    exact: true,
    isprotected: true,
  },
  {
    path: '/coupons/add',
    Component: loadable(() => import('pages/coupons/create')),
    exact: true,
    isprotected: true,
  },
  {
    path: '/coupons/:id/edit',
    Component: loadable(() => import('pages/coupons/edit')),
    exact: true,
    isprotected: true,
  },
  {
    path: '/notifications',
    Component: loadable(() => import('pages/notification')),
    exact: true,
    isprotected: true,
  },
  {
    path: '/notification/add',
    Component: loadable(() => import('pages/notification/create')),
    exact: true,
    isprotected: true,
  },
  {
    path: '/notification/edit',
    Component: loadable(() => import('pages/notification/edit')),
    exact: true,
    isprotected: true,
  },

  // {
  //   path: '/about',
  //   Component: loadable(() => import('pages/about')),
  //   exact: true,
  //   isprotected: true,
  // },
  {
    path: '/roles',
    Component: loadable(() => import('pages/roles')),
    exact: true,
    isprotected: true,
  },
  {
    path: '/testimonials',
    Component: loadable(() => import('pages/testimonials')),
    exact: true,
    isprotected: true,
  },
  {
    path: '/testimonials/add',
    Component: loadable(() => import('pages/testimonials/create')),
    exact: true,
    isprotected: true,
  },
  {
    path: pathConfig.testimonial.edit,
    Component: loadable(() => import('pages/testimonials/edit')),
    exact: true,
    isprotected: true,
  },
  {
    path: '/course-learnings',
    Component: loadable(() => import('pages/courseLearning')),
    exact: true,
    isprotected: true,
  },
  {
    path: '/course-learnings/add',
    Component: loadable(() => import('pages/courseLearning/create')),
    exact: true,
    isprotected: true,
  },
  {
    path: pathConfig.courseLearning.edit,
    Component: loadable(() => import('pages/courseLearning/edit')),
    exact: true,
    isprotected: true,
  },

  {
    path: '/wallet',
    Component: loadable(() => import('pages/wallet')),
    exact: true,
    isprotected: true,
  },
  {
    path: '/generate-wallet-paymentlink',
    Component: loadable(() => import('pages/wallet/add')),
    exact: true,
    isprotected: true,
  },

  // reports routes

  {
    path: pathConfig.reports.collectionReport,
    Component: loadable(() => import('pages/reports/collection')),
    exact: true,
    isprotected: true,
  },
  {
    path: pathConfig.reports.enrollmentReport,
    Component: loadable(() => import('pages/reports/enrollment')),
    exact: true,
    isprotected: true,
  },
  {
    path: pathConfig.reports.organicReport,
    Component: loadable(() => import('pages/reports/organic')),
    exact: true,
    isprotected: true,
  },

  // trail slot

  {
    path: pathConfig.enrollment.trail_slot,
    Component: loadable(() => import('pages/counsellorDashboard/CourseDetails/TrailSlot')),
    exact: true,
    isprotected: true,
  },
  {
    path: pathConfig.enrollment.trial_slots_index,
    Component: loadable(() => import('pages/trialSlots')),
    exact: true,
    isprotected: true,
  },
  {
    path: pathConfig.trialSlot.conversion,
    Component: loadable(() => import('pages/trialConversion')),
    exact: true,
    isprotected: true,
  },
  {
    path: pathConfig.feeDueList.index,
    Component: loadable(() => import('pages/feeDueList')),
    exact: true,
    isprotected: true,
  },
]

const mapStateToProps = ({ settings }) => ({ settings })

@connect(mapStateToProps)
class Router extends React.Component {
  render() {
    const {
      history,
      settings: { routerAnimation },
    } = this.props
    return (
      <ConnectedRouter history={history}>
        <Layout>
          <Switch
            render={props => {
              const {
                children,
                location: { pathname },
              } = props
              return (
                <SwitchTransition>
                  <CSSTransition
                    key={pathname}
                    classNames={routerAnimation}
                    timeout={routerAnimation === 'none' ? 0 : 300}
                  >
                    {children}
                  </CSSTransition>
                </SwitchTransition>
              )
            }}
          >
            {routes.map(({ path, Component, exact, isprotected }) => (
              <ProtectedRoute path={path} key={path} exact={exact} isprotected={isprotected}>
                <Component />
              </ProtectedRoute>
            ))}
            <Route component={NotFoundPage} />
          </Switch>
        </Layout>
      </ConnectedRouter>
    )
  }
}

export default Router
