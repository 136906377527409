import { notification } from 'antd'
import axiosInstance from 'utilities/configureAxios'
import route from 'utilities/route'

export default async function fetchRelatedTags(payload = {}) {
  return axiosInstance
    .get(route('courses.tags'), {
      params: payload,
      headers: { Pragma: 'no-cache' },
    })
    .then(res => res)
    .catch(error => {
      notification.warning({
        message: error.code,
        description: error.message,
      })
      return error.response
    })
}
