import { notification } from 'antd'
import axiosInstance from 'utilities/configureAxios'
import route from 'utilities/route'

export default async function getEnrollments(payload = {}) {
  return axiosInstance
    .get(route('enrollments.index'), payload)
    .then(res => res)
    .catch(error => {
      notification.warning({
        message: error.code,
        description: error.message,
      })
      return error.response
    })
}

export async function getEnrollmentByOrder(orderId, payload = {}) {
  const extra = '/enrollments'
  const url = route('orders.current_order') + orderId + extra
  return axiosInstance
    .get(url, payload)
    .then(res => res)
    .catch(error => {
      notification.warning({
        message: error.code,
        description: error.message,
      })
      return error.response
    })
}

export async function getCurrentEnrollment(orderId, payload = {}) {
  const url = route('enrollments.current_enrollment') + orderId
  return axiosInstance
    .get(url, payload)
    .then(res => res)
    .catch(error => {
      notification.warning({
        message: error.code,
        description: error.message,
      })
      return error.response
    })
}

export async function createEnrollment(payload = {}) {
  return axiosInstance
    .post(route('enrollments.index'), payload)
    .then(res => res)
    .catch(error => {
      notification.warning({
        message: error.code,
        description: error.message,
      })
      return error.response
    })
}

export async function cancelEnrollment(orderId, isRefundable, payload = {}) {
  const extra = '/cancel'
  const requestPayload = {
    ...payload,
    is_refundable: isRefundable,
  }
  const url = route('enrollments.current_enrollment') + orderId + extra
  return axiosInstance
    .post(url, requestPayload)
    .then(res => res)
    .catch(error => {
      notification.warning({
        message: error.code,
        description: error.message,
      })
      return error.response
    })
}

export async function updateTrialEnrollmentType(orderId, payload = {}) {
  const url = route('enrollments.current_enrollment') + orderId
  return axiosInstance
    .patch(url, payload)
    .then(res => res)
    .catch(error => {
      notification.warning({
        message: error.code,
        description: error.message,
      })
      return error.response
    })
}

export async function updateEnrollment(orderId, payload = {}) {
  const url = route('enrollments.current_enrollment') + orderId
  return axiosInstance
    .post(url, payload)
    .then(res => res)
    .catch(error => {
      notification.warning({
        message: error.code,
        description: error.message,
      })
      return error.response
    })
}

export async function updateCommission(enrollId, payload = {}) {
  const url = route('enrollments.modify_commission', { id: enrollId })
  return axiosInstance
    .patch(url, payload)
    .then(res => res)
    .catch(error => {
      notification.warning({
        message: error.code,
        description: error.message,
      })
      return error.response
    })
}

export async function exportEnrollments(payload = {}, extraParams = {}) {
  const routeValue = route('enrollments.export')
  return axiosInstance
    .post(routeValue, payload, extraParams)
    .then(res => res)
    .catch(error => {
      notification.warning({
        message: error.code,
        description: error.message,
      })
      return error.response
    })
}

export async function getEnrollmentAttendance(payload = {}) {
  return axiosInstance
    .get(route('enrollments.attendance', { id: payload.id }), { params: payload.params })
    .then(res => res)
    .catch(error => {
      notification.warning({
        message: error.code,
        description: error.message,
      })
      return error.response
    })
}

export async function updateEnrollmentAttendance(payload = {}) {
  const routeValue = `${route('enrollments.update_attendance', { id: payload.enrollId })}`
  return axiosInstance
    .patch(routeValue, { status: payload.status }, { params: payload.params })
    .then(res => res)
    .catch(error => {
      notification.warning({
        message: error.code,
        description: error.message,
      })
      return error.response
    })
}

export async function transferEnrollment(id, payload = {}) {
  const routeValue = route('enrollments.transfer', { id })
  return axiosInstance
    .post(routeValue, payload)
    .then(res => res)
    .catch(error => {
      notification.warning({
        message: error.code,
        description: error.message,
      })
      return error.response
    })
}

export async function editClassFeesEnrollment(id, payload = {}) {
  const routeValue = route('enrollments.editClassFees', { id })
  return axiosInstance
    .post(routeValue, payload)
    .then(res => res)
    .catch(error => {
      notification.warning({
        message: error.code,
        description: error.message,
      })
      return error.response
    })
}

export async function printReceiptEnrollment(id, payload = {}) {
  const routeValue = route('enrollments.print_receipt', { id })
  return axiosInstance
    .get(routeValue, payload)
    .then(res => res)
    .catch(error => {
      notification.warning({
        message: error.code,
        description: error.message,
      })
      return error.response
    })
}

export async function getCertificateEnrollment(id, payload = {}) {
  const routeValue = route('enrollments.get_certificate', { id })
  return axiosInstance
    .get(routeValue, payload)
    .then(res => res)
    .catch(error => {
      notification.warning({
        message: error.code,
        description: error.message,
      })
      return error.response
    })
}

export async function getCancellationReasons(payload = {}) {
  return axiosInstance
    .get(route('enrollments.cancellation_reason'), payload)
    .then(res => res)
    .catch(error => {
      notification.warning({
        message: error.code,
        description: error.message,
      })
      return error.response
    })
}

// archiveEnrollmentApi
export async function archiveEnrollmentApi(id, payload = {}) {
  const routeValue = route('enrollments.archive') + id
  return axiosInstance
    .delete(routeValue, payload)
    .then(res => res)
    .catch(error => {
      notification.warning({
        message: error.code,
        description: error.message,
      })
      return error.response
    })
}
export async function unarchiveEnrollmentApi(id, payload = {}) {
  const routeValue = route('enrollments.unarchive') + id
  return axiosInstance
    .get(routeValue, payload)
    .then(res => res)
    .catch(error => {
      notification.warning({
        message: error.code,
        description: error.message,
      })
      return error.response
    })
}

export async function enrollmentCount(payload = {}) {
  return axiosInstance
    .get(route('enrollments.count'), payload)
    .then(res => res)
    .catch(error => {
      notification.warning({
        message: error.code,
        description: error.message,
      })
      return error.response
    })
}

export async function markClassLeave(id, payload = {}) {
  return axiosInstance
    .post(route('classes.mark_class_leave', { id }), payload)
    .then(res => res)
    .catch(error => {
      notification.warning({
        message: error.code,
        description: error.message,
      })
      return error.response
    })
}
