const actions = {
  SET_STATE: 'feedbacks/SET_STATE',
  LOAD_FEEDBACKS_INDEX: 'feedbacks/LOAD_FEEDBACKS_INDEX',
  LOAD_CURRENT_FEEDBACK: 'feedbacks/LOAD_CURRENT_FEEDBACK',
  LOAD_CURRENT_FEEDBACK_ASYNC: 'feedbacks/LOAD_CURRENT_FEEDBACK_ASYNC',
  SET_FILTERS: 'feedbacks/SET_FILTERS',
  CLEAR_FILTERS: 'feedbacks/CLEAR_FILTERS',
  UPDATE_FEEDBACK: 'feedbacks/UPDATE_FEEDBACK',
}

export default actions
