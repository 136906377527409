const routesConfig = {
  auth: {
    login: 'api/v1/admin/login',
    logout: 'api/v1/admin/logout',
    reset_link: 'api/v1/admin/password',
    reset_password: 'api/v1/admin/password/reset',
  },
  users: {
    index: 'api/v1/admin/users',
  },
  admin: {
    index: 'api/v1/admin/admins',
    export: 'api/v1/admin/admins/export',
    create: 'api/v1/admin/admins',
    edit: 'api/v1/admin/admins/:id',
    update_status: 'api/v1/admin/admins/:id',
    getById: 'api/v1/admin/admins/:id',
    roles: 'api/v1/admin/roles',
    permissions: 'api/v1/admin/permissions',
    create_role: 'api/v1/admin/roles/:name',
    payment_cron: 'api/v2/admin/cron/sync-payments',
    fee_due_cron: 'api/v2/admin/enrollments/fee_due_status',
    wallet_payment_cron: 'api/v2/admin/wallet/cron/sync-payments ',
  },
  courses: {
    index: 'api/v1/admin/courses',
    tags: 'api/v1/admin/related-topics',
    create: 'api/v1/admin/courses',
    course_types: 'api/v1/admin/course-types',
    current_course: 'api/v1/admin/courses/',
    update_course: 'api/v1/admin/courses/',
    export: 'api/v1/admin/courses/export',
    archive: 'api/v1/admin/courses/',
    unarchive: 'api/v1/admin/courses/restore/',
  },
  classes: {
    index: 'api/v2/admin/classes',
    class_update: 'api/v2/admin/classes/:id/class-updates',
    mark_holiday: 'api/v2/admin/classes/:id/mark-holiday',
    mark_class_attendence: 'api/v2/admin/classes/:id/mark-attendance',
    export: 'api/v2/admin/classes/export',
    class_ledger: 'api/v2/admin/classes/:id/class-ledger',
    mark_class_leave: 'api/v2/admin/classes/:id/mark-leave',
  },
  batches: {
    index: 'api/v1/admin/batches',
    installments: 'api/v2/admin/installment-types',
    current_batch: 'api/v1/admin/batches/',
    export: 'api/v1/admin/batches/export',
    schedule: 'api/v1/admin/batches/schedule',
    archive: 'api/v1/admin/batches/',
    unarchive: 'api/v1/admin/batches/restore/',
  },
  teachers: {
    index: 'api/v1/admin/teachers',
    revenue_responsible: 'api/v2/admin/teachers/revenue-responsible',
    create: 'api/v1/admin/teachers',
    getById: 'api/v1/admin/teachers/:id',
    edit: 'api/v1/admin/teachers/:id',
    export: 'api/v1/admin/teachers/export',
  },
  teachers_earning: {
    index: 'api/v2/admin/teacher/earning',
    add: 'api/v2/admin/teacher/earning',
    edit: 'api/v2/admin/teacher/earning/:id',
    delete: 'api/v2/admin/teacher/earning/:id',
    upload: 'api/v2/admin/teacher/upload',
  },
  centres: {
    index: 'api/v1/admin/centres',
    update: 'api/v1/admin/centres/:id',
    create: 'api/v1/admin/centres',
    getById: 'api/v1/admin/centres/:id',
    export: 'api/v1/admin/centres/export',
  },
  standards: {
    index: 'api/v1/admin/standards',
  },
  guardians: {
    create: 'api/v1/admin/guardians',
    index: 'api/v1/admin/guardians',
    getById: 'api/v1/admin/guardians/:id',
    edit: 'api/v1/admin/guardians/:id',
    cart_items: 'api/v1/admin/guardians/:id/cart-items',
    delete_item: 'api/v1/admin/guardians/:id/cart-items/:cartId',
    export: 'api/v1/admin/guardians/export',
    family_members: 'api/v2/admin/guardians/:id/family-members',
    link_guardian: 'api/v2/admin/guardians/:id/link-new-guardian',
  },
  enrollments: {
    index: 'api/v1/admin/enrollments',
    count: 'api/v1/admin/enrollments/count',
    current_enrollment: 'api/v1/admin/enrollments/',
    export: 'api/v1/admin/enrollments/export',
    modify_commission: 'api/v2/admin/enrollments/:id/commission',
    attendance: 'api/v2/admin/enrollments/:id/attendance',
    update_attendance: 'api/v2/admin/enrollments/:id/update-attendance',
    transfer: 'api/v2/admin/enrollments/:id/transfer',
    editClassFees: 'api/v2/admin/enrollments/:id/adjust-class-fees',
    print_receipt: 'api/v1/admin/enrollments/:id/pdf',
    get_certificate: 'api/v2/admin/enrollments/:id/certificate',
    cancellation_reason: 'api/v2/admin/enrollments/cancellation-reasons',
    archive: 'api/v2/admin/enrollments/',
    unarchive: 'api/v2/admin/enrollments/restore/',
    notesUpdate: '',
  },
  orders: {
    index: 'api/v1/admin/orders',
    current_order: 'api/v1/admin/orders/',
    export: 'api/v1/admin/orders/export',
    collect_fees: 'api/v2/admin/orders/:id/collect-fees',
    update_amount: 'api/v2/admin/orders/:id/update-amount',
    update_status: 'api/v1/admin/orders/:id',
    add_due: 'api/v2/admin/orders/:id/add-due',
    collect_fees_emandate: 'api/v2/admin/recurring-payment/:id/payment',
  },
  cities: {
    index: 'api/v1/admin/cities',
    current_city: 'api/v1/admin/cities/',
  },
  states: {
    index: 'api/v1/admin/states',
  },
  zones: {
    index: 'api/v1/admin/zones',
    create: 'api/v1/admin/zones',
    edit: 'api/v1/admin/zones/:id',
  },
  configurations: {
    index: 'api/v1/admin/configurations',
    edit: 'api/v1/admin/configurations',
    generate_secret_otp: 'api/v2/admin/otp/secret_otp',
  },
  location: {
    index: 'api/v1/admin/locations',
    current_location: 'api/v1/admin/locations/',
  },
  ledgers: {
    index: 'api/v2/admin/ledgers',
    create_settlement: 'api/v2/admin/ledgers/:id/initiate-settlement',
    export: 'api/v2/admin/ledgers/export',
  },
  payments: {
    index: 'api/v1/admin/payments',
    create: 'api/v1/admin/orders',
    create_refund: 'api/v1/admin/payments/:id/refund',
    get_refunds: 'api/v1/admin/refunds',
    edit_refund: 'api/v1/admin/refunds/:id',
    export: 'api/v2/admin/payments/export',
  },
  coupons: {
    index: 'api/v2/admin/coupons',
    create: 'api/v2/admin/coupons/new',
    export: 'api/v2/admin/coupons/export',
    current_coupon: 'api/v2/admin/coupons/:id',
  },
  notifications: {
    index: 'api/v2/admin/notifications',
    create: 'api/v2/admin/notifications/new',
    teacherRecipients: 'api/v1/admin/teachers',
    parentRecipients: 'api/v1/admin/guardians',
    enrollmentRecipients: 'api/v1/admin/enrollments',
    export: 'api/v2/admin/notifications/export',
  },
  programs: {
    index: 'api/v2/admin/programs',
    current_program: 'api/v2/admin/programs/:id',
    edit: 'api/v2/admin/programs/:id',
    current_program_registrations: 'api/v2/admin/programs/:id/registrations',
    update_status: 'api/v2/admin/programs/:id',
  },
  feedbacks: {
    export: 'api/v2/admin/feedbacks/export',
    index: 'api/v2/admin/feedbacks',
    current_feedback: 'api/v2/admin/feedbacks/:id',
  },
  counselor: {
    grades: 'api/v1/admin/standards',
    courses: 'api/v1/admin/courses',
    courseDetails: 'api/v1/admin/courses/',
    enrollmentDetails: 'api/v2/admin/counsellor/enrollments',
    addChild: 'api/v1/admin/guardians/',
    addLead: 'api/v2/admin/counsellor/guardian-leads',
    trialClassDates: 'api/v2/admin/counsellor/batches/',
    installmentBreakage: 'api/v2/admin/counsellor/batches/',
    specifyCoupon: 'api/v2/admin/counsellor/coupons/',
    paymentLink: 'api/v2/admin/counsellor/orders/',
    shareResource: 'api/v2/admin/counsellor/courses/',
    counselors: 'api/v1/admin/admins',
    classesForEachBatch: 'api/v2/admin/classes',
    validate_enrollment: 'api/v2/admin/counsellor/enrollment_status',
    classes_count: 'api/v1/admin/classes_count',
  },
  counselorPayment: {
    bookTrial: 'api/v1/admin/enrollments',
    parentCoupons: 'api/v2/admin/counsellor/guardians/',
    toggleCoupon: 'api/v2/admin/counsellor/orders/',
    applyCoupon: 'api/v2/admin/counsellor/orders/',
    additionalDiscountOnly: 'api/v2/admin/counsellor/orders/',
  },
  testimonials: {
    index: 'api/v2/admin/testimonials',
    current_testimonial: 'api/v2/admin/testimonials/',
    create: 'api/v2/admin/testimonials',
    update: 'api/v2/admin/testimonials/',
    delete: 'api/v2/admin/testimonials/',
  },
  courseLearning: {
    index: 'api/v2/admin/course-learnings',
    current_courseLearning: 'api/v2/admin/course-learnings/',
    create: 'api/v2/admin/course-learnings',
    update: 'api/v2/admin/course-learnings/',
    delete: 'api/v2/admin/course-learnings/',
  },
  reports: {
    // enrollments -> course wise
    fetchEnrollmentsCoursewise: 'api/v2/admin/enrollments-coursewise/export',
    fetchEnrollmentsCoursBatchewise: 'api/v2/admin/enrollments-course-teacherwise/export',
    fetchEnrollmentsCourseTeacherBatchwise:
      'api/v2/admin/enrollments-course-teacher-batchwise/export',
    fetchEnrollmentsCourseTeacherBatchStudentwise:
      'api/v2/admin/enrollments-course-teacher-batch-studentwise/export',

    // enrollment -> By teacher
    fetchEnrollmentsTeacherwise: 'api/v2/admin/enrollments-teacherwise/export',
    fetchEnrollmentsTeacherCoursewise: 'api/v2/admin/enrollments-teacher-coursewise/export',
    fetchEnrollmentsTeacherCourseBatchwise:
      'api/v2/admin/enrollments-teacher-course-batchwise/export',
    fetchEnrollmentsTeacherCourseBatchStudentwise:
      'api/v2/admin/enrollments-teacher-course-batch-studentwise/export',

    // enrollment -> By student
    fetchEnrollmentsStudentwise: 'api/v2/admin/enrollments-studentwise/export',
    fetchEnrollmentsStudentCoursewise: 'api/v2/admin/enrollments-student-coursewise/export',
    fetchEnrollmentsStudentCourseBatchwise:
      'api/v2/admin/enrollments-student-course-batchwise/export',

    // collection -> By course
    fetchCollectionsCoursewise: 'api/v2/admin/collections-coursewise/export',
    fetchCollectionsCourseTeacherwise: 'api/v2/admin/collections-course-teacherwise/export',
    fetchCollectionsCourseTeacherBatchwise:
      'api/v2/admin/collections-course-teacher-batchwise/export',
    fetchCollectionsCourseTeacherBatchStudentwise:
      'api/v2/admin/collections-course-teacher-batch-studentwise/export',

    // collection -> By Teacher
    fetchCollectionsTeacherwise: 'api/v2/admin/collections-teacherwise/export',
    fetchcollectionsTeacherCoursewise: 'api/v2/admin/collections-teacher-coursewise/export',
    fetchCollectionsTeacherCourseBatchwise:
      'api/v2/admin/collections-teacher-course-batchwise/export',
    fetchCollectionsTeacherCourseBatchStudentwise:
      'api/v2/admin/collections-teacher-course-batch-studentwise/export',

    // collection -> By student
    fetchCollectionsStudentwise: 'api/v2/admin/collections-studentwise/export',
    fetchCollectionsStudentCoursewise: 'api/v2/admin/collections-student-coursewise/export',
    fetchCollectionsStudentCourseBatchwise:
      'api/v2/admin/collections-student-course-batchwise/export',

    // course reports
    // fetchTotalByCourse: 'api/v2/admin/total-by-course/export',
    // fetchTotalByCourseDetails: 'api/v2/admin/total-by-course-details/export',
    prevYearEnrolledStudentWhichIsNotEnrolledThisYear:
      '/api/v2/admin/prev-year-enrolled-students-which-is-not-enrolled-this-year',
    organicReport: '/api/v2/admin/organic-report/export',
    detailedOrganicReport: '/api/v2/admin/organic-report-detail/export',
    dashboardMetrics: '/api/v2/admin/dashboard-metrics',
  },
  wallet: {
    index: 'api/v2/admin/wallet/transactions/all',
    add: 'api/v2/admin/wallet/:uuid/payment-link/generate',
    export: 'api/v2/admin/wallet/transactions/export',
  },
  refund: {
    do_refund: 'api/v2/admin/wallet/refund',
  },
  student: {
    index: 'api/v2/admin/childrens',
  },
  trial: {
    index: 'api/v2/admin/trial-slots',
    export: 'api/v2/admin/trial-slots/export',
    trialEnrollment: 'api/v2/admin/trial-enrollment',
    trialAttendenece: 'api/v2/admin/trial-attendence',
    trialSlotConversion: 'api/v2/admin/trial-conversion/export',
    trialSlotConversionDetail: 'api/v2/admin/trial-conversion-details/export',
  },
  razorpayCred: {
    index: 'api/v2/admin/orders/razorpay-cred',
  },
  boards: {
    index: 'api/v2/admin/boards',
    add: 'api/v2/admin/boards',
    update: 'api/v2/admin/boards/:id',
    remove: 'api/v2/admin/boards/:id',
  },
  cancellationReasons: {
    index: 'api/v2/admin/enrollments/cancellation-reasons',
    add: 'api/v2/admin/enrollments/cancellation-reasons',
    update: 'api/v2/admin/enrollments/cancellation-reasons/:id',
    remove: 'api/v2/admin/enrollments/cancellation-reasons/:id',
  },
  batchLabels: {
    index: 'api/v2/admin/batch-labels',
    add: 'api/v2/admin/batch-labels',
    update: 'api/v2/admin/batch-labels/:id',
    remove: 'api/v2/admin/batch-labels/:id',
  },
}

export default routesConfig
