import { all, takeEvery, put, call } from 'redux-saga/effects'
import { push } from 'react-router-redux'
import getPayments, { createPayment, createRefund, getRefunds, editRefund } from 'services/payments'
import { notification } from 'antd'
import actions from './actions'
import errorActions from '../error/actions'

export function* WATCH_LOAD_PAYMENTS_INDEX(action) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })

  const response = yield call(getPayments, action.payload)

  if (response) {
    if (response.data.success) {
      yield put({
        type: actions.SET_STATE,
        payload: {
          index: response.data.data,
          pagination: response.data.meta.pagination,
        },
      })
    } else {
      yield put({
        type: errorActions.SET_STATE,
        payload: response.data,
      })
    }
  }
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: false,
    },
  })
}

export function* WATCH_CREATE_PAYMENT(action) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })

  const response = yield call(createPayment, action.payload)

  if (response) {
    if (response.data.success) {
      yield put(push('/payments'))
    } else {
      yield put({
        type: errorActions.SET_STATE,
        payload: response.data,
      })
    }
  }
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: false,
    },
  })
}

export function* WATCH_LOAD_REFUNDS_TABLE(action) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      refundTableLoading: true,
    },
  })

  const response = yield call(getRefunds, action.payload)

  if (response) {
    if (response.data.success) {
      yield put({
        type: actions.SET_STATE,
        payload: {
          refunds: response.data.data,
        },
      })
    } else {
      yield put({
        type: errorActions.SET_STATE,
        payload: response.data,
      })
    }
  }
  yield put({
    type: actions.SET_STATE,
    payload: {
      refundTableLoading: false,
    },
  })
}

export function* WATCH_CREATE_REFUND(action) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      refundModalLoading: true,
    },
  })

  const response = yield call(createRefund, action.payload.id, action.payload)
  yield put({
    type: actions.SET_STATE,
    payload: {
      refundModalLoading: false,
    },
  })
  if (response) {
    if (response.data.success) {
      yield put({
        type: actions.SET_STATE,
        payload: {
          showRefundModal: false,
          current: {},
        },
      })
      notification.success({
        message: 'Success!',
        description: 'Refund successfully processed',
      })
    } else {
      yield put({
        type: actions.SET_STATE,
        payload: {
          showRefundModal: false,
          current: {},
        },
      })
      yield put({
        type: errorActions.SET_STATE,
        payload: response.data,
      })
    }
  }
}

export function* WATCH_EDIT_REFUND(action) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      refundStatusModalLoading: true,
    },
  })
  const response = yield call(editRefund, action.payload.id, action.payload)
  yield put({
    type: actions.SET_STATE,
    payload: {
      refundStatusModalLoading: false,
    },
  })

  if (response) {
    if (response.data.success) {
      yield put({
        type: actions.SET_STATE,
        payload: {
          showRefundStatusModal: false,
          currentRefund: {},
        },
      })

      yield put({
        type: actions.LOAD_REFUNDS,
        payload: {
          id: action.payload.paymentId,
        },
      })
      notification.success({
        message: 'Success!',
        description: 'Refund status successfully changed',
      })
    } else {
      yield put({
        type: actions.SET_STATE,
        payload: {
          showRefundStatusModal: false,
          currentRefund: {},
        },
      })
      yield put({
        type: errorActions.SET_STATE,
        payload: response.data,
      })
    }
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.LOAD_PAYMENTS_INDEX, WATCH_LOAD_PAYMENTS_INDEX),
    takeEvery(actions.CREATE_PAYMENTS, WATCH_CREATE_PAYMENT),
    takeEvery(actions.CREATE_REFUNDS, WATCH_CREATE_REFUND),
    takeEvery(actions.LOAD_REFUNDS, WATCH_LOAD_REFUNDS_TABLE),
    takeEvery(actions.EDIT_REFUNDS, WATCH_EDIT_REFUND),
  ])
}
