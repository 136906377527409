const actions = {
  SET_STATE: 'cities/SET_STATE',
  LOAD_CITIES_INDEX: 'cities/LOAD_CITIES_INDEX',
  LOAD_CURRENT_CITIES: 'cities/LOAD_CITIES_INDEX',
  SET_FILTERS: 'cities/SET_FILTERS',
  CREATE_CITY: 'cities/CREATE_CITY',
  UPDATE_CITY: 'cities/UPDATE_CITY',
  CLEAR_FILTERS: 'cities/CLEAR_FILTERS',
}

export default actions
