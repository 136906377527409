import { all, takeEvery, put, call } from 'redux-saga/effects'
import getTeachers, { createTeacher, updateTeacher, getTeacherDetails } from 'services/teachers'
import { push } from 'react-router-redux'
import { notification } from 'antd'
import actions from './actions'
import errorActions from '../error/actions'

export function* WATCH_LOAD_TEACHERS_INDEX(action) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })

  const response = yield call(getTeachers, action.payload)

  if (response) {
    if (response.data.success) {
      yield put({
        type: actions.SET_STATE,
        payload: {
          index: response.data.data,
          pagination: response.data.meta.pagination,
        },
      })
    } else {
      yield put({
        type: errorActions.SET_STATE,
        payload: response.data,
      })
    }
  }
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: false,
    },
  })
}
export function* WATCH_CREATE_TEACHER(action) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })

  const response = yield call(createTeacher, action.payload)

  if (response) {
    if (response.data.success) {
      yield put(push('/teachers'))
      yield put({
        type: errorActions.SET_STATE,
        payload: { errors: {} },
      })
      notification.success({
        message: 'Success !',
        description: 'Teacher created successfully.',
      })
    } else if (response.data.code === 422) {
      yield put({
        type: errorActions.SET_STATE,
        payload: response.data,
      })
    }
  }
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: false,
    },
  })
}

export function* WATCH_UPDATE_TEACHER(action) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })

  const response = yield call(
    updateTeacher,
    action.payload.id,
    action.payload.values,
    action.payload.query,
  )
  if (response) {
    if (response.data.success) {
      yield put({
        type: actions.SET_STATE,
        payload: { current: response.data.data },
      })
      yield put(push('/teachers'))
      notification.success({
        message: 'Success !',
        description: 'Details saved successfully.',
      })
    } else if (response.data.code === 422) {
      yield put({
        type: errorActions.SET_STATE,
        payload: response.data,
      })
    }
  }
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: false,
    },
  })
}

export function* WATCH_LOAD_SELECTED_TEACHER(action) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })

  const response = yield call(getTeacherDetails, action.payload.id, action.payload.query)

  if (response) {
    if (response.data.success) {
      yield put({
        type: actions.SET_STATE,
        payload: { current: response.data.data },
      })
    } else if (response.data.code === 422) {
      yield put({
        type: errorActions.SET_STATE,
        payload: response.data,
      })
    }
  }
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: false,
    },
  })
}

export function* WATCH_CALENDAR_EVENTS(action) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })

  const response = yield call(getTeachers, action.payload)

  if (response) {
    if (response.data.success) {
      console.log(response.data.data?.[0])
      yield put({
        type: actions.SET_STATE,
        payload: {
          calendarEvents: response.data.data?.[0],
        },
      })
    } else {
      yield put({
        type: errorActions.SET_STATE,
        payload: response.data,
      })
    }
  }
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: false,
    },
  })
}

export function* WATCH_UPDATE_STATUS(action) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })

  const response = yield call(updateTeacher, action.payload.id, action.payload.data)

  if (response) {
    if (response.data.success) {
      yield put({
        type: actions.LOAD_TEACHERS_INDEX,
        payload: { params: action.payload.filters },
      })
      notification.success({
        message: 'Success !',
        description: 'Status Changed Success',
      })
    } else {
      yield put({
        type: errorActions.SET_STATE,
        payload: response.data,
      })
      notification.warning({
        message: 'Failure !',
        description: 'Status Cannot Be Changed',
      })
    }
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.LOAD_TEACHERS_INDEX, WATCH_LOAD_TEACHERS_INDEX),
    takeEvery(actions.CREATE_TEACHER, WATCH_CREATE_TEACHER),
    takeEvery(actions.LOAD_CURRENT_TEACHER, WATCH_LOAD_SELECTED_TEACHER),
    takeEvery(actions.UPDATE_TEACHER, WATCH_UPDATE_TEACHER),
    takeEvery(actions.UPDATE_STATUS, WATCH_UPDATE_STATUS),
    takeEvery(actions.CALENDAR_EVENTS, WATCH_CALENDAR_EVENTS),
  ])
}
