import { all, takeEvery, put, call } from 'redux-saga/effects'
import actions from './actions'
import errorActions from '../error/actions'
import { getAllTrialSlot } from '../../services/trial'

export function* WATCH_LOAD_TRIAL_INDEX(action) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })

  const response = yield call(getAllTrialSlot, action.payload)

  if (response) {
    if (response.data.success) {
      yield put({
        type: actions.SET_STATE,
        payload: {
          index: response.data.data,
          pagination: response.data.meta.pagination,
        },
      })
    } else {
      yield put({
        type: errorActions.SET_STATE,
        payload: response.data,
      })
    }
  }
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: false,
    },
  })
}

export default function* rootSaga() {
  yield all([takeEvery(actions.LOAD_TRIAL_INDEX, WATCH_LOAD_TRIAL_INDEX)])
}
