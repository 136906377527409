import { notification } from 'antd'
import axiosInstance from 'utilities/configureAxios'
import route from 'utilities/route'

export default async function getCenters(payload = {}) {
  return axiosInstance
    .get(route('centres.index'), payload)
    .then(res => res)
    .catch(error => {
      notification.warning({
        message: error.code,
        description: error.message,
      })
      return error.response
    })
}

export async function createCenter(payload, query = {}) {
  return axiosInstance
    .post(route('centres.create'), payload, { params: query })
    .then(res => res)
    .catch(error => {
      notification.warning({
        message: error.code,
        description: error.message,
      })
      return error.response
    })
}

export async function updateCenters(id, payload = {}, query = {}) {
  return axiosInstance
    .put(route('centres.update', { id }), payload, { params: query })
    .then(res => res)
    .catch(error => {
      notification.warning({
        message: error.code,
        description: error.message,
      })
      return error.response
    })
}

export async function updateCenterStatus(id, payload = {}, query = {}) {
  return axiosInstance
    .post(route('centres.update', { id }), payload, { params: query })
    .then(res => res)
    .catch(error => {
      notification.warning({
        message: error.code,
        description: error.message,
      })
      return error.response
    })
}

export async function fetchCenter(id, query = {}) {
  return axiosInstance
    .get(route('centres.getById', { id }), { params: query })
    .then(res => res)
    .catch(error => {
      notification.warning({
        message: error.code,
        description: error.message,
      })
      return error.response
    })
}

export async function exportCentres(payload = {}, extraParams = {}) {
  const routeValue = route('centres.export')
  return axiosInstance
    .post(routeValue, payload, extraParams)
    .then(res => res)
    .catch(error => {
      notification.warning({
        message: error.code,
        description: error.message,
      })
      return error.response
    })
}
