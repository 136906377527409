const actions = {
  SET_STATE: 'boards/SET_STATE',
  HIDE_MODAL: 'boards/HIDE_MODAL',
  LOAD_BOARD_INDEX: 'boards/LOAD_BOARD_INDEX',
  LOAD_CURRENT_BOARD: 'boards/LOAD_CURRENT_BOARD',
  SET_FILTERS: 'boards/SET_FILTERS',
  CREATE_BOARD: 'boards/CREATE_BOARD',
  UPDATE_BOARD: 'boards/UPDATE_BOARD',
  DELETE_BOARD: 'boards/DELETE_BOARD',
  CLEAR_FILTERS: 'boards/CLEAR_FILTERS',
}

export default actions
