import { notification } from 'antd'
import axiosInstance from 'utilities/configureAxios'
import route from 'utilities/route'

export const getTeacherPayments = async function(payload = {}) {
  return axiosInstance
    .get(route('teachers_earning.index'), payload)
    .then(res => res)
    .catch(error => {
      notification.warning({
        message: error.code,
        description: error.message,
      })
      return error.response
    })
}

export const addTeacherPayment = async function(payload = {}) {
  return axiosInstance
    .post(route('teachers_earning.add'), payload)
    .then(res => res)
    .catch(error => {
      notification.warning({
        message: error.code,
        description: error.message,
      })
      return error.response
    })
}

export const updateTeacherPayment = async function(id, payload = {}) {
  return axiosInstance
    .patch(route('teachers_earning.edit', { id }), payload)
    .then(res => res)
    .catch(err => {
      notification.warning({
        message: err.code,
        description: err.message,
      })
      return err.response
    })
}

export const removeTeacherPayment = async function(id, payload = {}) {
  return axiosInstance
    .delete(route('teachers_earning.delete', { id }), payload)
    .then(res => res)
    .catch(err => {
      notification.warning({
        message: err.code,
        description: err.message,
      })
      return err.response
    })
}
