const appConfig = {
  api: {
    host: process.env.REACT_APP_API_HOST,
    timeout: 20000,
  },
  google: {
    maps: {
      apiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    },
  },
  captcha: {
    siteKey: process.env.REACT_APP_CAPTCHA_KEY,
  },
}

export default appConfig
