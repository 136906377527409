import { store } from 'index'
import { get, uniqBy } from 'lodash'

export default async function getMenuData() {
  const permissions = get(store.getState(), 'auth.permissions', [])
  let allowedMenu = []
  const superAdmin = get(store.getState(), 'auth.superAdmin', false)
  if (superAdmin) {
    allowedMenu = [
      {
        title: 'Dashboard',
        key: 'dashboard',
        icon: 'fe fe-home',
        url: '/dashboard/home',
      },
      {
        title: 'Counsellors',
        icon: 'fa fa-flag',
        // url: '/counsellor/dashboard/home',
        children: [
          {
            title: 'Dashboard',
            key: 'counsellor dashboard',
            icon: 'fe fe-chevron-right',
            url: '/counsellor/dashboard/home',
          },
          {
            title: 'Parents',
            key: 'CParentIndex',
            icon: 'fe fe-chevron-right',
            url: '/counsellor/parents',
          },
          {
            title: 'Enroll',
            key: 'Cenrollments',
            icon: 'fe fe-chevron-right',
            url: '/counsellor/add-lead',
          },
          {
            title: 'View Schedule',
            key: 'CCourses',
            icon: 'fe fe-chevron-right',
            url: '/counsellor/select-course',
          },
        ],
      },
      {
        title: 'Courses',
        key: 'coursesIndex',
        icon: 'fe fe-list',
        children: [
          {
            title: 'List of Courses',
            key: 'listCourseIndex',
            icon: 'fe fe-chevron-right',
            url: '/courses',
          },
          {
            title: 'Batches',
            key: 'batchesIndex',
            icon: 'fe fe-chevron-right',
            url: '/batches',
          },
          {
            title: 'Trial Slots',
            key: 'trailSlots',
            icon: 'fe fe-chevron-right',
            url: '/trails-slots',
          },
          {
            title: 'Classes',
            key: 'classesIndex',
            icon: 'fe fe-chevron-right',
            url: '/classes',
          },
          {
            title: 'Testimonial',
            key: 'testimonialsIndex',
            icon: 'fe fe-chevron-right',
            url: '/testimonials',
          },
          {
            title: 'Course Learning',
            key: 'courseLearningIndex',
            icon: 'fe fe-chevron-right',
            url: '/course-learnings',
          },
        ],
      },
      {
        title: 'Programs',
        key: 'ProgramsIndex',
        icon: 'fa fa-graduation-cap',
        children: [
          {
            title: 'Parenting Programs',
            key: 'ParentingPrograms',
            icon: 'fe fe-chevron-right',
            url: '/apps/ParentTrainingPrograms',
          },
          {
            title: 'Teaching Programs',
            key: 'TeachingPrograms',
            icon: 'fe fe-chevron-right',
            url: '/apps/TeacherTrainingPrograms',
          },
        ],
      },
      {
        title: 'Users',
        key: 'userIndex',
        icon: 'fe fe-user',
        children: [
          {
            title: 'Teachers',
            key: 'TeacherIndex',
            icon: 'fe fe-chevron-right',
            url: '/teachers',
          },
          {
            title: 'Parents',
            key: 'ParentIndex',
            icon: 'fe fe-chevron-right',
            url: '/parents',
          },
          {
            title: 'Admins',
            key: 'AdminIndex',
            icon: 'fe fe-chevron-right',
            url: '/admins',
          },
        ],
      },
      {
        title: 'Centers',
        key: 'centerIndex',
        icon: 'fa fa-map-marker',
        url: '/center',
      },
      {
        title: 'Enrollments',
        key: 'EnrollmentIndex',
        icon: 'fe fe-user',
        children: [
          {
            title: 'List of Enrollments',
            key: 'EnrollmentListIndex',
            icon: 'fe fe-chevron-right',
            url: '/enrollments',
          },
          {
            title: 'Fee Due List',
            key: 'FeeDueListIndex',
            icon: 'fe fe-chevron-right',
            url: '/fee-due-list',
          },
        ],
      },
      {
        title: 'Payouts',
        key: 'payoutIndex',
        icon: 'fe fe-credit-card',
        children: [
          {
            title: 'List Of Teacher(s) Payouts',
            key: 'TeacherEarningIndex',
            icon: 'fe fe-chevron-right',
            url: '/teachers/payments',
          },
        ],
      },
      {
        title: 'Orders',
        key: 'OrdersIndex',
        icon: 'fe fe-user',
        children: [
          {
            title: 'List of Orders',
            key: 'OrdersListIndex',
            icon: 'fe fe-chevron-right',
            url: '/orders',
          },
        ],
      },
      {
        title: 'Payments',
        key: 'paymentsIndex',
        icon: 'fa fa-credit-card',
        children: [
          {
            title: 'List of Payments',
            key: 'paymentsIndex',
            icon: 'fe fe-chevron-right',
            url: '/payments',
          },
        ],
      },
      {
        title: 'Places',
        key: 'placesIndex',
        icon: 'fa fa-location-arrow',
        children: [
          {
            title: 'Cities',
            key: 'citiesIndex',
            icon: 'fe fe-chevron-right',
            url: '/cities',
          },
          {
            title: 'Zones',
            key: 'zonesIndex',
            icon: 'fe fe-chevron-right',
            url: '/zones',
          },
          {
            title: 'Location',
            key: 'locationIndex',
            icon: 'fe fe-chevron-right',
            url: '/location',
          },
        ],
      },
      {
        title: 'Ledger',
        key: 'ledger',
        icon: 'fe fe-bookmark',
        url: '/ledger',
      },
      {
        title: 'Coupons',
        key: 'CouponsIndex',
        icon: 'fe fe-tag',
        children: [
          {
            title: 'List of Coupons',
            key: 'CouponsIndex',
            icon: 'fe fe-chevron-right',
            url: '/coupons',
          },
        ],
      },
      {
        title: 'Notifications',
        key: 'NotificationIndex',
        icon: 'fe fe-bell',
        children: [
          {
            title: 'List of Notifications',
            key: 'NotificationIndex',
            icon: 'fe fe-chevron-right',
            url: '/notifications',
          },
        ],
      },
      {
        title: 'Feedback',
        key: 'feedback',
        icon: 'fa fa-comments-o',
        children: [
          {
            title: 'List of Feedback',
            key: 'feedback',
            icon: 'fe fe-chevron-right',
            url: '/feedback',
          },
        ],
      },
      {
        title: 'Roles',
        key: 'roles',
        icon: 'fe fe-user',
        url: '/roles',
      },

      {
        title: 'Reports',
        key: 'reports',
        icon: 'fe fe-bookmark',
        url: '/reports',
        children: [
          {
            title: 'Collection',
            key: 'collectionReport',
            icon: 'fe fe-chevron-right',
            url: '/report/collection',
          },
          {
            title: 'Enrollment',
            key: 'EnrollmentReport',
            icon: 'fe fe-chevron-right',
            url: '/report/enrollment',
          },
          {
            title: 'Trial Conversion',
            key: 'trial-conversion',
            icon: 'fe fe-chevron-right',
            url: '/trial-conversion',
          },
          {
            title: 'Organic Report',
            key: 'organic_report',
            icon: 'fe fe-chevron-right',
            url: '/organic-report',
          },
        ],
      },
      {
        title: 'Wallet',
        key: 'wallet',
        icon: 'fa fa-money',
        children: [
          {
            title: 'Create Wallet Link',
            key: 'createlink',
            icon: 'fe fe-chevron-right',
            url: '/generate-wallet-paymentlink',
          },
          {
            title: 'Wallet Entries',
            key: 'walletIndex',
            icon: 'fe fe-chevron-right',
            url: '/wallet',
          },
        ],
      },
      {
        title: 'Settings',
        key: 'setting',
        icon: 'fe fe-settings',
        children: [
          {
            title: 'App Settings',
            key: 'appSetting',
            icon: 'fe fe-chevron-right',
            url: '/appsettings',
          },
          {
            title: 'Cancellation Reasons',
            key: 'cancellationReasons',
            icon: 'fe fe-chevron-right',
            url: '/cancellationReasons',
          },
          {
            title: 'Batch Labels',
            key: 'batchLabels',
            icon: 'fe fe-chevron-right',
            url: '/batchLabels',
          },
          {
            title: 'Boards',
            key: 'boards',
            icon: 'fe fe-chevron-right',
            url: '/boards',
          },
        ],
      },

      // {
      //   title: 'All Students',
      //   key: 'StudentIndex',
      //   icon: 'fe fe-chevron-right',
      //   url: '/student',
      // },
      // {
      //   title: 'Payment Details',
      //   key: 'paymentDetails',
      //   icon: 'fa fa-credit-card',
      //   url: '/payment',
      // },
      // {
      //   title: 'Reports',
      //   key: 'reports',
      //   icon: 'fa fa-file',
      //   url: '/reports',
      // },
      // {
      //   title: 'Feeedback',
      //   key: 'feedback',
      //   icon: 'fa fa-comments-o',
      //   url: '/feedback',
      // },
    ]
  } else {
    let course = []
    let user = []
    let places = []
    let programs = []
    let reports = []
    let payouts = []
    permissions.map(item => {
      // reports
      if (item.name === 'view collections report') {
        reports = [
          ...reports,
          {
            title: 'Collection',
            key: 'collectionReport',
            icon: 'fe fe-chevron-right',
            url: '/report/collection',
          },
        ]
      }

      if (item.name === 'view enrollments report') {
        reports = [
          ...reports,
          {
            title: 'Enrollment',
            key: 'EnrollmentReport',
            icon: 'fe fe-chevron-right',
            url: '/report/enrollment',
          },
        ]
      }

      if (item.name === 'view enrollments report') {
        reports = [
          ...reports,
          {
            title: 'Organic Report',
            key: 'organic_report',
            icon: 'fe fe-chevron-right',
            url: '/organic-report',
          },
        ]
      }

      if (item.name === 'export trial slot conversion') {
        reports = [
          ...reports,
          {
            title: 'Trial Conversion',
            key: 'ledger',
            icon: 'fe fe-chevron-right',
            url: '/trial-conversion',
          },
        ]
      }

      // course

      if (item.name === 'view course') {
        course = [
          ...course,
          {
            title: 'List of Courses',
            key: 'listCourseIndex',
            icon: 'fe fe-chevron-right',
            url: '/courses',
          },
        ]
      }
      if (item.name === 'view batch') {
        course = [
          ...course,
          {
            title: 'Batches',
            key: 'batchesIndex',
            icon: 'fe fe-chevron-right',
            url: '/batches',
          },
        ]
      }
      if (item.name === 'view class') {
        course = [
          ...course,
          {
            title: 'Classes',
            key: 'classesIndex',
            icon: 'fe fe-chevron-right',
            url: '/classes',
          },
        ]
      }
      if (item.name === 'view slots') {
        course = [
          ...course,
          {
            title: 'Trial Slots',
            key: 'trailSlots',
            icon: 'fe fe-chevron-right',
            url: '/trails-slots',
          },
        ]
      }

      if (item.name === 'create edit testimonials') {
        course = [
          ...course,
          {
            title: 'Testimonial',
            key: 'testimonialsIndex',
            icon: 'fe fe-chevron-right',
            url: '/testimonials',
          },
        ]
      }

      // programs

      if (item.name === 'view parenting program') {
        programs = [
          ...programs,
          {
            title: 'Parenting Programs',
            key: 'ParentingPrograms',
            icon: 'fe fe-chevron-right',
            url: '/apps/ParentTrainingPrograms',
          },
        ]
      }

      if (item.name === 'view teaching program') {
        programs = [
          ...programs,
          {
            title: 'Teaching Programs',
            key: 'TeachingPrograms',
            icon: 'fe fe-chevron-right',
            url: '/apps/TeacherTrainingPrograms',
          },
        ]
      }

      // users

      if (item.name === 'view teacher') {
        user = [
          ...user,
          {
            title: 'Teachers',
            key: 'TeacherIndex',
            icon: 'fe fe-chevron-right',
            url: '/teachers',
          },
        ]

        payouts = [
          ...payouts,
          {
            title: 'List Of Teacher(s) Payouts',
            key: 'TeacherEarningIndex',
            icon: 'fe fe-chevron-right',
            url: '/teachers/payments',
          },
        ]
      }

      if (item.name === 'view parent') {
        user = [
          ...user,
          {
            title: 'Parents',
            key: 'ParentIndex',
            icon: 'fe fe-chevron-right',
            url: '/parents',
          },
        ]
      }
      if (item.name === 'view admin') {
        user = [
          ...user,
          {
            title: 'Admins',
            key: 'AdminIndex',
            icon: 'fe fe-chevron-right',
            url: '/admins',
          },
        ]
      }

      // place

      if (item.name === 'view city') {
        places = [
          ...places,
          {
            title: 'Cities',
            key: 'citiesIndex',
            icon: 'fe fe-chevron-right',
            url: '/cities',
          },
        ]
      }
      if (item.name === 'view zone') {
        places = [
          ...places,
          {
            title: 'Zones',
            key: 'zonesIndex',
            icon: 'fe fe-chevron-right',
            url: '/zones',
          },
        ]
      }
      if (item.name === 'view location') {
        places = [
          ...places,
          {
            title: 'Location',
            key: 'locationIndex',
            icon: 'fe fe-chevron-right',
            url: '/location',
          },
        ]
      }
      return true
    })
    permissions.map(item => {
      if (item.name === 'view collections report' || item.name === 'view enrollments report') {
        allowedMenu = [
          ...allowedMenu,
          {
            title: 'Reports',
            key: 'reports',
            icon: 'fe fe-bookmark',
            url: '/reports',
            children: reports,
          },
        ]
      }

      if (item.name === 'view wallet') {
        allowedMenu = [
          ...allowedMenu,
          {
            title: 'Wallet',
            key: 'wallet',
            icon: 'fa fa-money',
            children: [
              {
                title: 'Create Wallet Link',
                key: 'createlink',
                icon: 'fe fe-chevron-right',
                url: '/generate-wallet-paymentlink',
              },
              {
                title: 'Wallet Entries',
                key: 'walletIndex',
                icon: 'fe fe-chevron-right',
                url: '/wallet',
              },
            ],
          },
        ]
      }
      if (item.name === 'view counselor dashboard') {
        allowedMenu = [
          {
            title: 'Counsellors',
            icon: 'fa fa-flag',
            // url: '/counsellor/dashboard/home',
            children: [
              {
                title: 'Dashboard',
                key: 'counsellor dashboard',
                icon: 'fe fe-chevron-right',
                url: '/counsellor/dashboard/home',
              },
              {
                title: 'Parents',
                key: 'CParentIndex',
                icon: 'fe fe-chevron-right',
                url: '/counsellor/parents',
              },
              {
                title: 'Enroll',
                key: 'Cenrollments',
                icon: 'fe fe-chevron-right',
                url: '/counsellor/add-lead',
              },
              {
                title: 'View Schedule',
                key: 'CCourses',
                icon: 'fe fe-chevron-right',
                url: '/counsellor/select-course',
              },
            ],
          },
          ...allowedMenu,
        ]
      }

      if (
        item.name === 'view course' ||
        item.name === 'view batch' ||
        item.name === 'view class' ||
        item.name === 'create edit testimonials'
      ) {
        allowedMenu = [
          ...allowedMenu,
          {
            title: 'Courses',
            key: 'coursesIndex',
            icon: 'fe fe-list',
            children: course,
          },
        ]
      }
      if (item.name === 'view parenting program' || item.name === 'view teaching program') {
        allowedMenu = [
          ...allowedMenu,
          {
            title: 'Programs',
            key: 'programsIndex',
            icon: 'fe fe-list',
            children: programs,
          },
        ]
      }
      if (
        item.name === 'view admin' ||
        item.name === 'view parent' ||
        item.name === 'view teacher'
      ) {
        allowedMenu = [
          ...allowedMenu,
          {
            title: 'Users',
            key: 'userIndex',
            icon: 'fe fe-user',
            children: user,
          },
          {
            title: 'Payouts',
            key: 'payoutIndex',
            icon: 'fe fe-credit-card',
            children: payouts,
          },
        ]
      }
      if (item.name === 'view centre') {
        allowedMenu = [
          ...allowedMenu,
          {
            title: 'Centers',
            key: 'centerIndex',
            icon: 'fa fa-map-marker',
            url: '/center',
          },
        ]
      }
      if (item.name === 'view enrollment') {
        allowedMenu = [
          ...allowedMenu,
          {
            title: 'Enrollments',
            key: 'EnrollmentIndex',
            icon: 'fe fe-user',
            children: [
              {
                title: 'List of Enrollments',
                key: 'EnrollmentListIndex',
                icon: 'fe fe-chevron-right',
                url: '/enrollments',
              },
              {
                title: 'Fee Due List',
                key: 'FeeDueListIndex',
                icon: 'fe fe-chevron-right',
                url: '/fee-due-list',
              },
            ],
          },
        ]
      }
      if (item.name === 'view order') {
        allowedMenu = [
          ...allowedMenu,
          {
            title: 'Orders',
            key: 'OrdersIndex',
            icon: 'fe fe-user',
            children: [
              {
                title: 'List of Orders',
                key: 'OrdersListIndex',
                icon: 'fe fe-chevron-right',
                url: '/orders',
              },
            ],
          },
        ]
      }
      if (item.name === 'view payment') {
        allowedMenu = [
          ...allowedMenu,
          {
            title: 'Payments',
            key: 'paymentsIndex',
            icon: 'fa fa-credit-card',
            children: [
              {
                title: 'List of Payments',
                key: 'paymentsIndex',
                icon: 'fe fe-chevron-right',
                url: '/payments',
              },
            ],
          },
        ]
      }
      if (item.name === 'view city' || item.name === 'view zone' || item.name === 'view location') {
        allowedMenu = [
          ...allowedMenu,
          {
            title: 'Places',
            key: 'placesIndex',
            icon: 'fa fa-location-arrow',
            children: places,
          },
        ]
      }
      if (item.name === 'view ledger') {
        allowedMenu = [
          ...allowedMenu,
          {
            title: 'Ledger',
            key: 'ledger',
            icon: 'fe fe-bookmark',
            url: '/ledger',
          },
        ]
      }
      if (item.name === 'view coupon') {
        allowedMenu = [
          ...allowedMenu,
          {
            title: 'Coupons',
            key: 'CouponsIndex',
            icon: 'fe fe-tag',
            children: [
              {
                title: 'List of Coupons',
                key: 'CouponsIndex',
                icon: 'fe fe-chevron-right',
                url: '/coupons',
              },
            ],
          },
        ]
      }

      if (item.name === 'view notification') {
        allowedMenu = [
          ...allowedMenu,
          {
            title: 'Notifications',
            key: 'NotificationIndex',
            icon: 'fe fe-bell',
            children: [
              {
                title: 'List of Notifications',
                key: 'NotificationIndex',
                icon: 'fe fe-chevron-right',
                url: '/notifications',
              },
            ],
          },
        ]
      }

      if (item.name === 'view feedback') {
        allowedMenu = [
          ...allowedMenu,
          {
            title: 'Feedback',
            key: 'feedback',
            icon: 'fa fa-comments-o',
            children: [
              {
                title: 'List of Feedback',
                key: 'feedback',
                icon: 'fe fe-chevron-right',
                url: '/feedback',
              },
            ],
          },
        ]
      }
      if (item.name === 'view role') {
        allowedMenu = [
          ...allowedMenu,
          {
            title: 'Roles',
            key: 'roles',
            icon: 'fe fe-user',
            url: '/roles',
          },
        ]
      }
      if (item.name === 'view configuration') {
        allowedMenu = [
          ...allowedMenu,
          {
            title: 'Settings',
            key: 'setting',
            icon: 'fe fe-settings',
            children: [
              {
                title: 'App Settings',
                key: 'appSetting',
                icon: 'fe fe-chevron-right',
                url: '/appsettings',
              },
              {
                title: 'Boards',
                key: 'boards',
                icon: 'fe fe-chevron-right',
                url: '/boards',
              },
              {
                title: 'Cancellation Reasons',
                key: 'cancellationReasons',
                icon: 'fe fe-chevron-right',
                url: '/cancellationReasons',
              },
              {
                title: 'Batch Labels',
                key: 'batchLabels',
                icon: 'fe fe-chevron-right',
                url: '/batchLabels',
              },
            ],
          },
        ]
      }
      return true
    })
  }
  allowedMenu = uniqBy(allowedMenu, 'key')
  // allowedMenu=sortBy(allowedMenu,'title')
  return allowedMenu
}
