const actions = {
  SET_FILTERS: 'teachersEarning/SET_FILTERS',
  SET_STATE: 'teachersEarning/SET_STATE',
  SET_SELECTED_TEACHER: 'teachersEarning/SET_SELECTED_TEACHER',
  LOAD_TEACHER_EARNING_INDEX: 'teachersEarning/LOAD_TEACHER_EARNING_INDEX',
  REMOVE_TEACHER_EARNING: 'teacherEarning/REMOVE_TEACHER_EARNING',
  CREATE_NEW_TEACHER_EARNING: 'teachersEarning/CREATE_NEW_TEACHER_EARNING',
  UPDATE_TEACHER_EARNING: 'teachersEarning/UPDATE_TEACHER_EARNING',
  UPLOAD_EARNING: 'teachersEarning/UPLOAD_EARNING',
  CLEAR_FILTERS: 'teachersEarning/CLEAR_FILTERS',
  CLEAR_CURRENT: 'teachersEarning/CLEAR_CURRENT',
  CLEAR_SELECTED_TEACHER: 'teachersEarning/CLEAR_SELECTED_TEACHER',
}

export default actions
