const actions = {
  SET_STATE: 'wallet/SET_STATE',
  LOAD_WALLET_INDEX: 'wallet/LOAD_WALLET_INDEX',
  SET_FILTERS: 'wallet/SET_FILTERS',
  RESET_FILTERS: 'wallet/RESET_FILTERS',
  TOGGLE_WALLET: 'TOGGLE_WALLET',
  MAKE_REFUND: 'wallet/MAKE_REFUND',
}

export default actions
