import { all, takeEvery, put, call } from 'redux-saga/effects'
import { notification } from 'antd'
import getOrders, {
  getOrderDetails,
  collectFees,
  updateOrderAmount,
  collectFeesEmandate,
  AddDue,
  updateStatus,
} from 'services/orders'
import actions from './actions'
import enrollmentAction from '../enrollments/actions'
import errorActions from '../error/actions'

export function* WATCH_LOAD_ORDERS_INDEX(action) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })

  const response = yield call(getOrders, action.payload)

  if (response) {
    if (response.data.success) {
      yield put({
        type: actions.SET_STATE,
        payload: {
          index: response.data.data,
          pagination: response.data.meta.pagination,
        },
      })
    } else {
      yield put({
        type: errorActions.SET_STATE,
        payload: response.data,
      })
    }
  }
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: false,
    },
  })
}

export function* WATCH_UPDATE_ORDER_STATUS(action) {
  const response = yield call(updateStatus, action.payload.id, { status: action.payload.status })

  if (response) {
    if (response.data.success) {
      notification.success({
        message: 'Success!',
        description: 'Order Status Updated Successfully!',
      })
    } else {
      yield put({
        type: errorActions.SET_STATE,
        payload: response.data,
      })

      yield put({
        type: actions.SET_STATE,
        payload: {
          loading: false,
        },
      })
    }
  }
}

export function* WATCH_LOAD_CURRENT_ORDER(action) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })

  const response = yield call(getOrderDetails, action.payload.id, action.payload)

  if (response) {
    if (response.data.success) {
      yield put({
        type: actions.SET_STATE,
        payload: {
          current: response.data.data,
        },
      })
      yield put({
        type: enrollmentAction.LOAD_ENROLLMENT_BY_ORDER,
        payload: {
          id: action.payload.id,
          params: {
            includes: 'order',
          },
        },
      })
    } else {
      yield put({
        type: errorActions.SET_STATE,
        payload: response.data,
      })
    }
  }
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: false,
    },
  })
}

export function* COLLECT_FEES(action) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      submitLoading: true,
    },
  })

  const response = yield call(
    collectFees,
    action.payload.id,
    action.payload.data,
    action.payload.by_wallet,
  )
  if (response) {
    if (response.data.success) {
      // yield put({
      //   type: enrollmentAction.LOAD_ENROLLMENT_BY_ORDER,
      //   payload: {
      //     id: action.payload.id,
      //   },
      // })
      yield put({
        type: actions.LOAD_ORDERS_INDEX,
        payload: {
          params: {
            ...action.payload.params,
          },
        },
      })
      yield put({
        type: enrollmentAction.SHOW_MODAL,
        payload: {
          visible: false,
        },
      })
      notification.success({
        message: 'Success!',
        description: 'Fees collected successfully',
      })
    } else {
      yield put({
        type: errorActions.SET_STATE,
        payload: response.data,
      })
    }
  }
  yield put({
    type: actions.SET_STATE,
    payload: {
      submitLoading: false,
    },
  })
}

export function* COLLECT_FEES_EMANDATE(action) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      submitLoading: true,
    },
  })

  const response = yield call(collectFeesEmandate, action.payload.id, action.payload.data)
  if (response) {
    if (response.data.success) {
      // yield put({
      //   type: enrollmentAction.LOAD_ENROLLMENT_BY_ORDER,
      //   payload: {
      //     id: action.payload.id,
      //   },
      // })
      yield put({
        type: actions.LOAD_ORDERS_INDEX,
        payload: {
          params: {
            ...action.payload.params,
          },
        },
      })
      yield put({
        type: enrollmentAction.SHOW_MODAL,
        payload: {
          visible: false,
        },
      })
      notification.success({
        message: 'Success!',
        description: 'Fees collected successfully',
      })
    } else {
      yield put({
        type: errorActions.SET_STATE,
        payload: response.data,
      })
    }
  }
  yield put({
    type: actions.SET_STATE,
    payload: {
      submitLoading: false,
    },
  })
}

//
export function* UPDATE_ORDER_AMOUNT(action) {
  yield put({
    type: actions.SET_UPDATE_ORDER_AMOUNT_MODAL,
    payload: {
      loading: true,
    },
  })

  const response = yield call(updateOrderAmount, action.payload.id, action.payload.data)

  if (response) {
    if (response.data.success) {
      notification.success({
        message: 'Success!',
        description: 'Order Updated Successfully!',
      })
      yield put({
        type: actions.LOAD_ORDERS_INDEX,
        payload: {
          params: {
            ...action.payload.params,
          },
        },
      })
      yield put({
        type: actions.SET_UPDATE_ORDER_AMOUNT_MODAL,
        payload: {
          visible: false,
        },
      })
    } else {
      yield put({
        type: errorActions.SET_STATE,
        payload: response.data,
      })
    }
  }
  yield put({
    type: actions.SET_UPDATE_ORDER_AMOUNT_MODAL,
    payload: {
      loading: false,
      enrollment: {},
    },
  })
}

//  add  due amount
export function* ADD_DUE_AMOUNT(action) {
  yield put({
    type: actions.SET_ADD_DUE_AMOUNT_MODAL,
    payload: {
      loading: true,
    },
  })

  const response = yield call(AddDue, action.payload.id, action.payload.data)

  if (response) {
    if (response.data.success) {
      notification.success({
        message: 'Success!',
        description: 'Due Amount Added Successfully!',
      })
      yield put({
        type: actions.LOAD_ORDERS_INDEX,
        payload: {
          params: {
            ...action.payload.params,
          },
        },
      })
      yield put({
        type: actions.SET_ADD_DUE_AMOUNT_MODAL,
        payload: {
          visible: false,
        },
      })
    } else {
      yield put({
        type: errorActions.SET_STATE,
        payload: response.data,
      })
    }
  }
  yield put({
    type: actions.SET_UPDATE_ORDER_AMOUNT_MODAL,
    payload: {
      loading: false,
      enrollment: {},
    },
  })
}

export default function* rootSaga() {
  yield all([takeEvery(actions.LOAD_ORDERS_INDEX, WATCH_LOAD_ORDERS_INDEX)])
  yield all([takeEvery(actions.LOAD_CURRENT_ORDER, WATCH_LOAD_CURRENT_ORDER)])
  yield all([takeEvery(actions.COLLECT_FEES, COLLECT_FEES)])
  yield all([takeEvery(actions.UPDATE_ORDER_AMOUNT, UPDATE_ORDER_AMOUNT)])
  yield all([takeEvery(actions.UPDATE_ORDER_STATUS, WATCH_UPDATE_ORDER_STATUS)])
  yield all([takeEvery(actions.ADD_DUE_AMOUNT, ADD_DUE_AMOUNT)])
  yield all([takeEvery(actions.COLLECT_FEES_EMANDATE, COLLECT_FEES_EMANDATE)])
}
