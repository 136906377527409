const actions = {
  SET_STATE: 'report/SET_STATE',
  CLEAR_FILTERS: 'report/CLEAR_FILTERS',
  SET_REPORT_FILTER: 'report/SET_REPORT_FILTER',

  // enrollments -> by course
  FETCH_ENROLLMENTS_COURSEWISE: 'report/FETCH_ENROLLMENTS_COURSEWISE',
  FETCH_ENROLLMENTS_COURSE_BATCHWISE: 'report/FETCH_ENROLLMENTS_COURSE_BATCHWISE',
  FETCH_ENROLLMENTS_COURSE_TEACHER_BATCHWISE: 'report/FETCH_ENROLLMENTS_COURSE_TEACHER_BATCHWISE',
  FETCH_ENROLLMENTS_COURSE_TEACHER_BATCH_STUDENTWISE:
    'report/FETCH_ENROLLMENTS_COURSE_TEACHER_BATCH_STUDENTWISE',

  // enrollments -> by teacher
  FETCH_ENROLLMENTS_TEACHERWISE: 'report/FETCH_ENROLLMENTS_TEACHERWISE',
  FETCH_ENROLLMENTS_TEACHER_COURSEWISE: 'report/FETCH_ENROLLMENTS_TEACHER_COURSEWISE',
  FETCH_ENROLLMENTS_TEACHER_COURSE_BATCHWISE: 'report/FETCH_ENROLLMENTS_TEACHER_COURSE_BATCHWISE',
  FETCH_ENROLLMENTS_TEACHER_COURSE_BATCH_STUDENTWISE:
    'report/FETCH_ENROLLMENTS_TEACHER_COURSE_BATCH_STUDENTWISE',

  // enrollments -> by Student
  FETCH_ENROLLMENTS_STUDENTWISE: 'report/FETCH_ENROLLMENTS_STUDENTWISE',
  FETCH_ENROLLMENTS_STUDENT_COURSEWISE: 'report/FETCH_ENROLLMENTS_STUDENT_COURSEWISE',
  FETCH_ENROLLMENTS_STUDENT_COURSE_BATCHWISE: 'report/FETCH_ENROLLMENTS_STUDENT_COURSE_BATCHWISE',

  // collection -> By Course wise
  FETCH_COLLECTIONS_COURSEWISE: 'report/FETCH_COLLECTIONS_COURSEWISE',
  FETCH_COLLECTIONS_COURSE_TEACHERWISE_DETAILS:
    'report/FETCH_COLLECTIONS_COURSE_TEACHERWISE_DETAILS',
  FETCH_COLLECTIONS_COURSE_TEACHER_BATCHWISE_DETAILS:
    'report/FETCH_COLLECTIONS_COURSE_TEACHER_BATCHWISE_DETAILS',
  FETCH_COLLECTIONS_COURSE_TEACHER_BATCH_STUDENTWISE:
    'report/FETCH_COLLECTIONS_COURSE_TEACHER_BATCH_STUDENTWISE',

  // collection -> By Teacher
  FETCH_COLLECTIONS_TEACHERWISE: 'report/FETCH_COLLECTIONS_TEACHERWISE',
  FETCH_COLLECTIONS_TEACHER_COURSEWISE_DETAILS:
    'report/FETCH_COLLECTIONS_TEACHER_COURSEWISE_DETAILS',
  FETCH_COLLECTIONS_TEACHER_COURSE_BATCHWISE_DETAILS:
    'report/FETCH_COLLECTIONS_TEACHER_COURSE_BATCHWISE_DETAILS',
  FETCH_COLLECTIONS_TEACHER_COURSE_BATCH_STUDENTWISE:
    'report/FETCH_COLLECTIONS_TEACHER_COURSE_BATCH_STUDENTWISE',

  // collection -> By Student
  FETCH_COLLECTIONS_STUDENTWISE: 'report/FETCH_COLLECTIONS_STUDENTWISE',
  FETCH_COLLECTIONS_STUDENT_COURSEWISE: 'report/FETCH_COLLECTIONS_STUDENT_COURSEWISE',
  FETCH_COLLECTIONS_STUDENT_COURSE_BATCHWISE: 'report/FETCH_COLLECTIONS_STUDENT_COURSE_BATCHWISE',

  // FETCH_TOTAL_BY_COURSE: 'FETCH_TOTAL_BY_COURSE',
  // FETCH_TOTAL_BY_COURSE_DETAILS: 'FETCH_TOTAL_BY_COURSE_DETAILS',
  // teacher reports

  // FETCH_COLLECTIONS_TEACHERWISE: 'FETCH_COLLECTIONS_TEACHERWISE',
  // student report

  FETCH_DASHBOARD_METRICS: 'report/FETCH_DASHBORAD_METRICS',
}

export default actions
