import { all, takeEvery, put, call } from 'redux-saga/effects'
import getCenters, {
  updateCenters,
  fetchCenter,
  createCenter,
  updateCenterStatus,
} from 'services/centers'
import { push } from 'react-router-redux'
import { get } from 'lodash'
import { notification } from 'antd'
import actions from './actions'
import errorActions from '../error/actions'

export function* WATCH_LOAD_CENTER_INDEX(action) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })

  const response = yield call(getCenters, action.payload)

  if (response) {
    if (response.data.success) {
      yield put({
        type: actions.SET_STATE,
        payload: {
          index: response.data.data,
          pagination: response.data.meta.pagination,
        },
      })
    } else {
      yield put({
        type: errorActions.SET_STATE,
        payload: response.data,
      })
    }
  }
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: false,
    },
  })
}

export function* WATCH_UPDATE_STATUS(action) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })

  const response = yield call(updateCenterStatus, action.payload.id, action.payload.data)

  if (response) {
    if (response.data.success) {
      yield put({
        type: actions.LOAD_CENTERS_INDEX,
        payload: { params: action.payload.filters },
      })
      notification.success({
        message: 'Success !',
        description: get(response, 'data.message', 'Status Changed Success'),
      })
    } else {
      yield put({
        type: errorActions.SET_STATE,
        payload: response.data,
      })
      notification.warning({
        message: 'Failure !',
        description: get(response, 'data.message', 'Status Cannot Be Changed'),
      })
      yield put({
        type: actions.SET_STATE,
        payload: {
          loading: false,
        },
      })
    }
  }
}

export function* WATCH_LOAD_SELECTED_CENTER(action) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })

  const response = yield call(fetchCenter, action.payload.id, action.payload.query)

  if (response) {
    if (response.data.success) {
      yield put({
        type: actions.SET_STATE,
        payload: { current: response.data.data },
      })
    } else if (response.data.code === 422) {
      yield put({
        type: errorActions.SET_STATE,
        payload: response.data,
      })
    }
  }
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: false,
    },
  })
}

export function* WATCH_CREATE_CENTER(action) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })

  const response = yield call(createCenter, action.payload.values, action.payload.query)

  if (response) {
    if (response.data.success) {
      yield put(push('/center'))
      yield put({
        type: errorActions.SET_STATE,
        payload: { errors: {} },
      })
      notification.success({
        message: 'Success !',
        description: 'Center created successfully.',
      })
    } else if (response.data.code === 422) {
      yield put({
        type: errorActions.SET_STATE,
        payload: response.data,
      })
    }
  }
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: false,
    },
  })
}

export function* WATCH_UPDATE_CENTER(action) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })

  const response = yield call(
    updateCenters,
    action.payload.id,
    action.payload.values,
    action.payload.query,
  )
  if (response) {
    if (response.data.success) {
      yield put({
        type: actions.SET_STATE,
        payload: { current: response.data.data },
      })
      yield put(push('/center'))
      notification.success({
        message: 'Success !',
        description: 'Details saved successfully.',
      })
    } else if (response.data.code === 422) {
      yield put({
        type: errorActions.SET_STATE,
        payload: response.data,
      })
    }
  }
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: false,
    },
  })
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.LOAD_CENTERS_INDEX, WATCH_LOAD_CENTER_INDEX),
    takeEvery(actions.UPDATE_STATUS, WATCH_UPDATE_STATUS),
    takeEvery(actions.CREATE_CENTER, WATCH_CREATE_CENTER),
    takeEvery(actions.UPDATE_CENTER, WATCH_UPDATE_CENTER),
    takeEvery(actions.LOAD_SELECTED_CENTER, WATCH_LOAD_SELECTED_CENTER),
  ])
}
