import { notification } from 'antd'
import axiosInstance from 'utilities/configureAxios'
import route from 'utilities/route'

export default async function getBoards(payload = {}) {
  return axiosInstance
    .get(route('boards.index'), payload)
    .then(res => res)
    .catch(error => {
      notification.warning({
        message: error.code,
        description: error.message,
      })
      return error.response
    })
}

export async function addNewBoard(payload = {}) {
  return axiosInstance
    .post(route('boards.add'), payload)
    .then(res => res)
    .catch(error => {
      notification.warning({
        message: error.code,
        description: error.message,
      })
      return error.response
    })
}

export async function updateBoard(id, payload = {}) {
  return axiosInstance
    .post(route('boards.update', { id }), payload)
    .then(res => res)
    .catch(error => {
      notification.warning({
        message: error.code,
        description: error.message,
      })
      return error.response
    })
}

export async function removeBoard(id, payload = {}) {
  return axiosInstance
    .delete(route('boards.remove', { id }), payload)
    .then(res => res)
    .catch(error => {
      notification.warning({
        message: error.code,
        description: error.message,
      })
      return error.response
    })
}
