import actions from './actions'

const initialState = {
  loading: false,
  standards: [],
  courses: [],
  classesForEachBatch: [],
  courseLoader: false,
  batchLoader: false,
  modalLoader: false,
  singleCourseDetails: [],
  batchId: null,
  enrollmentsDetails: [],
  pagination: null,
  trialClassDates: [],
  installments: [],
  modalVisible: '',
  specifyCouponModal: false,
  paymentLinkDetails: '',
  counselors: [],
  filter: {},
  enrollmentSuccess: false,
}

export default function counselorRducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_FILTER: {
      return {
        ...state,
        filter: {
          ...state.filter,
          ...action.payload,
        },
      }
    }
    case actions.SINGLE_COURSE_BATCH_LOADING: {
      return {
        ...state,
        batchLoader: action.payload,
      }
    }
    case actions.SINGLE_COURSE_BATCH_SUCCESS: {
      return {
        ...state,
        singleCourseDetails: action.payload,
      }
    }
    case actions.GET_COURSES_SUCCESS:
      return {
        ...state,
        courses: action.payload,
      }
    case actions.CLASSES_FOR_EACH_BATCH_SUCCESS:
      return {
        ...state,
        classesForEachBatch: action.payload,
      }
    case actions.GET_STANDARDS_SUCCESS:
      return {
        ...state,
        standards: action.payload,
      }
    case actions.TRIAL_CLASS_DATES_SUCCESS:
      return {
        ...state,
        ...action.payload,
      }

    case actions.GET_COUNSELOR_SUCCESS:
      return {
        ...state,
        counselors: action.payload,
      }
    case actions.ENROLLMENT_SUCCESS_MODAL:
      return {
        ...state,
        ...action.payload,
      }

    case actions.COURSE_DETAILS_SUCCESS:
      return {
        ...state,
        singleCourseDetails: action.payload,
      }

    case actions.UPDATED_COURSE_DETAILS_BATCH_SUCCESS:
      return {
        ...state,
        singleCourseDetails: action.payload,
      }

    case actions.PAYMENT_LINK_SUCCESS:
      return {
        ...state,
        ...action.payload,
      }

    case actions.REMOVE_COUNSELOR_STATE:
      return {
        ...state,
        paymentLinkDetails: '',
      }

    case actions.REMOVE_STANDARDS:
      return {
        ...state,
        standards: [],
      }
    case actions.REMOVE_INSTALLMENTS:
      return {
        ...state,
        installments: [],
      }

    case actions.COUNSELOR_ENROLLMENTS_SUCCESS:
      return {
        ...state,
        enrollmentsDetails: action.payload.index,
        pagination: action.payload.pagination,
      }

    case actions.INSTALLMENT_BREAKAGE_SUCCESS:
      return {
        ...state,
        ...action.payload,
      }

    case actions.SPECIFY_COUPON_MODAL:
      return {
        ...state,
        ...action.payload,
      }

    case actions.MODAL_CLOSE:
      return { ...state, ...action.payload }

    case actions.JOIN_MODAL_OPEN:
      return { ...state, ...action.payload }

    case actions.SET_LOADING:
      return { ...state, loading: action.payload }

    case actions.SET_STATE:
      return { ...state, ...action.payload }

    case actions.SET_COURSE_LOADER:
      return { ...state, courseLoader: action.payload }

    case actions.SET_MODAL_LOADER:
      return { ...state, modalLoader: action.payload }

    default:
      return state
  }
}
