import React from 'react'
import { connect } from 'react-redux'
import classNames from 'classnames'
import style from './style.module.scss'

const mapStateToProps = ({ settings }) => ({ settings })

@connect(mapStateToProps)
class FooterDark extends React.Component {
  render() {
    const {
      settings: { isContentNoMaxWidth },
    } = this.props
    return (
      <div
        className={classNames(style.footerDark, {
          [style.footerFullWidth]: isContentNoMaxWidth,
        })}
      >
        <div className="bg-black py-4">
          <div className={style.container}>
            <div className="d-sm-flex align-items-sm-center">
              <div className={`clearfix mb-3 mr-sm-auto ${style.logo}`}>
                <img src="resources/images/logo-white.svg" alt="Cedarwood" width="120" />
                {/* <div className={style.logoName}>Cedarwood</div>
                <div className={style.logoDescr}>Admin Panel</div> */}
              </div>
              <div className="d-flex flex-column flex-sm-row">
                <a className="mb-1 mb-sm-0 px-sm-3" href="#">
                  About
                </a>
                <a className="mb-1 mb-sm-0 px-sm-3" href="#">
                  Terms of Use
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default FooterDark
