import { all, takeEvery, put, call } from 'redux-saga/effects'
import getTestimonials, {
  createTestimonialUpdate,
  getTestimonial,
  createTestimonial,
  updateTestimonial,
  deleteTestimonial,
} from 'services/testimonials'
import { push } from 'react-router-redux'
import { notification } from 'antd'
import actions from './actions'
import errorActions from '../error/actions'

export function* WATCH_LOAD_TESTIMONIALS_INDEX(action) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })

  const response = yield call(getTestimonials, action.payload)

  if (response) {
    if (response.data.success) {
      yield put({
        type: actions.SET_STATE,
        payload: {
          index: response.data.data,
          pagination: response.data.meta.pagination,
        },
      })
    } else {
      yield put({
        type: errorActions.SET_STATE,
        payload: response.data,
      })
    }
  }
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: false,
    },
  })
}

export function* WATCH_LOAD_CURRENT_TESTIMONIAL(action) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })

  const response = yield call(getTestimonial, action.payload)
  if (response) {
    if (response.data.success) {
      yield put({
        type: actions.SET_STATE,
        payload: {
          current: response.data.data,
        },
      })
    } else {
      yield put({
        type: errorActions.SET_STATE,
        payload: response.data,
      })
    }
  }
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: false,
    },
  })
}

export function* CREATE_TESTIMONIAL_UPDATE(action) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })

  const response = yield call(createTestimonialUpdate, action.payload.id, action.payload.data)

  if (response) {
    if (response.data.success) {
      yield put(push('/testimonials'))
      notification.success({
        message: 'Success!',
        description: 'Testimonial updates added',
      })
    } else {
      yield put({
        type: errorActions.SET_STATE,
        payload: response.data,
      })
    }
  }
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: false,
    },
  })
}

export function* WATCH_CREATE_TESTIMONIAL(action) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })

  const response = yield call(createTestimonial, action.payload)

  if (response) {
    if (response.data.success) {
      yield put(push('/testimonials'))
      yield put({
        type: errorActions.SET_STATE,
        payload: { errors: {} },
      })
    } else if (response.data.code === 422) {
      yield put({
        type: errorActions.SET_STATE,
        payload: response.data,
      })
    }
  }
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: false,
    },
  })
}

export function* WATCH_UPDATE_TESTIMONIAL(action) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })

  const response = yield call(updateTestimonial, action.payload.id, action.payload)

  if (response) {
    if (response.data.success) {
      yield put(push('/testimonials'))
      notification.success({
        message: 'Success!',
        description: 'Testimonial updated successfully',
      })
    } else {
      yield put({
        type: errorActions.SET_STATE,
        payload: response.data,
      })
    }
  }
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: false,
    },
  })
}

export function* DELETE_TESTIMONIAL(action) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })

  const response = yield call(deleteTestimonial, action.payload.id)
  if (response) {
    if (response.status === 204) {
      yield put({
        type: actions.LOAD_TESTIMONIALS_INDEX,
        payload: {
          params: {
            includes: 'guardian,course',
          },
        },
      })
    } else {
      yield put({
        type: errorActions.SET_STATE,
        payload: response.data,
      })
    }
  }
}

export default function* rootSaga() {
  yield all([takeEvery(actions.LOAD_TESTIMONIALS_INDEX, WATCH_LOAD_TESTIMONIALS_INDEX)])
  yield all([takeEvery(actions.CREATE_TESTIMONIAL_UPDATE, CREATE_TESTIMONIAL_UPDATE)])
  yield all([takeEvery(actions.LOAD_CURRENT_TESTIMONIAL, WATCH_LOAD_CURRENT_TESTIMONIAL)])
  yield all([takeEvery(actions.CREATE_TESTIMONIAL, WATCH_CREATE_TESTIMONIAL)])
  yield all([takeEvery(actions.UPDATE_TESTIMONIAL, WATCH_UPDATE_TESTIMONIAL)])
  yield all([takeEvery(actions.DELETE_TESTIMONIAL, DELETE_TESTIMONIAL)])
}
