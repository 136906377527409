import actions from './actions'

const initialState = {
  batchId: null,
  batchDetails: {},
  childDetails: [],
  parentId: null,
  parentDetails: {},
  joiningDate: null,
  joiningClass: null,
  childId: null,
  selectedInstallment: null,
  installmentId: null,
  selectedTrialClassId: null,
  loading: false,
  checkoutLoading: false,
  checkoutDetails: {},
  modalVisible: false,
  parentsCoupons: [],
  applyCouponModal: false,
  toggleCouponDetails: null,
}

export default function paymentReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_BATCH_DETAILS:
      return {
        ...state,
        batchDetails: action.payload,
        batchId: action.payload.id,
      }
    case actions.SET_JOIN_DATE:
      return {
        ...state,
        joiningDate: action.payload,
      }
    case actions.SET_JOIN_CLASS:
      return {
        ...state,
        joiningClass: action.payload,
      }
    case actions.SET_CHILD_DETAILS:
      return {
        ...state,
        childDetails: action.payload.child,
        childId: action.payload.child[0].id,
        parentId: action.payload.parentId,
        parentDetails: action.payload.parentDetails,
      }
    case actions.SET_INSTALLMENT_DETAILS:
      return {
        ...state,
        installmentId: action.payload,
      }

    case actions.SET_STATE:
      return {
        ...state,
        ...action.payload,
      }

    case actions.SET_LOADING:
      return {
        ...state,
        ...action.payload,
      }

    case actions.CHECKOUT_SUCCESS:
      return {
        ...state,
        checkoutDetails: action.payload,
      }

    case actions.CHECKOUT_LOADING:
      return {
        ...state,
        ...action.payload,
      }

    case actions.SELECTED_INSTALLMENT:
      return {
        ...state,
        selectedInstallment: action.payload,
      }

    case actions.REMOVE_STATE:
      return initialState

    case actions.REMOVE_CHILD_STATE:
      return {
        ...state,
        childId: null,
        childDetails: [],
      }

    case actions.REMOVE_INSTALLMENT_DETAILS:
      return {
        ...state,
        selectedInstallment: null,
        installmentId: null,
      }

    case actions.TOGGLE_COUPON_SUCCESS:
      return {
        ...state,
        ...action.payload,
      }
    case actions.REMOVE_COUPON:
      return {
        ...state,
        toggleCouponDetails: null,
      }

    case actions.PARENTS_COUPONS_SUCCSESS:
      return {
        ...state,
        ...action.payload,
      }
    case actions.APPLY_COUPON_MODAL:
      return {
        ...state,
        ...action.payload,
      }

    case actions.CLEAR_TRIAL_DETAILS:
      return {
        ...state,
        selectedTrialClassId: null,
        batchId: null,
        batchDetails: {},
      }

    default:
      return state
  }
}
