import actions from './actions'

const initialState = {
  loading: false,
  index: [],
  current: {},
  filters: {},
  editModalVisible: { visible: false, configuration: null },
}

export default function configurationReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }

    case actions.SET_FILTERS:
      return {
        ...state,
        filters: {
          ...state.filters,
          ...action.payload,
        },
      }

    case actions.CLEAR_FILTERS:
      return { ...state, filters: {} }

    case actions.SET_EDIT_MODAL_VISIBLE:
      return {
        ...state,
        editModalVisible: {
          visible: action.payload.visible,
          configuration: action.payload.configuration,
        },
      }

    default:
      return state
  }
}
