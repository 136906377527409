import { notification } from 'antd'
import axiosInstance from 'utilities/configureAxios'
import route from 'utilities/route'

export default async function getPayments(payload = {}) {
  return axiosInstance
    .get(route('payments.index'), payload)
    .then(res => res)
    .catch(error => {
      notification.warning({
        message: error.code,
        description: error.message,
      })
      return error.response
    })
}

export async function createPayment(payload = {}) {
  return axiosInstance
    .post(route('payments.create'), payload)
    .then(res => res)
    .catch(error => {
      notification.warning({
        message: error.code,
        description: error.message,
      })
      return error.response
    })
}

export async function createRefund(id, payload = {}) {
  const routeValue = route('payments.create_refund', { id })
  return axiosInstance
    .post(routeValue, payload.data)
    .then(res => res)
    .catch(error => {
      notification.warning({
        message: error.code,
        description: error.message,
      })
      return error.response
    })
}

export async function getRefunds(payload = { id: '' }) {
  const routeValue = route('payments.get_refunds')
  return axiosInstance
    .get(routeValue, { params: { payment_id: payload.id } })
    .then(res => res)
    .catch(error => {
      notification.warning({
        message: error.code,
        description: error.message,
      })
      return error.response
    })
}

export async function editRefund(id, payload = {}) {
  const routeValue = route('payments.edit_refund', { id })
  return axiosInstance
    .patch(routeValue, payload.data)
    .then(res => res)
    .catch(error => {
      notification.warning({
        message: error.code,
        description: error.message,
      })
      return error.response
    })
}

export async function exportPayments(payload = {}, extraParams = {}) {
  const routeValue = route('payments.export')
  return axiosInstance
    .post(routeValue, payload, extraParams)
    .then(res => res)
    .catch(error => {
      notification.warning({
        message: error.code,
        description: error.message,
      })
      return error.response
    })
}
