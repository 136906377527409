const actions = {
  SET_STATE: 'counselor/SET_STATE',
  SET_FILTER: 'counselor/SET_FILTER',
  SET_COURSE_LOADER: 'counselor/SET_COURSE_LOADER',
  SET_MODAL_LOADER: 'counselor/SET_MODAL_LOADER',
  GET_STANDARDS: 'counselor/GET_STANDARDS',
  GET_STANDARDS_SUCCESS: 'counselor/GET_STANDARDS_SUCCESS',
  GET_COURSES: 'counselor/GET_COURSES',
  GET_COURSES_SUCCESS: 'counselor/GET_COURSES_SUCCESS',
  SINGLE_COURSE_BATCH: 'counselor/SINGLE_COURSE_BATCH',
  SINGLE_COURSE_BATCH_SUCCESS: 'counselor/SINGLE_COURSE_BATCH_SUCCESS',
  SINGLE_COURSE_BATCH_LOADING: 'counselor/SINGLE_COURSE_BATCH_LOADING',
  COURSE_DETAILS: 'counselor/COURSE_DETAILS',
  COURSE_DETAILS_SUCCESS: 'counselor/COURSE_DETAILS_SUCCESS',
  GET_COUNSELOR: 'counselor/GET_COUNSELOR',
  GET_COUNSELOR_SUCCESS: 'counselor/GET_COUNSELOR_SUCCESS',
  COUNSELOR_ENROLLMENTS: 'counselor/COUNSELOR_ENROLLMENTS',
  COUNSELOR_ENROLLMENTS_SUCCESS: 'counselor/COUNSELOR_ENROLLMENTS_SUCCESS',
  ADD_CHILD: 'counselor/ADD_CHILD',
  ADD_LEAD: 'counselor/ADD_LEAD',
  TRIAL_CLASS_DATES: 'counselor/TRIAL_CLASS_DATES',
  TRIAL_CLASS_DATES_SUCCESS: 'counselor/TRIAL_CLASS_DATES_SUCCESS',
  SET_LOADING: 'counselor/SET_LOADING',
  MODAL_CLOSE: 'counselor/MODAL_CLOSE',
  INSTALLMENT_BREAKAGE: 'counselor/INSTALLMENT_BREAKAGE',
  INSTALLMENT_BREAKAGE_SUCCESS: 'counselor/INSTALLMENT_BREAKAGE_SUCCESS',
  REMOVE_INSTALLMENTS: 'counselor/REMOVE_INSTALLMENTS',
  JOIN_MODAL_OPEN: 'counselor/JOIN_MODAL_OPEN',
  SPECIFY_COUPON_MODAL: 'counselor/SPECIFY_COUPON_MODAL',
  UPDATE_SPECIFY_COUPON: 'counselor/UPDATE_SPECIFY_COUPON',
  PAYMENT_LINK: 'counselor/PAYMENT_LINK',
  PAYMENT_LINK_SUCCESS: 'counselor/PAYMENT_LINK_SUCCESS',
  SHARE_RESOURCES: 'counselor/SHARE_RESOURCES',
  REMOVE_COUNSELOR_STATE: 'counselor/REMOVE_COUNSELOR_STATE',
  REMOVE_STANDARDS: 'counselor/REMOVE_STANDARDS',
  ENROLLMENT_SUCCESS_MODAL: 'counselor/ENROLLMENT_SUCCESS_MODAL',
  CLASSES_FOR_EACH_BATCH: 'CLASSES_FOR_EACH_BATCH',
  CLASSES_FOR_EACH_BATCH_SUCCESS: 'CLASSES_FOR_EACH_BATCH_SUCCESS',
}

export default actions
