const actions = {
  LOGGIN_ASYNC: 'auth/LOGGIN_ASYNC',
  LOGGOUT: 'auth/LOGGOUT',
  LOGGOUT_ASYNC: 'auth/LOGGOUT_ASYNC',
  SET_TOKEN: 'auth/SET_TOKEN',
  LOGGIN: 'auth/LOGGIN',
  TOGGLE_LOADING: 'auth/TOGGLE_LAODING',
  RESET_PASSWORD: 'auth/RESET_PASSWORD',
  SEND_RESET_LINK: 'auth/SEND_RESET_LINK',
  SET_STATE: 'auth/SET_STATE',
  UNAUTHORIZED: 'auth/UNAUTHORIZED',
}

export default actions
