import { notification } from 'antd'
import axios from 'axios'
import axiosInstance from 'utilities/configureAxios'
import route from 'utilities/route'

export default async function getBatches(payload = {}) {
  return axiosInstance
    .get(route('batches.index'), payload)
    .then(res => res)
    .catch(error => {
      notification.warning({
        message: error.code,
        description: error.message,
      })
      return error.response
    })
}

export async function fetchInstallments() {
  return axiosInstance
    .get(route('batches.installments'))
    .then(res => res)
    .catch(error => {
      notification.warning({
        message: error.code,
        description: error.message,
      })
      return error.response
    })
}

export async function getSingleBatch(batchId, payload = {}) {
  return axiosInstance
    .get(route('batches.current_batch').concat(batchId), payload)
    .then(res => res)
    .catch(error => {
      notification.warning({
        message: error.code,
        description: error.message,
      })
      return error.response
    })
}

export async function createBatch(payload = {}) {
  return axiosInstance
    .post(route('batches.index'), payload)
    .then(res => res)
    .catch(error => {
      notification.warning({
        message: error.code,
        description: error.message,
      })
      return error.response
    })
}

export async function updateBatch(batchId, payload = {}) {
  return axiosInstance
    .post(route('batches.current_batch').concat(batchId), payload)
    .then(res => res)
    .catch(error => {
      notification.warning({
        message: error.code,
        description: error.message,
      })
      return error.response
    })
}

export async function completeBatch(batchId, payload = {}) {
  return axiosInstance
    .post(route('batches.current_batch').concat(batchId), payload)
    .then(res => {
      if (res) {
        notification.success({
          message: 'Batch Completed Successfully!',
        })
      }
      return res
    })
    .catch(error => {
      notification.warning({
        message: error.code,
        description: error.message,
      })
      return error.response
    })
}

export async function exportBatches(payload = {}, extraParams = {}) {
  const routeValue = route('batches.export')
  return axiosInstance
    .post(routeValue, payload, extraParams)
    .then(res => res)
    .catch(error => {
      notification.warning({
        message: error.code,
        description: error.message,
      })
      return error.response
    })
}

export async function sendSchedule(payload = {}) {
  return axiosInstance
    .post(route('batches.schedule'), payload)
    .then(res => res)
    .catch(error => {
      notification.warning({
        message: error.code,
        description: error.message,
      })
      return error.response
    })
}

export async function getGroupID() {
  const data = await axios.get('https://learning.cedarwood.co.in/api/getallgroups')
  return data.data
}

export async function archiveBatchApi(id, payload = {}) {
  const routeValue = route('batches.archive') + id
  return axiosInstance
    .delete(routeValue, payload)
    .then(res => res)
    .catch(error => {
      notification.warning({
        message: error.code,
        description: error.message,
      })
      return error.response
    })
}
export async function unarchiveBatchApi(id, payload = {}) {
  const routeValue = route('batches.unarchive') + id
  return axiosInstance
    .get(routeValue, payload)
    .then(res => res)
    .catch(error => {
      notification.warning({
        message: error.code,
        description: error.message,
      })
      return error.response
    })
}
