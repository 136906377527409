const actions = {
  SET_STATE: 'betaLabels/SET_STATE',
  SET_FILTERS: 'batchLabels/SET_FILTERS',
  HIDE_MODAL: 'batchLabels/HIDE_MODAL',
  CLEAR_FILTERS: 'batchLabels/CLEAR_FILTERS',
  LOAD_BATCH_LABELS_INDEX: 'batchLabels/LOAD_BATCH_LABELS',
  LOAD_CURRENT_BATCH_LABELS: 'batchLabels/LOAD_CURRENT_BATCH_LABELS',
  CREATE_BATCH_LABELS: 'batchLabels/CREATE_BATCH_LABELS',
  UPDATE_BATCH_LABELS: 'batchLabels/UPDATE_BATCH_LABELS',
  DELETE_BATCH_LABELS: 'batchLabels/DELETE_BATCH_LABELS',
}

export default actions
