const actions = {
  SET_STATE: 'testimonials/SET_STATE',
  LOAD_TESTIMONIALS_INDEX: 'testimonials/LOAD_TESTIMONIALS_INDEX',
  LOAD_CURRENT_TESTIMONIAL: 'testimonials/LOAD_CURRENT_TESTIMONIAL',
  CREATE_TESTIMONIAL: 'courses/CREATE_TESTIMONIAL',
  MARK_TESTIMONIAL_ATTENDENCE: 'testimonials/MARK_TESTIMONIAL_ATTENDENCE',
  CLEAR_CURRENT: 'testimonials/CLEAR_CURRENT',
  SET_FILTERS: 'testimonials/SET_FILTERS',
  CLEAR_FILTERS: 'testimonials/CLEAR_FILTERS',
  CREATE_TESTIMONIAL_UPDATE: 'testimonials/CREATE_TESTIMONIAL_UPDATE',
  UPDATE_TESTIMONIAL: 'testimonials/UPDATE_TESTIMONIAL',
  DELETE_TESTIMONIAL: 'testimonials/DELETE_TESTIMONIAL',
}

export default actions
