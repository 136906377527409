import actions from './actions'

const initialState = {
  loading: false,
  index: [],
  pagination: null,
  current: {},
  showmodal: false,
  isDisableFilter: false,
  filters: {
    page: 1,
    per_page: 15,
  },
}

export default function classReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_FILTERS:
      return {
        ...state,
        filters: {
          ...state.filters,
          ...action.payload,
        },
      }

    case actions.UPDATE_SPECIFIC_CLASS: {
      const targetIndex = state.index.findIndex(val => val.id === action.payload.id)
      const updatedIndex = [...state.index]
      updatedIndex.splice(targetIndex, 1, action.payload)

      return {
        ...state,
        index: updatedIndex,
      }
    }

    case actions.SET_STATE:
      return { ...state, ...action.payload }

    case actions.LOAD_CURRENT_CLASS:
      return { ...state, current: action.payload }
    case actions.CLEAR_CURRENT:
      return { ...state, current: {} }
    case actions.CLEAR_FILTERS:
      return { ...state, filters: {} }

    default:
      return state
  }
}
