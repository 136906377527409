import { all, takeEvery, put, call } from 'redux-saga/effects'
import {
  fetchCollectionsCoursewise,
  fetchCollectionsTeacherwise,
  fetchEnrollmentsCoursewise,
  fetchEnrollmentsTeacherwise,
  fetchCollectionsStudentwise,
  fetchCollectionsStudentCoursewise,
  fetchEnrollmentsCoursBatchewise,
  fetchEnrollmentsCourseTeacherBatchwise,
  fetchEnrollmentsCourseTeacherBatchStudentwise,
  fetchEnrollmentsTeacherCoursewise,
  fetchEnrollmentsTeacherCourseBatchStudentwise,
  fetchEnrollmentsTeacherCourseBatchwise,
  fetchCollectionsCourseTeacherwise,
  fetchCollectionsCourseTeacherBatchwise,
  fetchCollectionsCourseTeacherBatchStudentwise,
  fetchcollectionsTeacherCoursewise,
  fetchCollectionsTeacherCourseBatchwise,
  fetchCollectionsTeacherCourseBatchStudentwise,
  fetchCollectionsStudentCourseBatchwise,
  fetchEnrollmentsStudentwise,
  fetchEnrollmentsStudentCoursewise,
  fetchEnrollmentsStudentCourseBatchwise,
  fetchDashboardMetrics,
} from 'services/reports'
// import { push } from 'react-router-redux'
import { notification } from 'antd'
import errorActions from '../error/actions'
import actions from './actions'

// enrollments -> by course
export function* WATCH_FETCH_ENROLLMENTS_COURSEWISE(action) {
  yield put({ type: actions.SET_STATE, payload: { loading: true } })

  const isGet = true
  const response = yield call(fetchEnrollmentsCoursewise, action.payload, isGet)

  yield put({
    type: actions.SET_STATE,
    payload: { loading: false },
  })

  if (response) {
    if (response.data.success) {
      yield put({
        type: actions.SET_STATE,
        payload: {
          fetchEnrollmentsCoursewise: response?.data?.data,
          pagination: response.data?.meta,
        },
      })
    } else {
      yield put({
        type: errorActions.SET_STATE,
        payload: response.data,
      })
      yield put({
        type: actions.SET_STATE,
        payload: {
          index: [],
          pagination: {},
        },
      })
    }
  }
}
export function* WATCH_FETCH_ENROLLMENTS_COURSE_BATCHWISE(action) {
  yield put({ type: actions.SET_STATE, payload: { loading: true } })

  const isGet = true
  const response = yield call(fetchEnrollmentsCoursBatchewise, action.payload, isGet)

  yield put({
    type: actions.SET_STATE,
    payload: { loading: false },
  })

  if (response) {
    if (response.data.success) {
      yield put({
        type: actions.SET_STATE,
        payload: {
          fetchEnrollmentsCoursBatchewise: response?.data?.data,
          pagination: response.data?.meta,
        },
      })
    } else {
      yield put({
        type: errorActions.SET_STATE,
        payload: response.data,
      })
      yield put({
        type: actions.SET_STATE,
        payload: {
          index: [],
          pagination: {},
        },
      })
    }
  }
}
export function* WATCH_FETCH_ENROLLMENTS_COURSE_TEACHER_BATCHWISE(action) {
  yield put({ type: actions.SET_STATE, payload: { loading: true } })

  const isGet = true
  const response = yield call(fetchEnrollmentsCourseTeacherBatchwise, action.payload, isGet)

  yield put({
    type: actions.SET_STATE,
    payload: { loading: false },
  })

  if (response) {
    if (response.data.success) {
      yield put({
        type: actions.SET_STATE,
        payload: {
          fetchEnrollmentsCourseTeacherBatchwise: response?.data?.data,
          pagination: response.data?.meta,
        },
      })
    } else {
      yield put({
        type: errorActions.SET_STATE,
        payload: response.data,
      })
      yield put({
        type: actions.SET_STATE,
        payload: {
          index: [],
          pagination: {},
        },
      })
    }
  }
}
export function* WATCH_FETCH_ENROLLMENTS_COURSE_TEACHER_BATCH_STUDENTWISE(action) {
  yield put({ type: actions.SET_STATE, payload: { loading: true } })

  const isGet = true
  const response = yield call(fetchEnrollmentsCourseTeacherBatchStudentwise, action.payload, isGet)

  yield put({
    type: actions.SET_STATE,
    payload: { loading: false },
  })

  if (response) {
    if (response.data.success) {
      yield put({
        type: actions.SET_STATE,
        payload: {
          fetchEnrollmentsCourseTeacherBatchStudentwise: response?.data?.data,
          pagination: response.data?.meta,
        },
      })
    } else {
      yield put({
        type: errorActions.SET_STATE,
        payload: response.data,
      })
      yield put({
        type: actions.SET_STATE,
        payload: {
          index: [],
          pagination: {},
        },
      })
    }
  }
}

// enrollments -> by teacher report
export function* WATCH_FETCH_ENROLLMENTS_TEACHERWISE(action) {
  yield put({ type: actions.SET_STATE, payload: { loading: true } })

  const isGet = true
  const response = yield call(fetchEnrollmentsTeacherwise, action.payload, isGet)

  yield put({
    type: actions.SET_STATE,
    payload: { loading: false },
  })

  if (response) {
    if (response.data.success) {
      yield put({
        type: actions.SET_STATE,
        payload: {
          fetchEnrollmentsTeacherwise: response?.data?.data,
          pagination: response.data?.meta,
        },
      })
    } else {
      yield put({
        type: errorActions.SET_STATE,
        payload: response.data,
      })
      yield put({
        type: actions.SET_STATE,
        payload: {
          index: [],
          pagination: {},
        },
      })
    }
  }
}
export function* WATCH_FETCH_ENROLLMENTS_TEACHER_COURSEWISE(action) {
  yield put({ type: actions.SET_STATE, payload: { loading: true } })

  const isGet = true
  const response = yield call(fetchEnrollmentsTeacherCoursewise, action.payload, isGet)

  yield put({
    type: actions.SET_STATE,
    payload: { loading: false },
  })

  if (response) {
    if (response.data.success) {
      yield put({
        type: actions.SET_STATE,
        payload: {
          fetchEnrollmentsTeacherCoursewise: response?.data?.data,
          pagination: response.data?.meta,
        },
      })
    } else {
      yield put({
        type: errorActions.SET_STATE,
        payload: response.data,
      })
      yield put({
        type: actions.SET_STATE,
        payload: {
          index: [],
          pagination: {},
        },
      })
    }
  }
}
export function* WATCH_FETCH_ENROLLMENTS_TEACHER_COURSE_BATCHWISE(action) {
  yield put({ type: actions.SET_STATE, payload: { loading: true } })

  const isGet = true
  const response = yield call(fetchEnrollmentsTeacherCourseBatchwise, action.payload, isGet)

  yield put({
    type: actions.SET_STATE,
    payload: { loading: false },
  })

  if (response) {
    if (response.data.success) {
      yield put({
        type: actions.SET_STATE,
        payload: {
          fetchEnrollmentsTeacherCourseBatchwise: response?.data?.data,
          pagination: response.data?.meta,
        },
      })
    } else {
      yield put({
        type: errorActions.SET_STATE,
        payload: response.data,
      })
      yield put({
        type: actions.SET_STATE,
        payload: {
          index: [],
          pagination: {},
        },
      })
    }
  }
}
export function* WATCH_FETCH_ENROLLMENTS_TEACHER_COURSE_BATCH_STUDENTWISE(action) {
  yield put({ type: actions.SET_STATE, payload: { loading: true } })

  const isGet = true
  const response = yield call(fetchEnrollmentsTeacherCourseBatchStudentwise, action.payload, isGet)

  yield put({
    type: actions.SET_STATE,
    payload: { loading: false },
  })

  if (response) {
    if (response.data.success) {
      yield put({
        type: actions.SET_STATE,
        payload: {
          fetchEnrollmentsTeacherCourseBatchStudentwise: response?.data?.data,
          pagination: response.data?.meta,
        },
      })
    } else {
      yield put({
        type: errorActions.SET_STATE,
        payload: response.data,
      })
      yield put({
        type: actions.SET_STATE,
        payload: {
          index: [],
          pagination: {},
        },
      })
    }
  }
}

// enrollments -> by student report
export function* WATCH_FETCH_ENROLLMENTS_STUDENTWISE(action) {
  yield put({ type: actions.SET_STATE, payload: { loading: true } })
  const isGet = true
  const response = yield call(fetchEnrollmentsStudentwise, action.payload, isGet)
  yield put({ type: actions.SET_STATE, payload: { loading: false } })
  if (response) {
    if (response.data.success) {
      yield put({
        type: actions.SET_STATE,
        payload: {
          fetchEnrollmentsStudentwise: response?.data?.data,
          pagination: response.data?.meta,
        },
      })
    } else {
      yield put({
        type: errorActions.SET_STATE,
        payload: response.data,
      })
      yield put({
        type: actions.SET_STATE,
        payload: {
          index: [],
          pagination: {},
        },
      })
    }
  }
}
export function* WATCH_FETCH_ENROLLMENTS_STUDENT_COURSEWISE(action) {
  yield put({ type: actions.SET_STATE, payload: { loading: true } })

  const isGet = true
  const response = yield call(fetchEnrollmentsStudentCoursewise, action.payload, isGet)

  yield put({
    type: actions.SET_STATE,
    payload: { loading: false },
  })

  if (response) {
    if (response.data.success) {
      yield put({
        type: actions.SET_STATE,
        payload: {
          fetchEnrollmentsStudentCoursewise: response?.data?.data,
          pagination: response?.data?.meta,
        },
      })
    } else {
      yield put({
        type: errorActions.SET_STATE,
        payload: response.data,
      })
      yield put({
        type: actions.SET_STATE,
        payload: {
          index: [],
          pagination: {},
        },
      })
    }
  }
}
export function* WATCH_FETCH_ENROLLMENTS_STUDENT_COURSE_BATCHWISE(action) {
  yield put({ type: actions.SET_STATE, payload: { loading: true } })

  const isGet = true
  const response = yield call(fetchEnrollmentsStudentCourseBatchwise, action.payload, isGet)

  yield put({
    type: actions.SET_STATE,
    payload: { loading: false },
  })

  if (response) {
    if (response.data.success) {
      yield put({
        type: actions.SET_STATE,
        payload: {
          fetchEnrollmentsStudentCourseBatchwise: response?.data?.data,
          pagination: response?.data?.meta,
        },
      })
    } else {
      yield put({
        type: errorActions.SET_STATE,
        payload: response.data,
      })
      yield put({
        type: actions.SET_STATE,
        payload: {
          index: [],
          pagination: {},
        },
      })
    }
  }
}

// collection -> By course
export function* WATCH_FETCH_COLLECTIONS_COURSEWISE(action) {
  yield put({
    type: actions.SET_STATE,
    payload: { loading: true },
  })
  const isGet = true
  const response = yield call(fetchCollectionsCoursewise, action.payload, isGet)

  yield put({
    type: actions.SET_STATE,
    payload: { loading: false },
  })

  if (response) {
    if (response.data.success) {
      yield put({
        type: actions.SET_STATE,
        payload: {
          fetchCollectionsCoursewise: response?.data?.data,
          pagination: response.data?.meta,
        },
      })
      notification.success({
        message: 'Success !',
        description: response.data.message,
      })
    } else {
      yield put({
        type: errorActions.SET_STATE,
        payload: response.data,
      })
      yield put({
        type: actions.SET_STATE,
        payload: {
          index: [],
          pagination: {},
        },
      })
    }
  } else {
    yield put({
      type: actions.SET_STATE,
      payload: {
        index: [],
        pagination: {},
      },
    })
  }
}
export function* WATCH_FETCH_COLLECTIONS_COURSE_TEACHERWISE_DETAILS(action) {
  yield put({
    type: actions.SET_STATE,
    payload: { loading: true },
  })

  const isGet = true
  const response = yield call(fetchCollectionsCourseTeacherwise, action.payload, isGet)

  yield put({
    type: actions.SET_STATE,
    payload: { loading: false },
  })

  if (response) {
    if (response.data.success) {
      yield put({
        type: actions.SET_STATE,
        payload: {
          fetchCollectionsCourseTeacherwise: response?.data?.data,
          pagination: response.data?.meta,
        },
      })
      notification.success({
        message: 'Success !',
        description: response.data.message,
      })
    } else {
      yield put({
        type: errorActions.SET_STATE,
        payload: response.data,
      })
    }
  } else {
    yield put({
      type: actions.SET_STATE,
      payload: {
        index: [],
        pagination: {},
      },
    })
  }
}
export function* WATCH_FETCH_COLLECTIONS_COURSE_TEACHER_BATCHWISE_DETAILS(action) {
  yield put({
    type: actions.SET_STATE,
    payload: { loading: true },
  })

  const isGet = true
  const response = yield call(fetchCollectionsCourseTeacherBatchwise, action.payload, isGet)

  yield put({
    type: actions.SET_STATE,
    payload: { loading: false },
  })

  if (response) {
    if (response.data.success) {
      yield put({
        type: actions.SET_STATE,
        payload: {
          fetchCollectionsCourseTeacherBatchwise: response?.data?.data,
          pagination: response.data?.meta,
        },
      })
      notification.success({
        message: 'Success !',
        description: response.data.message,
      })
    } else {
      yield put({
        type: errorActions.SET_STATE,
        payload: response.data,
      })
    }
  } else {
    yield put({
      type: actions.SET_STATE,
      payload: {
        index: [],
        pagination: {},
      },
    })
  }
}
export function* WATCH_FETCH_COLLECTIONS_COURSE_TEACHER_BATCH_STUDENTWISE(action) {
  yield put({
    type: actions.SET_STATE,
    payload: { loading: true },
  })

  const isGet = true
  const response = yield call(fetchCollectionsCourseTeacherBatchStudentwise, action.payload, isGet)

  yield put({
    type: actions.SET_STATE,
    payload: { loading: false },
  })

  if (response) {
    if (response.data.success) {
      yield put({
        type: actions.SET_STATE,
        payload: {
          fetchCollectionsCourseTeacherBatchStudentwise: response?.data?.data,
          pagination: response.data?.meta,
        },
      })
      notification.success({
        message: 'Success !',
        description: response.data.message,
      })
    } else {
      yield put({
        type: errorActions.SET_STATE,
        payload: response.data,
      })
    }
  } else {
    yield put({
      type: actions.SET_STATE,
      payload: {
        index: [],
        pagination: {},
      },
    })
  }
}

// collection -> By teacher
export function* WATCH_FETCH_COLLECTIONS_TEACHERWISE(action) {
  yield put({
    type: actions.SET_STATE,
    payload: { loading: true },
  })
  const isGet = true
  const response = yield call(fetchCollectionsTeacherwise, action.payload, isGet)

  yield put({
    type: actions.SET_STATE,
    payload: { loading: false },
  })

  if (response) {
    if (response.data.success) {
      yield put({
        type: actions.SET_STATE,
        payload: {
          fetchCollectionsTeacherwise: response?.data?.data,
          pagination: response.data?.meta,
        },
      })
      notification.success({
        message: 'Success !',
        description: response.data.message,
      })
    } else {
      yield put({
        type: errorActions.SET_STATE,
        payload: response.data,
      })
      yield put({
        type: actions.SET_STATE,
        payload: {
          index: [],
          pagination: {},
        },
      })
    }
  } else {
    yield put({
      type: actions.SET_STATE,
      payload: {
        index: [],
        pagination: {},
      },
    })
  }
}
export function* WATCH_FETCH_COLLECTIONS_TEACHER_COURSEWISE_DETAILS(action) {
  yield put({
    type: actions.SET_STATE,
    payload: { loading: true },
  })

  const isGet = true
  const response = yield call(fetchcollectionsTeacherCoursewise, action.payload, isGet)

  yield put({
    type: actions.SET_STATE,
    payload: { loading: false },
  })

  if (response) {
    if (response.data.success) {
      yield put({
        type: actions.SET_STATE,
        payload: {
          fetchcollectionsTeacherCoursewise: response?.data?.data,
          pagination: response.data?.meta,
        },
      })
      notification.success({
        message: 'Success !',
        description: response.data.message,
      })
    } else {
      yield put({
        type: errorActions.SET_STATE,
        payload: response.data,
      })
    }
  } else {
    yield put({
      type: actions.SET_STATE,
      payload: {
        index: [],
        pagination: {},
      },
    })
  }
}
export function* WATCH_FETCH_COLLECTIONS_TEACHER_COURSE_BATCHWISE_DETAILS(action) {
  yield put({
    type: actions.SET_STATE,
    payload: { loading: true },
  })

  const isGet = true
  const response = yield call(fetchCollectionsTeacherCourseBatchwise, action.payload, isGet)

  yield put({
    type: actions.SET_STATE,
    payload: { loading: false },
  })

  if (response) {
    if (response.data.success) {
      yield put({
        type: actions.SET_STATE,
        payload: {
          fetchCollectionsTeacherCourseBatchwise: response?.data?.data,
          pagination: response.data?.meta,
        },
      })
      notification.success({
        message: 'Success !',
        description: response.data.message,
      })
    } else {
      yield put({
        type: errorActions.SET_STATE,
        payload: response.data,
      })
    }
  } else {
    yield put({
      type: actions.SET_STATE,
      payload: {
        index: [],
        pagination: {},
      },
    })
  }
}
export function* WATCH_FETCH_COLLECTIONS_TEACHER_COURSE_BATCH_STUDENTWISE(action) {
  yield put({
    type: actions.SET_STATE,
    payload: { loading: true },
  })

  const isGet = true
  const response = yield call(fetchCollectionsTeacherCourseBatchStudentwise, action.payload, isGet)

  yield put({
    type: actions.SET_STATE,
    payload: { loading: false },
  })

  if (response) {
    if (response.data.success) {
      yield put({
        type: actions.SET_STATE,
        payload: {
          fetchCollectionsTeacherCourseBatchStudentwise: response?.data?.data,
          pagination: response.data?.meta,
        },
      })
      notification.success({
        message: 'Success !',
        description: response.data.message,
      })
    } else {
      yield put({
        type: errorActions.SET_STATE,
        payload: response.data,
      })
    }
  } else {
    yield put({
      type: actions.SET_STATE,
      payload: {
        index: [],
        pagination: {},
      },
    })
  }
}

// collection by studnet
export function* WATCH_FETCH_COLLECTIONS_STUDENTWISE(action) {
  yield put({ type: actions.SET_STATE, payload: { loading: true } })

  const isGet = true
  const response = yield call(fetchCollectionsStudentwise, action.payload, isGet)

  yield put({
    type: actions.SET_STATE,
    payload: { loading: false },
  })

  if (response) {
    if (response.data.success) {
      yield put({
        type: actions.SET_STATE,
        payload: {
          fetchCollectionsStudentwise: response?.data?.data,
          pagination: response.data?.meta,
        },
      })
    } else {
      yield put({
        type: errorActions.SET_STATE,
        payload: response.data,
      })
      yield put({
        type: actions.SET_STATE,
        payload: {
          index: [],
          pagination: {},
        },
      })
    }
  }
}
export function* WATCH_FETCH_COLLECTIONS_STUDENT_COURSEWISE(action) {
  yield put({ type: actions.SET_STATE, payload: { loading: true } })

  const isGet = true
  const response = yield call(fetchCollectionsStudentCoursewise, action.payload, isGet)

  yield put({
    type: actions.SET_STATE,
    payload: { loading: false },
  })

  if (response) {
    if (response.data.success) {
      yield put({
        type: actions.SET_STATE,
        payload: {
          fetchCollectionsStudentCoursewise: response?.data?.data,
          pagination: response?.data?.meta,
        },
      })
    } else {
      yield put({
        type: errorActions.SET_STATE,
        payload: response.data,
      })
      yield put({
        type: actions.SET_STATE,
        payload: {
          index: [],
          pagination: {},
        },
      })
    }
  }
}
export function* WATCH_FETCH_COLLECTIONS_STUDENT_COURSE_BATCHWISE(action) {
  yield put({ type: actions.SET_STATE, payload: { loading: true } })

  const isGet = true
  const response = yield call(fetchCollectionsStudentCourseBatchwise, action.payload, isGet)

  yield put({
    type: actions.SET_STATE,
    payload: { loading: false },
  })

  if (response) {
    if (response.data.success) {
      yield put({
        type: actions.SET_STATE,
        payload: {
          fetchCollectionsStudentCourseBatchwise: response?.data?.data,
          pagination: response?.data?.meta,
        },
      })
    } else {
      yield put({
        type: errorActions.SET_STATE,
        payload: response.data,
      })
      yield put({
        type: actions.SET_STATE,
        payload: {
          index: [],
          pagination: {},
        },
      })
    }
  }
}

export function* WATCH_FETCH_DASHBOARD_METRICS(action) {
  yield put({ type: actions.SET_STATE, payload: { loading: true } })
  const response = yield call(fetchDashboardMetrics, action.payload)
  console.log('after response', response)

  yield put({
    type: actions.SET_STATE,
    payload: { loading: false },
  })

  if (response) {
    if (response.data.success) {
      yield put({
        type: actions.SET_STATE,
        payload: {
          fetchDashboardMetrics: response?.data?.data,
        },
      })
    } else {
      yield put({
        type: errorActions.SET_STATE,
        payload: response.data,
      })
      yield put({
        type: actions.SET_STATE,
        payload: {
          index: [],
          pagination: {},
        },
      })
    }
  }
}

export default function* rootSaga() {
  // enrollment -> by Course
  yield all([takeEvery(actions.FETCH_ENROLLMENTS_COURSEWISE, WATCH_FETCH_ENROLLMENTS_COURSEWISE)])
  yield all([
    takeEvery(actions.FETCH_ENROLLMENTS_COURSE_BATCHWISE, WATCH_FETCH_ENROLLMENTS_COURSE_BATCHWISE),
  ])
  yield all([
    takeEvery(
      actions.FETCH_ENROLLMENTS_COURSE_TEACHER_BATCHWISE,
      WATCH_FETCH_ENROLLMENTS_COURSE_TEACHER_BATCHWISE,
    ),
  ])
  yield all([
    takeEvery(
      actions.FETCH_ENROLLMENTS_COURSE_TEACHER_BATCH_STUDENTWISE,
      WATCH_FETCH_ENROLLMENTS_COURSE_TEACHER_BATCH_STUDENTWISE,
    ),
  ])

  // enrollment -> by Teacher
  yield all([takeEvery(actions.FETCH_ENROLLMENTS_TEACHERWISE, WATCH_FETCH_ENROLLMENTS_TEACHERWISE)])
  yield all([
    takeEvery(
      actions.FETCH_ENROLLMENTS_TEACHER_COURSEWISE,
      WATCH_FETCH_ENROLLMENTS_TEACHER_COURSEWISE,
    ),
  ])
  yield all([
    takeEvery(
      actions.FETCH_ENROLLMENTS_TEACHER_COURSE_BATCHWISE,
      WATCH_FETCH_ENROLLMENTS_TEACHER_COURSE_BATCHWISE,
    ),
  ])
  yield all([
    takeEvery(
      actions.FETCH_ENROLLMENTS_TEACHER_COURSE_BATCH_STUDENTWISE,
      WATCH_FETCH_ENROLLMENTS_TEACHER_COURSE_BATCH_STUDENTWISE,
    ),
  ])

  // enrollment -> by student
  yield all([takeEvery(actions.FETCH_ENROLLMENTS_STUDENTWISE, WATCH_FETCH_ENROLLMENTS_STUDENTWISE)])
  yield all([
    takeEvery(
      actions.FETCH_ENROLLMENTS_STUDENT_COURSEWISE,
      WATCH_FETCH_ENROLLMENTS_STUDENT_COURSEWISE,
    ),
  ])
  yield all([
    takeEvery(
      actions.FETCH_ENROLLMENTS_STUDENT_COURSE_BATCHWISE,
      WATCH_FETCH_ENROLLMENTS_STUDENT_COURSE_BATCHWISE,
    ),
  ])

  // collection -> by course

  yield all([takeEvery(actions.FETCH_COLLECTIONS_COURSEWISE, WATCH_FETCH_COLLECTIONS_COURSEWISE)])
  yield all([
    takeEvery(
      actions.FETCH_COLLECTIONS_COURSE_TEACHERWISE_DETAILS,
      WATCH_FETCH_COLLECTIONS_COURSE_TEACHERWISE_DETAILS,
    ),
  ])
  yield all([
    takeEvery(
      actions.FETCH_COLLECTIONS_COURSE_TEACHER_BATCHWISE_DETAILS,
      WATCH_FETCH_COLLECTIONS_COURSE_TEACHER_BATCHWISE_DETAILS,
    ),
  ])
  yield all([
    takeEvery(
      actions.FETCH_COLLECTIONS_COURSE_TEACHER_BATCH_STUDENTWISE,
      WATCH_FETCH_COLLECTIONS_COURSE_TEACHER_BATCH_STUDENTWISE,
    ),
  ])

  // collection -> by teacher

  yield all([takeEvery(actions.FETCH_COLLECTIONS_TEACHERWISE, WATCH_FETCH_COLLECTIONS_TEACHERWISE)])
  yield all([
    takeEvery(
      actions.FETCH_COLLECTIONS_TEACHER_COURSEWISE_DETAILS,
      WATCH_FETCH_COLLECTIONS_TEACHER_COURSEWISE_DETAILS,
    ),
  ])
  yield all([
    takeEvery(
      actions.FETCH_COLLECTIONS_TEACHER_COURSE_BATCHWISE_DETAILS,
      WATCH_FETCH_COLLECTIONS_TEACHER_COURSE_BATCHWISE_DETAILS,
    ),
  ])
  yield all([
    takeEvery(
      actions.FETCH_COLLECTIONS_TEACHER_COURSE_BATCH_STUDENTWISE,
      WATCH_FETCH_COLLECTIONS_TEACHER_COURSE_BATCH_STUDENTWISE,
    ),
  ])

  // collection by  student

  yield all([takeEvery(actions.FETCH_COLLECTIONS_STUDENTWISE, WATCH_FETCH_COLLECTIONS_STUDENTWISE)])
  yield all([
    takeEvery(
      actions.FETCH_COLLECTIONS_STUDENT_COURSEWISE,
      WATCH_FETCH_COLLECTIONS_STUDENT_COURSEWISE,
    ),
  ])
  yield all([
    takeEvery(
      actions.FETCH_COLLECTIONS_STUDENT_COURSE_BATCHWISE,
      WATCH_FETCH_COLLECTIONS_STUDENT_COURSE_BATCHWISE,
    ),
  ])
  yield all([takeEvery(actions.FETCH_DASHBOARD_METRICS, WATCH_FETCH_DASHBOARD_METRICS)])
}
