import React from 'react'
// import Search from './Search'
import UserMenu from './UserMenu'
import style from './style.module.scss'

class TopBar extends React.Component {
  render() {
    return (
      <div className={style.topbar}>
        <div className="mr-auto d-md-block">{/*  */}</div>
        <div className="logo-mobile">
          <a
            href="#"
            onClick={e => e.preventDefault()}
            className={style.air__menuLeft__logo}
            style={{ marginLeft: '23px' }}
          >
            <img src="resources/images/logo-white.svg" alt="Cedarwood" width="150" />
          </a>
        </div>
        <div className="mr-auto d-md-block">{/*  */}</div>
        <div className="d-sm-block logout-mobile">
          <UserMenu />
        </div>
      </div>
    )
  }
}

export default TopBar
