import actions from './actions'

const initialState = {
  loading: false,
  index: [],
  pagination: null,
  current: {},
  filters: {
    page: 1,
    per_page: 15,
    includes: 'city',
  },
  addModalVisible: false,
  editModalVisible: { visible: false, zone: null },
}

export default function zoneReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }

    case actions.SET_FILTERS:
      return {
        ...state,
        filters: {
          ...state.filters,
          ...action.payload,
        },
      }

    case actions.CLEAR_FILTERS:
      return { ...state, filters: {} }

    case actions.SET_ADD_MODAL_VISIBLE:
      return { ...state, addModalVisible: action.payload }

    case actions.SET_EDIT_MODAL_VISIBLE:
      return {
        ...state,
        editModalVisible: { visible: action.payload.visible, zone: action.payload.zone },
      }

    default:
      return state
  }
}
