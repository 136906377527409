import { get } from 'lodash'
import Route from 'route-parser'
import appConfig from '../config'

const { routesConfig } = appConfig

export default (name, parameters = [], absolute = false) => {
  let path = get(routesConfig, name)

  if (path === undefined) {
    throw new Error(`Route [${name}] not defined.`)
  }

  if (absolute) {
    path = appConfig.api.host + path
  }

  if (parameters.length === 0) {
    return path
  }

  return new Route(path).reverse(parameters)
}
