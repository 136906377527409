const actions = {
  SET_STATE: 'admins/SET_STATE',
  LOAD_ADMINS_INDEX: 'admins/LOAD_ADMIN_INDEX',
  SET_FILTERS: 'admins/SET_FILTERS',
  CLEAR_FILTERS: 'admins/CLEAR_FILTERS',
  LOAD_SELECTED_ADMIN: 'admins/LOAD_SELECTED_ADMIN',
  UPDATE_ADMIN: 'admins/UPDATE_ADMIN',
  CREATE_ADMIN: 'admins/CREATE_ADMIN',
  CLEAR_CURRENT: 'admins/CLEAR_CURRENT',
}

export default actions
