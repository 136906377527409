import { all } from 'redux-saga/effects'
import user from './user/sagas'
import menu from './menu/sagas'
import settings from './settings/sagas'
import courses from './courses/sagas'
import auth from './auth/sagas'
import tags from './tags/sagas'
import batches from './batches/sagas'
import standards from './standards/sagas'
import guardians from './guardians/sagas'
import teachers from './teachers/sagas'
import admins from './admin/sagas'
import centers from './centers/sagas'
import enrollments from './enrollments/sagas'
import orders from './orders/sagas'
import cities from './cities/sagas'
import zones from './zones/sagas'
import configurations from './configurations/sagas'
import locations from './location/sagas'
import payments from './payments/sagas'
import classes from './classes/sagas'
import ledgers from './ledgers/sagas'
import coupons from './coupons/sagas'
import notifications from './notifications/sagas'
import programs from './programs/sagas'
import feedbacks from './feedbacks/sagas'
import roles from './roles/sagas'
import counselor from './counselor/sagas'
import counselorPayment from './counselorPayment/sagas'
import testimonials from './testimonials/sagas'
import courseLearnings from './courseLearnings/sagas'
import report from './report/sagas'
import wallet from './wallet/sagas'
import trial from './trial/sagas'
import boards from './boards/sagas'
import cancellationReasons from './cancellationReasons/sagas'
import batchLabels from './batchLabels/sagas'
import teacherEarning from './teacherEarning/sagas'

export default function* rootSaga() {
  yield all([
    user(),
    menu(),
    settings(),
    courses(),
    auth(),
    tags(),
    batches(),
    standards(),
    guardians(),
    teachers(),
    admins(),
    centers(),
    enrollments(),
    orders(),
    cities(),
    zones(),
    configurations(),
    locations(),
    payments(),
    classes(),
    ledgers(),
    coupons(),
    notifications(),
    programs(),
    feedbacks(),
    roles(),
    counselor(),
    counselorPayment(),
    testimonials(),
    courseLearnings(),
    report(),
    wallet(),
    trial(),
    boards(),
    cancellationReasons(),
    batchLabels(),
    teacherEarning(),
  ])
}
