const actions = {
  SET_STATE: 'ledgers/SET_STATE',
  LOAD_LEDGERS_INDEX: 'ledgers/LOAD_LEDGERS_INDEX',
  LOAD_CURRENT_LEDGER: 'ledgers/LOAD_CURRENT_LEDGER',
  CREATE_SETTLEMENT: 'ledgers/CREATE_SETTLEMENT',
  ADD_LEDGER: 'ledgers/ADD_LEDGER',
  EDIT_LEDGER: 'ledgers/EDIT_LEDGER',
  CLEAR_CURRENT: 'ledgers/CLEAR_CURRENT',
  SET_FILTERS: 'ledgers/SET_FILTERS',
  CLEAR_FILTERS: 'ledgers/CLEAR_FILTERS',
}

export default actions
