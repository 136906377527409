import actions from './actions'

const initialState = {
  loading: false,
  index: [],
  pagination: null,
  showmodal: false,
  current: {},
  filters: {
    page: 1,
    per_page: 15,
  },
}

export default function feedbackReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_FILTERS:
      return {
        ...state,
        filters: {
          ...state.filters,
          ...action.payload,
        },
      }

    case actions.SET_STATE:
      return { ...state, ...action.payload }

    case actions.LOAD_CURRENT_FEEDBACK_ASYNC:
      return { ...state, current: action.payload }

    case actions.CLEAR_FILTERS:
      return {
        ...state,
        filters: {},
      }

    default:
      return state
  }
}
