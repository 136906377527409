import { all, takeEvery, put, call } from 'redux-saga/effects'
import getCities, { createCity, updateCurrentCity } from 'services/cities'
// import { push } from 'react-router-redux'
import { notification } from 'antd'
import actions from './actions'
import errorActions from '../error/actions'

export function* WATCH_LOAD_CITIES_INDEX(action) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })

  const response = yield call(getCities, action.payload)

  if (response) {
    if (response.data.success) {
      yield put({
        type: actions.SET_STATE,
        payload: {
          index: response.data.data,
          pagination: response.data.meta.pagination,
        },
      })
    } else {
      yield put({
        type: errorActions.SET_STATE,
        payload: response.data,
      })
    }
  }
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: false,
    },
  })
}

export function* WATCH_CREATE_CITY(action) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })

  const response = yield call(createCity, action.payload)

  if (response) {
    if (response.data.success) {
      yield put({
        type: actions.SET_STATE,
        payload: {
          showmodal: false,
        },
      })
      yield put({
        type: actions.LOAD_CITIES_INDEX,
        payload: {
          params: {
            includes: 'state',
          },
        },
      })
    } else {
      yield put({
        type: errorActions.SET_STATE,
        payload: response.data,
      })
    }
  }
}

export function* WATCH_UPDATE_CITY(action) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })

  const response = yield call(updateCurrentCity, action.payload.id, action.payload.data)

  if (response) {
    if (response.data.success) {
      yield put({
        type: actions.SET_STATE,
        payload: {
          showmodal: false,
          current: {},
        },
      })
      notification.success({
        message: 'Success !',
        description: 'Status Changed Success',
      })
      yield put({
        type: actions.LOAD_CITIES_INDEX,
        payload: {
          params: {
            includes: 'state',
          },
        },
      })
    } else {
      yield put({
        type: errorActions.SET_STATE,
        payload: response.data,
      })
    }
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.LOAD_CITIES_INDEX, WATCH_LOAD_CITIES_INDEX),
    takeEvery(actions.CREATE_CITY, WATCH_CREATE_CITY),
    takeEvery(actions.UPDATE_CITY, WATCH_UPDATE_CITY),
  ])
}
