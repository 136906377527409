import { all, takeEvery, put, call, select } from 'redux-saga/effects'
import getAdmins, { getAdminDetails, addAdmin, editAdmin } from 'services/admins'
import { getUser } from 'selectors/users'
import userActions from 'redux/user/actions'
import { get } from 'lodash'
import { notification } from 'antd'
import { push } from 'react-router-redux'
import actions from './actions'
import errorActions from '../error/actions'

export function* WATCH_LOAD_ADMINS_INDEX(action) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })

  const response = yield call(getAdmins, action.payload)

  if (response) {
    if (response.data.success) {
      yield put({
        type: actions.SET_STATE,
        payload: {
          index: response.data.data,
          pagination: response.data.meta.pagination,
        },
      })
    } else {
      yield put({
        type: errorActions.SET_STATE,
        payload: response.data,
      })
    }
  }
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: false,
    },
  })
}

export function* WATCH_LOAD_SELECTED_ADMIN(action) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })

  const response = yield call(getAdminDetails, action.payload.id, action.payload.query)

  if (response) {
    if (response.data.success) {
      yield put({
        type: actions.SET_STATE,
        payload: { current: response.data.data },
      })
    } else if (response.data.code === 422) {
      yield put({
        type: errorActions.SET_STATE,
        payload: response.data,
      })
    }
  }
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: false,
    },
  })
}

export function* WATCH_CREATE_ADMIN(action) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })

  const response = yield call(addAdmin, action.payload.values, action.payload.query)

  if (response) {
    if (response.data.success) {
      yield put(push('/admins'))
      yield put({
        type: errorActions.SET_STATE,
        payload: { errors: {} },
      })
      notification.success({
        message: 'Success !',
        description: 'Admin created successfully.',
      })
    } else if (response.data.code === 422) {
      yield put({
        type: errorActions.SET_STATE,
        payload: response.data,
      })
    }
  }
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: false,
    },
  })
}

export function* WATCH_UPDATE_ADMIN(action) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })

  const response = yield call(
    editAdmin,
    action.payload.id,
    action.payload.values,
    action.payload.query,
  )
  if (response) {
    if (response.data.success) {
      const user = yield select(getUser)
      if (get(response, 'data.data.user.id') === get(user, 'id')) {
        yield put({
          type: userActions.SET_STATE,
          payload: get(response, 'data.data.user', user),
        })
      }
      yield put({
        type: actions.SET_STATE,
        payload: { current: response.data.data },
      })
      yield put(push('/admins'))
      notification.success({
        message: 'Success !',
        description: 'Details saved successfully.',
      })
    } else if (response.data.code === 422) {
      yield put({
        type: errorActions.SET_STATE,
        payload: response.data,
      })
    }
  }
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: false,
    },
  })
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.LOAD_ADMINS_INDEX, WATCH_LOAD_ADMINS_INDEX),
    takeEvery(actions.LOAD_SELECTED_ADMIN, WATCH_LOAD_SELECTED_ADMIN),
    takeEvery(actions.CREATE_ADMIN, WATCH_CREATE_ADMIN),
    takeEvery(actions.UPDATE_ADMIN, WATCH_UPDATE_ADMIN),
  ])
}
