import actions from './actions'

const initialState = {
  loading: false,
  loadingCount: false,
  index: [],
  pagination: null,
  showmodal: false,
  showTransferModal: false,
  transferEnrollmentLoading: false,
  count: {},
  attendance: [],
  current: {},
  reasons: [],
  filters: {
    page: 1,
    per_page: 15,
  },
  enrollmentDoneBy: '',
  enrollmentReferredBy: '',
  installmentFeeModal: {
    visible: false,
    loading: false,
    enrollment: {},
    installment: null,
    batchId: null,
    classDateRange: {},
  },
  paymentStatusModal: {
    visible: false,
    enrollment: {},
  },
  classFeeModal: { visible: false, enrollment: {}, loading: false },
  editEnrollmentModal: { visible: false, enrollment: {}, loading: false },
}
export default function enrollmentReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_FILTERS:
      return {
        ...state,
        filters: {
          ...state.filters,
          ...action.payload,
        },
      }
    case actions.SET_STATE:
      return { ...state, ...action.payload }

    case actions.SHOW_MODAL:
      return { ...state, showmodal: action.payload.visible }

    case actions.SHOW_TRANSFER_MODAL:
      return { ...state, showTransferModal: action.payload.visible }

    case actions.LOAD_CURRENT_ENROLLMENT_ASYNC:
      return { ...state, current: action.payload }

    case actions.CLEAR_FILTERS:
      return {
        ...state,
        filters: {
          page: 1,
          per_page: 15,
        },
      }

    case actions.SET_INSTALLMENT_FEE_MODAL:
      return { ...state, installmentFeeModal: { ...state.installmentFeeModal, ...action.payload } }

    case actions.SET_CLASS_FEE_MODAL:
      return { ...state, classFeeModal: { ...state.classFeeModal, ...action.payload } }

    case actions.SET_ENROLLMENT_MODAL:
      return { ...state, editEnrollmentModal: { ...state.editEnrollmentModal, ...action.payload } }

    case actions.CANCELLATION_REASONS_SUCCESS:
      return {
        ...state,
        reasons: action.payload,
      }

    default:
      return state
  }
}
