const actions = {
  LOAD_GUARDIANS_INDEX: 'guardians/LOAD_GUARDIANS_INDEX',
  SET_FILTERS: 'guardians/SET_FILTERS',
  SET_STATE: 'guardians/SET_STATE',
  LOAD_CURRENT_GUARDIAN: 'guardians/LOAD_CURRENT_GUARDIAN',
  LOAD_CURRENT_GUARDIAN_ASYNC: 'guardians/LOAD_CURRENT_GUARDIAN_ASYNC',
  CLEAR_FILTERS: 'guardians/CLEAR_FILTERS',
  CREATE_GUARDIAN: 'guardians/CREATE_GUARDIAN',
  EDIT_GUARDIAN: 'guardians/EDIT_GUARDIAN',
  LOAD_SELECTED_GUARDIAN: 'guardians/LOAD_SELECTED_GUARDIAN',
  LOAD_CART_ITEMS: 'guardians/LOAD_CART_ITEMS',
  DELETE_CART_ITEM: 'guardians/DELETE_CART_ITEM',
  CLEAR_CURRENT: 'guardians/CLEAR_CURRENT',
  CLEAR_GUARDIAN_DETAIL: 'guardians/CLEAR_GUARDIAN_DETAIL',
  REMOVE_COUNSELORPARENT_DETAILS: 'guardians/REMOVE_COUNSELORPARENT_DETAILS',
  LOAD_FAMILY_INDEX: 'guardians/LOAD_FAMILY_INDEX',
  LINK_GUARDIAN: 'guardians/LINK_GUARDIAN',
}

export default actions
