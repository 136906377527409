import { notification } from 'antd'
import axiosInstance from 'utilities/configureAxios'
import route from 'utilities/route'

export default async function getCancellationReasons(payload = {}) {
  return axiosInstance
    .get(route('cancellationReasons.index'), payload)
    .then(res => res)
    .catch(error => {
      notification.warning({
        message: error.code,
        description: error.message,
      })
      return error.response
    })
}

export async function createCancellationReason(payload = {}) {
  return axiosInstance
    .post(route('cancellationReasons.add'), payload)
    .then(res => res)
    .catch(error => {
      notification.warning({
        message: error.code,
        description: error.message,
      })
      return error.response
    })
}

export async function updateCancellationReason(id, payload = {}) {
  return axiosInstance
    .post(route('cancellationReasons.update', { id }), payload)
    .then(res => res)
    .catch(error => {
      notification.warning({
        message: error.code,
        description: error.message,
      })
      return error.response
    })
}

export async function removeCancellationReason(id, payload = {}) {
  return axiosInstance
    .delete(route('cancellationReasons.remove', { id }), payload)
    .then(res => res)
    .catch(error => {
      notification.warning({
        message: error.code,
        description: error.message,
      })
      return error.response
    })
}
